import styled from 'styled-components'

export const CountryWrapper = styled.div`
  /* margin-left: 400px; */
  .ant-select-selection {
    width: 140px;
    border: none;
  }
  .anticon-down {
    font-size: 12px !important;
  }
  img {
    width: 16px !important;
    margin-right: 6px !important;
  }
  .ant-select:focus {
    border: none;
    outline: none;
  }
  .ant-select-focused {
    border: none;
    outline: none;
  }
`
