import { Component } from 'react'
import { Select, Icon } from 'antd'

import { CountryWrapper } from './country.style'
import { GENERIC_IMAGES } from '../../constants/images'

const { Option } = Select

const countrySelect = [
  {
    flag: GENERIC_IMAGES.INDONESIA_FLAG,
    name: 'Indonesia'
  }, {
    flag: GENERIC_IMAGES.THAILAND_FLAG,
    name: 'Thailand'
  }, {
    flag: GENERIC_IMAGES.VIETNAM_FLAG,
    name: 'Vietnam'
  }
]

class Country extends Component {
  render () {
    return (
      <CountryWrapper>
        <Select
          defaultValue='Indonesia'
          suffixIcon={<Icon type='down' />}
          dropdownClassName='country-dropdown'
        >
          {
            countrySelect.map(({ flag, name }) =>
              <Option value={name} className='country-dropdown' key={name}>
                <img width='22' className='flag' src={flag} alt='flag' />{name}
              </Option>
            )
          }
        </Select>
      </CountryWrapper>
    )
  }
}

export default Country
