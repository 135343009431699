import actions from './actions'

const initialState = {
  reportListMessage: '',
  reportList: [],
  createReportMessage: ''
}

export default function CustomerReducer (state = initialState, action) {
  switch (action.type) {
    case actions.GET_INSIGHTS_SUCCESS:
    case actions.GET_INSIGHTS_FAILURE:
      return {
        ...state,
        insightData: action.data,
        getInsightDataMessage: action.message
      }
    default:
      return state
  }
}
