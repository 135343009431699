import styled from 'styled-components'
import { palette } from 'styled-theme'

const AppHolder = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

 
  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  button {
    border-radius: 0;
  }
  .btn-detail-grid, .ant-btn, .ant-btn-primary {
    color: ${props => props.headerTextColor};
    border-radius: 20px;
    border: none;
    background-color: ${props => props.color};
  }
  .ant-btn:hover, .ant-btn-primary:hover {
    background-color: ${props => props.color};
    color: ${props => props.headerTextColor};
  }
  .ant-table-tbody > tr > td a, .ant-table-tbody > tr > td .anchor {
    color: ${props => props.headerTextColor} !important;
   padding: 7px 20px;
  }
  .ant-table-tbody > tr > td a:hover, .ant-table-tbody > tr > td .anchor:hover {
    background-color: ${props => props.color};
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${props => props.color};
  }
  .ant-tabs-tab-active .ant-tabs-tab:hover {
    color: ${props => props.color} !important;
  }
  .ant-tabs-tab:hover {
    color: ${props => props.color}!important;
  }
  .ant-tabs-tab-active:hover {
    color: ${props => props.color} !important;
  }
  .ant-tabs-ink-bar {
    background-color: ${props => props.color};
  }
  .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item, .ant-pagination-item-link {
    border: none !important;
  }
  .ant-pagination-item a:hover {
    color: ${props => props.color} !important;
  }
  .ant-table ul li:hover {
    color: ${props => props.color} !important;
  }
  .ant-pagination-item-active {
    background-color: ${props => props.color} !important;
    border: none !important;
    // border-radius: 25px !important;
    color: #fff !important;
  }
  .ant-pagination.item-active {
    background-color: ${props => props.color} !important;
  }
  .ant-pagination-item-active a, .ant-pagination-item-active a:hover {
    color: #fff !important;
  }
  .ant-steps-icon-dot, .ant-steps-item-finish > .ant-steps-item-tail:after,
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: ${props => props.color};
  }
  .ant-btn-loading {
    background-color: ${props => props.color} !important;
  }
  .ant-table-thead tr th {
    background-color: ${props => props.color} !important;
    color: ${props => props.headerTextColor} !important;
  }
  .anticon-filter {
    color: ${props => props.headerTextColor} !important;
  }
  .ant-input:hover {
    border-color: ${props => props.color} !important;
  }
  .ant-input:focus {
    border-color: ${props => props.color} !important;
  }
  .ant-spin {
    color: ${props => props.color} !important;
  }
  .ant-spin-dot i {
    background-color: ${props => props.color} !important;
  }
  .ant-btn:focus {
    background-color: ${props => props.color} !important;
    color: #fff !important;
    border-color: none !important;
  }
`

export default AppHolder
