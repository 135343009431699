const GOOGLE_STORAGE_BASE_URL = 'https://storage.googleapis.com/pp_img/'

export const GENERIC_IMAGES = {
  MONEY_BAG_ICON: `${GOOGLE_STORAGE_BASE_URL}money-bag.png`,
  POLICY_ICON: `${GOOGLE_STORAGE_BASE_URL}policy.png`,
  MONEY_INHAND_ICON: `${GOOGLE_STORAGE_BASE_URL}website/money-inhand-icon.png`,
  REPEATED_USER_ICON: `${GOOGLE_STORAGE_BASE_URL}repeated-user.png`,
  INDONESIA_FLAG: `${GOOGLE_STORAGE_BASE_URL}website/flag_indonesia.png`,
  THAILAND_FLAG: `${GOOGLE_STORAGE_BASE_URL}website/thailand_flag.png`,
  VIETNAM_FLAG: `${GOOGLE_STORAGE_BASE_URL}website/vietnam_flag.png`,
  PENCIL_ICON: `${GOOGLE_STORAGE_BASE_URL}pencil-icon.png`,
  PASARPOLIS_LOGO: 'https://storage.googleapis.com/pp_img/website/pp-logo-yellow-cap.png',
  ppBrokerLogo: `https://storage.googleapis.com/pp_img/ppib_assets/common/ppib-pasarpolis.png`
}
