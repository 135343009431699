import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { saveAs } from 'file-saver'
import actions from './actions'
import callApi from '../../../../helpers/callApi'
import authGet from '../../../../request/methods/authGet'
import fileUrls from '../../../../config/urls/file'
import _ from 'lodash/object'

export function * getFileTemplatesRequest () {
  yield takeEvery(actions.GET_FILE_TEMPLATES_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.getFileTemplates, payload)
    if (res.status === 200) {
      yield put({ type: actions.GET_FILE_TEMPLATES_SUCCESS, data: res.data, message: res.data.message || 'success' })
    } else {
      yield put({ type: actions.GET_FILE_TEMPLATES_FAILURE, message: (res.data || {}).message || 'error' })
    }
  })
}

export function * getFileTemplateSampleRequest () {
  yield takeEvery(actions.GET_FILE_TEMPLATE_SAMPLE_REQUEST, function * ({ payload = { params: {} } }) {
    try {
      let res = yield call(callApi.getFileTemplateSample, payload)
      const { params } = payload
      const { templateId } = params
      if (res.status === 200) {
        let filename
        const fileData = _.get(res.headers, 'content-disposition')
        if (fileData) {
          filename = fileData.split('=')[1]
          filename = filename.substring(1, filename.length - 1)
        } else {
          filename = `${templateId}.xlsx`
        }
        yield saveAs(res.data, filename)
        yield put({ type: actions.GET_FILE_TEMPLATE_SAMPLE_SUCCESS, data: res.data, message: res.data.message || 'success' })
      } else {
        yield put({ type: actions.GET_FILE_TEMPLATE_SAMPLE_FAILURE, data: '', message: (res.data || {}).message || 'error' })
      }
    } catch (e) {
      yield put({ type: actions.GET_FILE_TEMPLATE_SAMPLE_FAILURE, data: '', message: (e.data || {}).message || 'error' })
    }
  })
}

export function * getFileListRequest () {
  yield takeEvery(actions.GET_FILE_LIST_REQUEST, function * ({ payload }) {
    let res = yield call(authGet, { ...payload, url: fileUrls.getFileList })
    if (res.status === 200) {
      yield put({ type: actions.GET_FILE_LIST_SUCCESS, data: res.data, message: res.data.message || 'success' })
    } else {
      yield put({ type: actions.GET_FILE_LIST_FAILURE, message: (res.data || {}).message || 'error' })
    }
  })
}

export function * getFileDetailsRequest () {
  yield takeEvery(actions.GET_FILE_DETAILS_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.getFileDetails, payload)
    if (res.status === 200) {
      yield put({ type: actions.GET_FILE_DETAILS_SUCCESS, data: res.data, message: res.data.message || 'success' })
    } else {
      yield put({ type: actions.GET_FILE_DETAILS_FAILURE, message: (res.data || {}).message || 'error' })
    }
  })
}

export function * getFileRowsRequest () {
  yield takeEvery(actions.GET_FILE_ROWS_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.getFileRows, payload)
    if (res.status === 200) {
      const resData = res.data
      const { data, total_records: totalRecords, message } = resData
      yield put({ type: actions.GET_FILE_ROWS_SUCCESS, data, totalRecords, message: message || 'success' })
    } else {
      yield put({ type: actions.GET_FILE_ROWS_FAILURE, data: [], totalRecords: 0, message: (res.data || {}).message || 'error' })
    }
  })
}

export function * getFileRowsExcelRequest () {
  yield takeEvery(actions.GET_FILE_ROWS_EXCEL_REQUEST, function * ({ payload = { params: {} } }) {
    try {
      let res = yield call(callApi.getFileRowsExcel, payload)
      const { params } = payload
      const { fileId, fileName } = params
      if (res.status === 200) {
        if (fileName) {
          yield saveAs(res.data, `${fileName}.xlsx`)
        } else {
          yield saveAs(res.data, `${fileId}.xlsx`)
        }
        yield put({ type: actions.GET_FILE_ROWS_EXCEL_SUCCESS, message: res.data.message || 'success' })
      } else {
        yield put({ type: actions.GET_FILE_ROWS_EXCEL_FAILURE, message: (res.data || {}).message || 'error' })
      }
    } catch (e) {
      yield put({ type: actions.GET_FILE_ROWS_EXCEL_FAILURE, message: (e.data || {}).message || 'error' })
    }
  })
}

export function * getBulkUploadFileRequest () {
  yield takeEvery(actions.POST_BULK_UPLOAD_FILE_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.postBulkUploadFile, payload)
    if (res.status === 201) {
      yield put({ type: actions.POST_BULK_UPLOAD_FILE_SUCCESS, message: res.data.message || 'File uploaded successfully, go to upload list to view file' })
    } else {
      yield put({ type: actions.POST_BULK_UPLOAD_FILE_FAILURE, message: (res.data || {}).message || 'Failed, please re-upload the file' })
    }
  })
}

export function * cancelFileUpload () {
  yield takeEvery(actions.CANCEL_UPLOAD_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.cancelFileUpload, payload)
    if (res.status === 200) {
      yield put({ type: actions.CANCEL_UPLOAD_SUCCESS, message: res.data.status || 'File canceled successfully' })
    } else {
      yield put({ type: actions.CANCEL_UPLOAD_FAILURE, message: res.data.errors || 'Cancellation failed' })
    }
  })
}

export function * downloadOriginalFile () {
  yield takeEvery(actions.DOWNLOAD_ORIGINAL_FILE_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.downloadOriginalFile, payload)
    if (res.status === 200) {
      yield put({ type: actions.DOWNLOAD_ORIGINAL_FILE_SUCCESS, data: res.data, message: res.data.status || 'Original File downloaded successfully' })
    } else {
      yield put({ type: actions.DOWNLOAD_ORIGINAL_FILE_FAILURE, message: res.data.errors || 'Download original file failed' })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getFileTemplatesRequest),
    fork(getFileTemplateSampleRequest),
    fork(getFileListRequest),
    fork(getFileDetailsRequest),
    fork(getFileRowsRequest),
    fork(getFileRowsExcelRequest),
    fork(getBulkUploadFileRequest),
    fork(cancelFileUpload),
    fork(downloadOriginalFile)
  ])
}
