import { Map } from 'immutable'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function clearToken () {
  localStorage.removeItem('id_token')
  localStorage.removeItem('username')
  localStorage.removeItem('isDemo')
  localStorage.removeItem('config')
  cookies.remove('isDemo')
  const allCookies = cookies.getAll()
  for (let key in allCookies) {
    cookies.remove(key)
  }
}

export function getToken () {
  try {
    const idToken = localStorage.getItem('id_token')
    return new Map({ idToken })
  } catch (err) {
    clearToken()
    return new Map()
  }
}

export function getHeaderUploadFile () {
  let token = getToken().get('idToken')
  return {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Token ' + token
  }
}

export function timeDifference (givenTime) {
  givenTime = new Date(givenTime)
  const milliseconds = new Date().getTime() - givenTime.getTime()
  const numberEnding = number => {
    return number > 1 ? 's' : ''
  }
  const number = num => num > 9 ? '' + num : '0' + num
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000)
    const years = Math.floor(temp / 31536000)
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1)
      const day = number(givenTime.getUTCDate())
      const year = givenTime.getUTCFullYear() % 100
      return `${day}-${month}-${year}`
    }
    const days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days)
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
        const month = months[givenTime.getUTCMonth()]
        const day = number(givenTime.getUTCDate())
        return `${day} ${month}`
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`
    }
    const minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`
    }
    return 'a few seconds ago'
  }
  return getTime()
}

export function getHeader () {
  let token = getToken().get('idToken')
  return { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
}

export function dataFormatter (policy) {
  if (policy.length > 0) {
    return policy.map(({ InternalID, InsurancePolicyNo, PolicyStart, ProductKey, MobileNo, Email, CreatedAt, PolicyState, UniqueKey, Premium, PackageCode }) => {
      return {
        InternalID: InternalID,
        InsurancePolicyNo: InsurancePolicyNo,
        PolicyStartDate: PolicyStart !== undefined ? PolicyStart.Time : '-',
        ProductKey: ProductKey,
        MobileNo: MobileNo,
        Email: Email,
        CreatedAt: CreatedAt,
        PolicyState: PolicyState,
        UniqueKey: UniqueKey,
        Premium: Premium,
        PackageCode: PackageCode
      }
    })
  } else {
    return []
  }
}

export function objectQuery (obj) {
  let query = ''
  for (let key in obj) {
    query += `${query ? '&' : '?'}${key}=${obj[key]}`
  }
  return query
}

export function queryStringParser (queryString) {
  var regex = /[?&]([^=#]+)=([^&#]*)/g

  var url = queryString

  var params = {}

  var match = regex.exec(url)
  while (match) {
    params[match[1]] = match[2]
    match = regex.exec(url)
  }
  return params
}

export function getErrorMessage (statusCode, errorCode, response) {
  if (errorCode) {
    switch (errorCode) {
      case 'PP-401': // add error codes and return message here
        return 'PP-401'
      default:
        return errorCode
    }
  }
  switch (statusCode) {
    case 401:
      clearToken()
      window.location.replace('/')
      return ''
    case 500:
      return 'Something went wrong please try again!'
    case 404:
      return 'Requested Not Found'
    case 400:
      if (response && response?.error) {
        return response?.error
      }
      return response && response.message ? response.message : 'Bad request'
    default:
      return response && response.detail ? response.detail : 'Network Error. Please try again.'
  }
}

export function mapArrayElementsKeysToObjectKeys (keys) {
  let stateToBeReset = {}
  keys.forEach(key => { stateToBeReset[key] = undefined })
  return stateToBeReset
}

export function capitalize (str) {
  if (typeof str !== 'string') return ''
  return str[0].toUpperCase() + str.substring(1)
}

export const regExp = {
  email: /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const currencyChange = (number) => {
  return new Intl.NumberFormat('ID').format(number)
}
