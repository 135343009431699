import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { get } from 'lodash'
import actions from './actions'
import authGet from '../../../../request/methods/authGet'
import authPost from '../../../../request/methods/authPost'
import callApi from '../../../../helpers/callApi'
import middleware from '../../../../middleware/responseChecker'
import policyUrls from '../../../../config/urls/policy'
import claimActions from '../claims/actions'

export function * getPoliciesList () {
  yield takeEvery(actions.GET_POLICY_LIST_REQUEST, function * (payload) {
    let res = yield call(callApi.getPolicyList, payload.payload)
    if (res.status === 200) {
      yield put({ type: actions.GET_POLICY_LIST_SUCCESS, data: res.data, policyMessage: 'success' })
    } else {
      middleware(res)
      yield put({ type: actions.GET_POLICY_LIST_FAILURE, data: [], policyMessage: 'Failure fetching policy list' })
    }
  })
}

export function * getPoliciesListCount () {
  yield takeEvery(actions.GET_POLICY_LIST_COUNT_REQUEST, function * ({ payload }) {
    let res = yield call(authGet, { ...payload, url: policyUrls.getPolicyListCount })
    if (res.status === 200) {
      yield put({ type: actions.GET_POLICY_LIST_COUNT_SUCCESS, data: res.data, policyCountMessage: 'success' })
    } else {
      middleware(res)
      yield put({ type: actions.GET_POLICY_LIST_COUNT_FAILURE, data: [], policyCountMessage: get(res.data, 'error', 'Failure fetching policy list count') })
    }
  })
}

export function * getPartnersRequest () {
  yield takeEvery('GET_PARTNERS', function * () {
    let res = yield call(callApi.getPartners)
    if (res.status === 200) {
      yield put({ type: actions.DATA_PARTNERS, partners: res.data })
    } else {
      yield put({ type: actions.FAILED_GET_DATA_PARTNERS, failedMessageGetPartner: 'Ooops, something went terribly wrong!' })
    }
  })
}

export function * getProductsRequest () {
  yield takeEvery(actions.GET_PRODUCTS, function * ({ payload }) {
    let res = yield call(callApi.getProducts, payload)
    if (res.status === 200) {
      yield put({ type: actions.DATA_PRODUCTS, products: res.data.data })
    } else {
      yield put({ type: actions.FAILED_GET_DATA_PRODUCTS, failedMessageGetProducts: 'Ooops, something went terribly wrong!' })
    }
  })
}

export function * getPolicyDetail () {
  yield takeEvery(actions.GET_POLICY_DETAIL_REQUEST, function * (payload) {
    let res = yield call(callApi.getPolicyDetail, payload.payload)
    if (res.status === 200) {
      const policyDetail = res.data.data
      if (!policyDetail) {
        yield put({ type: actions.GET_POLICY_DETAIL_FAILURE, message: 'Failure fetching policy details' })
      } else {
        const claimPayload = { query: { 'application_no': get(policyDetail, 'ApplicationNo') } }
        yield put({ type: actions.GET_POLICY_DETAIL_SUCCESS, data: policyDetail, message: 'success' })
        yield put({ type: claimActions.GET_CLAIM_LISTS_REQUEST, payload: claimPayload })
      }
    } else {
      middleware(res)
      yield put({ type: actions.GET_POLICY_DETAIL_FAILURE, message: 'Failure fetching policy details' })
    }
  })
}

export function * getPolicyDetailV2 () {
  yield takeEvery(actions.GET_POLICY_DETAIL_REQUEST_V2, function * ({ payload }) {
    let res = yield call(authGet, { ...payload, url: policyUrls.getPolicyDetailV2 })
    if (res.status === 200) {
      const policyDetail = res.data
      if (!policyDetail) {
        yield put({ type: actions.GET_POLICY_DETAIL_SUCCESS_V2, message: 'Failure fetching policy details' })
      } else {
        yield put({ type: actions.GET_POLICY_DETAIL_SUCCESS_V2, data: policyDetail, message: 'success' })
      }
    } else {
      middleware(res)
      yield put({ type: actions.GET_POLICY_DETAIL_FAILURE_V2, message: 'Failure fetching policy details' })
    }
  })
}

export function * sendReportRequest () {
  yield takeEvery(actions.SEND_REPORT_POLICY, function * ({ payload }) {
    let res = yield call(authPost, { ...payload, url: policyUrls.emailPolicy })
    if (res.status === 200) {
      yield put({ type: actions.SUCCESS_SEND_REPORT_POLICY, sendPolicyReportStatus: 'success' })
    } else {
      yield put({ type: actions.FAILED_SEND_REPORT_POLICY, sendPolicyReportStatus: 'error' })
    }
  })
}

export function * sendReportPolicyRange () {
  yield takeEvery(actions.SEND_REPORT_POLICY_RANGE, function * ({ payload }) {
    let res = yield call(authPost, { ...payload, url: policyUrls.emailPolicyRange })
    if (res.status === 200) {
      yield put({ type: actions.SUCCESS_SEND_REPORT_POLICY_RANGE, sendPolicyReportStatusRange: 'success' })
    } else {
      yield put({ type: actions.FAILED_SEND_REPORT_POLICY_RANGE, sendPolicyReportStatusRange: 'error' })
    }
  })
}

export function * getMasterList () {
  yield takeEvery(actions.GET_MASTER_LIST_REQUEST, function * ({ payload }) {
    let { filterParams } = payload
    // if (filterParams.productKey) {
    //   filterParams.productCode = filterParams.productKey
    //   delete filterParams.productKey
    // }

    let res = yield call(authGet, { query: filterParams, url: policyUrls.getMasterList })
    if (res.status === 200) {
      yield put({ type: actions.GET_MASTER_LIST_SUCCESS, data: res.data, policyCountMessage: 'success' })
    } else {
      middleware(res)
      yield put({ type: actions.GET_POLICY_LIST_COUNT_FAILURE, data: [], policyCountMessage: get(res.data, 'error', 'Failure fetching policy list count') })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getPoliciesList),
    fork(getPoliciesListCount),
    fork(getPolicyDetail),
    fork(getPartnersRequest),
    fork(getProductsRequest),
    fork(sendReportRequest),
    fork(getMasterList),
    fork(getPolicyDetailV2),
    fork(sendReportPolicyRange)
  ])
}
