import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import actions from './actions'
import callApi from '../../../../helpers/callApi'
import request from '../../../../request/axios/claim'
import _ from 'lodash/object'
import authGet from '../../../../request/methods/authGet'
import authPost from '../../../../request/methods/authPost'
import claimsUrl from '../../../../config/urls/claims'

export function * getClaimLists () {
  yield takeEvery(actions.GET_CLAIM_LISTS_REQUEST, function * ({ payload }) {
    let res = yield call(authGet, { ...payload, url: claimsUrl.getClaimsList })
    if (res.status === 200) {
      const { data, meta_data: metaData } = res.data
      yield put({
        type: actions.GET_CLAIM_LISTS_SUCCESS,
        data,
        meta_data: metaData,
        getClaimListsStatus: 'success'
      })
    } else {
      yield put({
        type: actions.GET_CLAIM_LISTS_FAILURE,
        data: [],
        meta_data: {},
        getClaimListsStatus: 'Something went wrong! Please try again.'
      })
    }
  })
}
export function * getClaimFilters () {
  yield takeEvery(actions.GET_CLAIM_FILTERS_REQUEST, function * (payload) {
    let res = yield call(callApi.getClaimFilters, payload)
    if (res.status === 200 && res.data) {
      yield put({ type: actions.GET_CLAIM_FILTERS_SUCCESS, data: res.data, getClaimFilterStatus: 'success' })
    } else {
      yield put({ type: actions.GET_CLAIM_FILTERS_FAILURE, data: [], getClaimFilterStatus: 'Ooops, something went terribly wrong!' })
    }
  })
}
export function * getDetailsClaim () {
  yield takeEvery(actions.GET_DETAILS_CLAIM, function * (payload) {
    let res = yield call(callApi.getDetailsClaim, payload)
    if (res.status === 200) {
      yield put({
        type: actions.DETAILS_CLAIM,
        detailData: res.data,
        getDetailsClaimStatus: 'success'
      })
    } else {
      yield put({
        type: actions.FAILED_GET_DETAILS_CLAIM,
        detailData: [],
        getDetailsClaimStatus: 'Ooops, something went terribly wrong!'
      })
    }
  })
}
export function * sendReportClaims () {
  yield takeEvery('SEND_REPORT_CLAIMS', function * (payload) {
    let res = yield call(callApi.sendReportClaims, payload)
    if (res.status === 200) {
      yield put({ type: actions.SUCCESS_SEND_REPORT_CLAIMS, sendPolicyReportStatus: 'success' })
    } else {
      yield put({ type: actions.FAILED_SEND_REPORT_CLAIMS, sendPolicyReportStatus: 'error' })
    }
  })
}

export function * uploadCreateClaimDocRequest () {
  yield takeEvery(actions.UPLOAD_CREATE_CLAIM_DOC_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.uploadClaimDoc, payload)
    const attribute = payload instanceof FormData ? payload.get('attribute') : ''
    if ([200, 201].includes(res.status)) {
      const uploadedUrl = (res.data.data || {})[attribute]
      if (!!uploadedUrl && typeof uploadedUrl === 'string') {
        yield put({
          type: actions.UPLOAD_CREATE_CLAIM_DOC_SUCCESS,
          uploadCreateClaimDocStatus: 'success',
          url: uploadedUrl,
          attribute
        })
      } else {
        yield put({
          type: actions.UPLOAD_CREATE_CLAIM_DOC_FAILURE,
          uploadCreateClaimDocStatus: 'error',
          attribute
        })
      }
    } else {
      yield put({
        type: actions.UPLOAD_CREATE_CLAIM_DOC_FAILURE,
        uploadCreateClaimDocStatus: 'error',
        attribute
      })
    }
  })
}

export function * updateClaimFormWithGenericDoc () {
  yield takeEvery(actions.UPLOAD_CREATE_CLAIM_DOC_SUCCESS, function * ({ attribute, url }) {
    if (!url) return

    yield put({
      type: actions.UPDATE_UPLOAD_CREATE_CLAIM_DOC_VALUES,
      target: { [attribute]: url }
    })
  })
}

export function * createClaimWithDocumentRequest () {
  yield takeEvery(actions.CREATE_CLAIM_WITH_DOC_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.createClaimsWithDocuments, payload)
    if (res.status === 200) {
      yield put({
        type: actions.CREATE_CLAIM_WITH_DOC_SUCCESS,
        createdClaimWithDoc: res.data,
        createClaimWithDocStatus: _.get(res.data, 'message', 'Success')
      })
    } else {
      yield put({
        type: actions.CREATE_CLAIM_WITH_DOC_FAILURE,
        createdClaimWithDoc: {},
        createClaimWithDocStatus: _.get(res.data, 'error', 'Error')
      })
    }
  })
}

export function * createClaimRequest () {
  yield takeEvery(actions.CREATE_CLAIM, function * ({ payload }) {
    let res = yield call(callApi.createClaim, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.SUCCESS_CREATE_CLAIM,
        claimId: res.data.claim_id,
        createClaimStatus: _.get(res.data, 'message', 'Success')
      })
    } else {
      yield put({
        type: actions.FAILED_CREATE_CLAIM,
        createClaimStatus: _.get(res.data, 'error', 'Error')
      })
    }
  })
}

// export function * updateUploadGenericClaimDocLoadingStates () {
//   yield takeEvery([
//     actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST,
//     actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS,
//     actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE
//   ], function * ({ type, payload, attribute: payloadAttribute, form: payloadForm }) {
//     const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
//     const [,, requestState] = matches
//     const isRequest = requestState === 'REQUEST'
//     let attribute = ''
//     let form = ''
//
//     if (isRequest) {
//       const formData = _.get(payload, 'formData')
//       form = _.get(payload, 'form')
//       if (!(formData instanceof FormData) || !form) return
//       attribute = formData.get('attribute')
//     } else if (payloadAttribute && payloadForm) {
//       attribute = payloadAttribute
//       form = payloadForm
//     }
//
//     if (!attribute) return
//
//     yield put({
//       type: actions.UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES,
//       target: { [form]: { [attribute]: isRequest } }
//     })
//   })
// }

export function * getBeneftListRequest () {
  yield takeEvery(actions.GET_BENEFITS_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.getBenefitsList, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_BENEFITS_SUCCESS,
        benefitsList: res.data,
        getBenefitsListStatus: _.get(res.data, 'message', 'Success')
      })
    } else {
      yield put({
        type: actions.GET_BENEFITS_FAILURE,
        benefitsList: {},
        getBenefitsListStatus: _.get(res.data, 'error', 'Error')
      })
    }
  })
}

export function * getValidateClaimRequest () {
  yield takeEvery(actions.VALIDATE_CLAIM_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.validateClaim, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.VALIDATE_CLAIM_SUCCESS,
        validateClaimData: res.data,
        validateClaimStatus: _.get(res.data, 'message', 'Success') || 'Success'
      })
    } else {
      yield put({
        type: actions.VALIDATE_CLAIM_FAILURE,
        validateClaimData: {},
        validateClaimStatus: _.get(res.data, 'error', 'Error') || 'Error'
      })
    }
  })
}

export function * uploadGenericDocRequest () {
  yield takeEvery(actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST, function * ({ payload }) {
    const { formData, form } = payload
    const attribute = formData instanceof FormData ? formData.get('attribute') : ''
    let res = yield call(callApi.saveClaimFormAttribute, payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS,
        data: res.data,
        saveAttributesGenericDocStatus: 'success',
        attribute,
        form
      })
    } else {
      yield put({
        type: actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE,
        saveAttributesGenericDocStatus: 'unable to upload',
        attribute,
        form
      })
    }
  })
}
export function * saveGenericAttributesRequest () {
  yield takeEvery(actions.SAVE_GENERIC_ATTRIBUTES_REQUEST, function * ({ payload }) {
    let res = yield call(request.saveGenericAttributes, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CLAIM_DETAILS_REQUEST,
        payload: payload.claim_id
      })
      yield put({
        type: actions.SAVE_GENERIC_ATTRIBUTES_SUCCESS,
        saveGenericAttributesStatus: 'success'
      })
    } else {
      let message =
        res.data.message !== undefined ? res.data.message : res.data.error
      yield put({
        type: actions.SAVE_GENERIC_ATTRIBUTES_FAILURE,
        saveGenericAttributesStatus: message
      })
    }
  })
}

export function * saveDraftAttributesRequest () {
  yield takeEvery(actions.SAVE_DRAFT_ATTRIBUTES_REQUEST, function * ({ payload }) {
    let res = yield call(request.saveDraftAttributes, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_DETAILS_CLAIM,
        payload: payload.claim_id
      })
      yield put({
        type: actions.SAVE_DRAFT_ATTRIBUTES_SUCCESS,
        saveDraftAttributesStatus: 'success'
      })
    } else {
      let message =
        res.data.message !== undefined ? res.data.message : res.data.error
      yield put({
        type: actions.SAVE_DRAFT_ATTRIBUTES_FAILURE,
        saveDraftAttributesStatus: message
      })
    }
  })
}

export function * verifyBankAccountForms () {
  yield takeEvery(actions.VERIFY_BANK_ACCOUNT_FORMS_REQUEST, function * ({ payload }) {
    let data = {
      payload: {
        account_holder_name: payload?.payload?.account_holder_name,
        account_number: payload?.payload?.account_number,
        bank_code: payload?.payload?.bank_name,
        claim_no: payload?.payload?.claim_no
      }
    }

    try {
      let res = yield call(authPost, { body: data.payload, url: claimsUrl.verifyBankAccount })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FORMS_SUCCESS,
          data: res.data,
          status: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FORMS_FAILURE,
          data: res.data,
          status: _.get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.VERIFY_BANK_ACCOUNT_FORMS_FAILURE,
          status: _.get(e.data, 'error', 'Error')
        })
      }
    }
  })
}

export function * postSalavageFormRequest () {
  yield takeEvery(actions.POST_SALVAGE_FORMS_REQUEST, function * (
    payload
  ) {
    let res = yield call(request.postSalvageForms, payload.payload)
    if (res.status === 200 || res.status === 201) {
      yield put({
        type: actions.POST_SALVAGE_FORMS_SUCCESS,
        postSalvageFormStatus: 'success'
      })
    } else {
      let message =
        res.data.message !== undefined ? res.data.message : res.data.error
      yield put({
        type: actions.POST_SALVAGE_FORMS_FAILURE,
        postSalvageFormStatus: message
      })
    }
  })
}

export function * getBankList () {
  yield takeEvery(actions.GET_BANK_LIST_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimsUrl.getBankList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_BANK_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_BANK_LIST_FAILURE,
          data: []
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_BANK_LIST_FAILURE,
        data: []
      })
    }
  })
}

export function * generateSignedUrlAndUpload () {
  yield takeEvery(actions.GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.generateSignedURL, payload)
      if (res.status === 200) {
        const responseData = yield res.data
        yield put({
          type: actions.UPLOAD_FILE_BY_SIGNED_URL_REQUEST,
          payload: {
            apiData: payload,
            signedUrlData: responseData
          }
        })
        yield put({
          type: actions.GENERATE_SIGNED_URL_AND_UPLOAD_SUCCESS
        })
      } else {
        yield put({
          type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
          data: _.get(res.data, 'data', ''),
          message: 'Gagal unggah file'
        })
        yield put({
          type: actions.GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
        data: {},
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
      yield put({
        type: actions.GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE
      })
    }
  })
}

export function * uploadFileUsingSignedUrl () {
  yield takeEvery(actions.UPLOAD_FILE_BY_SIGNED_URL_REQUEST, function * ({ payload }) {
    try {
      const { apiData, signedUrlData } = payload
      const body = {
        file: apiData.file,
        signedUrlData: signedUrlData
      }
      let res = yield call(request.fileUploadUsingSignedUrl, body)
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST,
          payload: {
            ...apiData,
            signedUrlData
          }
        })
        yield put({
          type: actions.UPLOAD_FILE_BY_SIGNED_URL_SUCCESS
        })
      } else {
        yield put({
          type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
          data: _.get(res.data, 'data', ''),
          message: 'Gagal unggah file'
        })
        yield put({
          type: actions.UPLOAD_FILE_BY_SIGNED_URL_FAILURE
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPLOAD_COMMON_CLAIM_FILE_FAILURE,
        data: {},
        message: 'Gagal unggah file'
        // TODO: move to constant
      })
      yield put({
        type: actions.UPLOAD_FILE_BY_SIGNED_URL_FAILURE
      })
    }
  })
}

export function * getServiceCenterOnClaim () {
  yield takeEvery(actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { query: payload, url: claimsUrl.getServiceCenterOnClaim })
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_SUCCESS,
          data: _.get(res.data, 'data', []),
          status: _.get(res.data, 'status', ''),
          message: _.get(res.data, 'message', '')
        })
      } else {
        yield put({
          type: actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_FAILURE,
          data: _.get(res.data, 'data', []),
          status: _.get(res.data, 'status', ''),
          message: _.get(res.data, 'message', '')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_FAILURE,
        data: [],
        status: 'Error',
        message: _.get(e.data, 'message', '')
      })
    }
  })
}

export function * getServiceCenterBranchOnClaim () {
  yield takeEvery(actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { query: payload, url: claimsUrl.getServiceCenterBranchOnClaim })
      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_SUCCESS,
          data: _.get(res.data, 'data', []),
          status: _.get(res.data, 'status', ''),
          message: _.get(res.data, 'message', '')
        })
      } else {
        yield put({
          type: actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_FAILURE,
          data: _.get(res.data, 'data', []),
          status: _.get(res.data, 'status', ''),
          message: _.get(res.data, 'message', '')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_FAILURE,
        data: [],
        status: 'Error',
        message: _.get(e.data, 'message', '')
      })
    }
  })
}

export function * getProvinceListForServiceCenter () {
  yield takeEvery(actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { url: claimsUrl.getProvinceListForServiceCenter, query: payload })

      if (res.status === 200) {
        yield put({
          type: actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_SUCCESS,
          data: _.get(res.data, 'data', []),
          message: _.get(res.data, 'message', '')
        })
      } else {
        yield put({
          type: actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_FAILURE,
          data: _.get(res.data, 'data', []),
          message: _.get(res.data, 'message', '')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * getCityListForServiceCenter () {
  yield takeEvery(actions.GET_CITY_LIST_FOR_SERVICE_CENTER_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { query: payload, url: claimsUrl.getCityListForServiceCenter })
      if (res.status === 200) {
        yield put({
          type: actions.GET_CITY_LIST_FOR_SERVICE_CENTER_SUCCESS,
          data: _.get(res.data, 'data', []),
          message: _.get(res.data, 'message', '')
        })
      } else {
        yield put({
          type: actions.GET_CITY_LIST_FOR_SERVICE_CENTER_FAILURE,
          data: _.get(res.data, 'data', []),
          message: _.get(res.data, 'message', '')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CITY_LIST_FOR_SERVICE_CENTER_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * getPostalCodeForServiceCenter () {
  yield takeEvery(actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { query: payload, url: claimsUrl.getPostalCodeForServiceCenter })
      if (res.status === 200) {
        yield put({
          type: actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_SUCCESS,
          data: _.get(res.data, 'data', []),
          message: _.get(res.data, 'message', '')
        })
      } else {
        yield put({
          type: actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_FAILURE,
          data: _.get(res.data, 'data', []),
          message: _.get(res.data, 'message', '')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * getTotalServiceCenter () {
  yield takeEvery(actions.GET_TOTAL_SERVICE_CENTER_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { query: payload, url: claimsUrl.getTotalServiceCenter })

      if ([200, 201].includes(res.status)) {
        yield put({
          type: actions.GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'success')
        })
      } else {
        yield put({
          type: actions.GET_TOTAL_SERVICE_CENTER_LIST_FAILURE,
          data: _.get(res, 'data.data', []),
          message: _.get(res, 'data.message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOTAL_SERVICE_CENTER_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getBeneftListRequest),
    fork(createClaimRequest),
    fork(getClaimLists),
    fork(getDetailsClaim),
    fork(getClaimFilters),
    fork(sendReportClaims),
    fork(uploadCreateClaimDocRequest),
    fork(updateClaimFormWithGenericDoc),
    fork(createClaimWithDocumentRequest),
    fork(getValidateClaimRequest),
    fork(uploadGenericDocRequest),
    fork(saveGenericAttributesRequest),
    fork(verifyBankAccountForms),
    fork(saveDraftAttributesRequest),
    fork(postSalavageFormRequest),
    fork(generateSignedUrlAndUpload),
    fork(uploadFileUsingSignedUrl),
    fork(getBankList),
    fork(getServiceCenterOnClaim),
    fork(getProvinceListForServiceCenter),
    fork(getCityListForServiceCenter),
    fork(getPostalCodeForServiceCenter),
    fork(getServiceCenterBranchOnClaim),
    fork(getTotalServiceCenter)
  ])
}
