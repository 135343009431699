const actions = {
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_DASHBOARD_DATA_REQUEST: 'GET_DASHBOARD_DATA_REQUEST',
  GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
  GET_DASHBOARD_DATA_FAILURE: 'GET_DASHBOARD_DATA_FAILURE',
  FAILED_GET_DASHBOARD_DATA: 'FAILED_GET_DASHBAORD_DATA',

  GET_NOTIFICATION_GRAPH_DATA_REQUEST: 'GET_NOTIFICATION_GRAPH_DATA_REQUEST',
  GET_NOTIFICATION_GRAPH_DATA: 'GET_NOTIFICATION_GRAPH_DATA',
  GET_NOTIFICATION_GRAPH_DATA_SUCCESS: 'GET_NOTIFICATION_GRAPH_DATA_SUCCESS',
  GET_NOTIFICATION_GRAPH_DATA_FAILURE: 'GET_NOTIFICATION_GRAPH_DATA_FAILURE',

  GET_CLAIM_DASHBOARD_DATA: 'GET_CLAIM_DASHBOARD_DATA',
  GET_CLAIM_DASHBOARD_DATA_REQUEST: 'GET_CLAIM_DASHBOARD_DATA_REQUEST',
  GET_CLAIM_DASHBOARD_DATA_SUCCESS: 'GET_CLAIM_DASHBOARD_DATA_SUCCESS',
  GET_CLAIM_DASHBOARD_DATA_FAILURE: 'GET_CLAIM_DASHBOARD_DATA_FAILURE',

  GET_CLAIMABLE_DASHBOARD_REQUEST: 'GET_CLAIMABLE_DASHBOARD_REQUEST',
  GET_CLAIMABLE_DASHBOARD_DATA: 'GET_CLAIMABLE_DASHBOARD_DATA',
  GET_CLAIMABLE_DASHBOARD_DATA_SUCCESS: 'GET_CLAIMABLE_DASHBOARD_DATA_SUCCESS',
  GET_CLAIMABLE_DASHBOARD_DATA_FAILURE: 'GET_CLAIMABLE_DASHBOARD_DATA_FAILURE',

  GET_CLAIM_COMPLETION_TIME_REQUEST: 'GET_CLAIM_COMPLETION_TIME_REQUEST',
  GET_CLAIM_COMPLETION_TIME_DATA: 'GET_CLAIM_COMPLETION_TIME_DATA',
  GET_CLAIM_COMPLETION_TIME_DATA_SUCCESS: 'GET_CLAIM_COMPLETION_TIME_DATA_SUCCESS',
  GET_CLAIM_COMPLETION_TIME_DATA_FAILURE: 'GET_CLAIM_COMPLETION_TIME_DATA_FAILURE',

  GET_CLIENT_PARTNER_REQUEST: 'GET_CLIENT_PARTNER_REQUEST',
  GET_CLIENT_PARTNER_SUCCESS: 'GET_CLIENT_PARTNER_SUCCESS',
  GET_CLIENT_PARTNER_FAILURE: 'GET_CLIENT_PARTNER_FAILURE',

  GET_PACKAGE_DATA_REQUEST: 'GET_PACKAGE_DATA_REQUEST',
  GET_PACKAGE_DATA_SUCCESS: 'GET_PACKAGE_DATA_SUCCESS',
  GET_PACKAGE_DATA_FAILURE: 'GET_PACKAGE_DATA_FAILURE',

  GET_PACKAGES: 'GET_PACKAGES',
  DATA_PACKAGES: 'DATA_PACKAGES',
  FAILED_GET_DATA_PACKAGES: 'FAILED_GET_DATA_PACKAGES',

  dataPackages: payload => ({
    type: actions.DATA_PACKAGES,
    payload
  }),
  failedGetPackages: payload => ({
    type: actions.FAILED_GET_DATA_PACKAGES,
    payload
  }),
  getPackageRequest: payload => ({
    type: actions.GET_PACKAGES,
    payload
  }),
  getDashboardData: (payload) => ({
    type: actions.GET_DASHBOARD_DATA_REQUEST,
    payload
  }),
  getClaimDashBoardData: (payload) => ({
    type: actions.GET_CLAIM_DASHBOARD_DATA_REQUEST,
    payload
  }),
  updatedClaimDashBoardData: (payload) => ({
    type: actions.GET_CLAIM_DASHBOARD_DATA,
    payload
  }),
  getNotificationData: (payload) => ({
    type: actions.GET_NOTIFICATION_GRAPH_DATA_REQUEST,
    payload
  }),
  updatedNotificaticationData: (payload) => ({
    type: actions.GET_NOTIFICATION_GRAPH_DATA,
    payload
  }),
  getclaimableDashboarData: (payload) => ({
    type: actions.GET_CLAIMABLE_DASHBOARD_REQUEST,
    payload
  }),
  updatedClaimableDashboardData: (payload) => ({
    type: actions.GET_CLAIMABLE_DASHBOARD_DATA,
    payload
  }),
  getclaimCompletionTimeData: (payload) => ({
    type: actions.GET_CLAIM_COMPLETION_TIME_REQUEST,
    payload
  }),
  updatedCompletionTimeData: (payload) => ({
    type: actions.GET_CLAIM_COMPLETION_TIME_DATA,
    payload
  }),
  getClientPartners: () => ({
    type: actions.GET_CLIENT_PARTNER_REQUEST
  }),
  getPackageData: payload => ({
    type: actions.GET_PACKAGE_DATA_REQUEST,
    payload
  })
}

export default actions
