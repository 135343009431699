import { Alert, Form } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Input from '../../../../components/uielements/input'
import Button from '../../../../components/uielements/button'
import ForgotPasswordStyleWrapper from './forgotPassword.style'
import userAction from '../../redux/user/actions'
import { regExp } from '../../../../helpers/utility'

const { forgotPassword } = userAction
const FormItem = Form.Item

const ForgotPassword = ({
  forgotPasswordRequestLoading,
  forgotPasswordRequestError,
  forgotPasswordStatus,
  forgotPassword,
  form
}) => {
  const onHandleSubmit = (e) => {
    e.preventDefault()
    form.validateFields((error, values) => {
      if (!error) {
        const { email } = values
        forgotPassword(email)
      }
    })
  }

  const { getFieldDecorator } = form
  return (
    <ForgotPasswordStyleWrapper className='isoForgotPassPage'>
      <div className='isoFormContentWrapper'>
        <div className='isoFormContent'>
          <div className='isoLogoWrapper'>
            <Link to='/signin'>Partners Panel</Link>
          </div>
          {!forgotPasswordRequestLoading &&
            !forgotPasswordRequestError &&
            forgotPasswordStatus && (
            <Alert
              message='Success!'
              description='We just sent reset password link to your email. Please check your email!'
              type='success'
            />
          )}
          {!forgotPasswordRequestLoading &&
            !!forgotPasswordRequestError &&
            forgotPasswordStatus && (
            <Alert
              message='Warning!'
              description='Your email is invalid! Please contact cs@pasarpolis.co.id.'
              type='warning'
              className='margin-bottom'
            />
          )}
          {((!forgotPasswordRequestLoading && !forgotPasswordStatus) ||
            forgotPasswordRequestError) && (
            <div>
              <div className='isoFormHeadText'>
                <h3>Forgot Password?</h3>
                <p>Enter your email and we send you a reset link.</p>
              </div>
              <Form onSubmit={onHandleSubmit}>
                <div className='isoForgotPassForm'>
                  <div className='isoInputWrapper'>
                    <FormItem>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            pattern: regExp.email,
                            message: 'Enter a valid email address'
                          }
                        ]
                      })(<Input size='large' placeholder='Email' />)}
                    </FormItem>
                  </div>
                  <div className='isoInputWrapper'>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={forgotPasswordRequestLoading}
                      disabled={
                        !form.getFieldValue('email') ||
                        forgotPasswordRequestLoading
                      }
                    >
                      Send request
                    </Button>
                    <Link to='/' className='isoForgotPass'>
                      Back to Login Page
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </ForgotPasswordStyleWrapper>
  )
}

const WrappedForm = Form.create({})(ForgotPassword)

export default connect(
  (state) => ({
    forgotPasswordStatus: state.User.forgotPasswordStatus,
    forgotPasswordRequestLoading: state.Loading.FORGOT_PASSWORD,
    forgotPasswordRequestError: state.Error.FORGOT_PASSWORD
  }),
  {
    forgotPassword
  }
)(WrappedForm)
