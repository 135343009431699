import actions from './actions'

const initialState = {
  getActiveClaimsStatus: '',
  getAllClaimsStatus: '',
  createClaimShippingStatus: '',
  uploadClaimPhotosStatus: '',
  uploadImageStatus: '',
  submitClaimStatus: '',
  uploadBulkImageStatus: '',
  activeClaimDetailStatus: '',
  apiIntegrateStatus: '',
  trackingInformationStatus: ''
}

export default function partnerReducer (state = initialState, action) {
  switch (action.type) {
    case actions.RESET_REDUX_STATE_SHIPPING:
      return {
        ...state,
        getActiveClaimsStatus: '',
        getAllClaimsStatus: '',
        createClaimShippingStatus: '',
        uploadClaimPhotosStatus: '',
        uploadImageStatus: '',
        submitClaimStatus: '',
        uploadBulkImageStatus: '',
        activeClaimDetailStatus: '',
        apiIntegrateStatus: '',
        trackingInformationStatus: ''
      }
    case actions.CLEAR_UPLOAD_PHOTO_STATUS:
      return {
        ...state,
        uploadClaimPhotosStatus: ''
      }
    case actions.ACTIVE_CLAIMS_DATA:
      return {
        ...state,
        dataActiveClaims: action.data,
        getActiveClaimsStatus: action.getActiveClaimsStatus
      }
    case actions.ALL_CLAIMS_DATA:
      return {
        ...state,
        dataAllClaims: action.data,
        getAllClaimsStatus: action.getAllClaimsStatus
      }
    case actions.CREATE_SHIPPING_CLAIM_STATUS:
      return {
        ...state,
        dataCreateShipping: action.data,
        createClaimShippingStatus: action.createClaimShippingStatus
      }
    case actions.UPLOAD_SHIPPING_CLAIM_PHOTOS_STATUS:
      return {
        ...state,
        dataUploadShippingClaim: action.data,
        uploadClaimPhotosStatus: action.uploadClaimPhotosStatus
      }
    case actions.SUBMIT_CLAIM_STATUS:
      return {
        ...state,
        dataSubmitClaim: action.data,
        submitClaimStatus: action.submitClaimStatus
      }
    case actions.UPLOAD_BULK_IMAGE_STATUS:
      return {
        ...state,
        uploadBulkImageStatus: action.uploadBulkImageStatus,
        dataBulkUpload: action.data
      }
    case actions.ACTIVE_CLAIM_DETAIL_STATUS:
      return {
        ...state,
        activeClaimDetailStatus: action.activeClaimDetailStatus,
        dataActiveClaimDetail: action.data
      }
    case actions.API_INTEGRATION_STATUS:
      return {
        ...state,
        apiIntegrateStatus: action.apiIntegrateStatus
      }
    case actions.TRACKING_INFORMATION_STATUS:
      return {
        ...state,
        trackingInformationStatus: action.trackingInformationStatus
      }
    default:
      return state
  }
}
