import actions from './actions'

const initialState = {
  data: [],
  getNotficationsListStatus: '',
}

export default function partnerReducer (state = initialState, action) {
  switch (action.type) {
    case actions.RESET_NOTIFICATION_CORE:
      return {
        ...state,
        getNotficationsListStatus: ''
      }
    case actions.GET_NOTIFICATIONS_LIST_SUCCESS:
    case actions.GET_NOTIFICATIONS_LIST_FAILURE:
      return {
        ...state,
        notificationsListData: action.data,
        getNotficationsListStatus: action.getNotficationsListStatus
      }
    default:
      return state
  }
}
