import Auth from './auth/reducer'
import App from './app/reducer'
import Error from './error/reducer'
import Loading from './loading/reducer'
import Dashboard from '../containers/partnerApp/redux/dashboard/reducer'
import Policies from '../containers/partnerApp/redux/policies/reducer'
import User from '../containers/partnerApp/redux/user/reducer'
import Claims from '../containers/partnerApp/redux/claims/reducer'
import Shipping from '../containers/partnerApp/redux/shipping/reducer'
import Notifications from '../containers/partnerApp/redux/notifications/reducer'
import Upload from '../containers/partnerApp/redux/upload/reducer'
import UserManagement from '../containers/partnerApp/redux/user-engagement/reducer'
import FeedBack from '../containers/partnerApp/redux/feedback/reducer'
import Customer from '../containers/partnerApp/redux/customer/reducer'
import Reporting from '../containers/partnerApp/redux/reporting/reducer'
import Insight from '../containers/partnerApp/redux/insights/reducer'
import Wallet from '../containers/partnerApp/redux/Asowallet/reducer'

export default {
  Auth,
  App,
  Error,
  Loading,
  Dashboard,
  Policies,
  User,
  Claims,
  Shipping,
  Notifications,
  Upload,
  UserManagement,
  FeedBack,
  Customer,
  Reporting,
  Insight,
  Wallet
}
