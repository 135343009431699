import React from 'react'
import { Provider } from 'react-redux'
import { store, history } from './redux/store'
import PublicRoutes from './router'
import { ThemeProvider } from 'styled-components'
import { LocaleProvider } from 'antd'
import themes from './settings/themes'
import { themeConfig } from './settings'
import DashAppHolder from './dashAppStyle'
import Boot from './redux/boot'
import { ErrorContextProvider, GlobalContextProvider, LoaderContextProvider, ServiceContextProvider } from '@pasarpolis/common-react-hooks'
import { getHeader, getErrorMessage } from './helpers/utility'
import { initialGlobalState } from './containers/partnerApp/containers/ProductInsights/state/reducer'

const DashApp = () => (
  <LocaleProvider locale='english'>
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <DashAppHolder>
        <Provider store={store}>
          <GlobalContextProvider globalState={initialGlobalState} >
            <ErrorContextProvider>
              <LoaderContextProvider>
                <ServiceContextProvider
                  headers={getHeader()}
                  getGlobalErrorMessages={
                    (status, error) => getErrorMessage(status, error && error.error_code)
                  }
                >
                  <PublicRoutes history={history} />
                </ServiceContextProvider>
              </LoaderContextProvider>
            </ErrorContextProvider>
          </GlobalContextProvider>
        </Provider>
      </DashAppHolder>
    </ThemeProvider>
  </LocaleProvider>
)
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error))

export default DashApp
