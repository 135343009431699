const actions = {
  GET_INSIGHTS_REQUEST: 'GET_INSIGHTS_REQUEST',
  GET_INSIGHTS_SUCCESS: 'GET_INSIGHTS_SUCCESS',
  GET_INSIGHTS_FAILURE: 'GET_INSIGHTS_FAILURE',

  getInsightsData: payload => ({
    type: actions.GET_INSIGHTS_REQUEST,
    payload
  }),
}

export default actions
