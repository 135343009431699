import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import callApi from '../../helpers/callApi'
import middleware from '../../middleware/responseChecker'

export function * getConfig () {
  yield takeEvery(actions.GET_CONFIG_REQUEST, function * (payload) {
    let res = yield call(callApi.getConfig, payload)
    if (res.status === 200) {
      const {
        user_details: userDetails,
        user_permissions: userPermissions,
        partner_details: partnerDetails,
        default_2fa_option: default2FAOption,
        totp_enabled: totpEnabled,
        enable_2fa_auth: enable2FAAuth
      } = res.data
      yield localStorage.setItem('username', userDetails.email)
      yield localStorage.setItem('config', JSON.stringify(partnerDetails.configs))
      yield localStorage.setItem('permissions', JSON.stringify(userPermissions))
      yield put({ type: actions.GET_CONFIG_SUCCESS, userDetails, userPermissions, partnerDetails, default2FAOption, totpEnabled, enable2FAAuth })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_CONFIG_FAILURE,
        data: res.data
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getConfig)
  ])
}
