import axios from 'axios'
import { getHeader } from '../../helpers/utility'

const getRequest = async (url, payload) => {
  let headers = { headers: getHeader() }
  if (payload !== undefined && Object.keys(payload).length > 0) {
    headers = { params: payload, headers: getHeader() }
  }
  return await axios.get(url, headers)
}

export default getRequest
