import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export class ConfigChecker extends Component {
  render () {
    const { partnerDetails, requiredConfig, requiredValue } = this.props
    const partnerConfigs = partnerDetails.configs || []
    if (partnerConfigs.find((config = {}) => config.value === requiredValue && config?.config_type?.name === requiredConfig)) {
      return this.props.children
    }
    return null
  }
}

ConfigChecker.propTypes = {
  partnerDetails: PropTypes.object.isRequired,
  requiredConfig: PropTypes.string.isRequired
}

ConfigChecker.defaultProps = {
  partnerDetails: {}
}

export default connect(state => ({ partnerDetails: state.App.partnerDetails }), {})(ConfigChecker)
