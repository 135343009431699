import ReactDOM from 'react-dom'
import DashApp from './dashApp'
// import { unregister } from './registerServiceWorker';
import 'antd/dist/antd.css'
import './index.css'
// import 'ag-grid/dist/styles/ag-grid.css'
// import 'ag-grid/dist/styles/ag-theme-material.css'
// import 'react-day-picker/lib/style.css'
// import 'ag-grid-enterprise'

ReactDOM.render(<DashApp />, document.getElementById('root'))

// unregister();
