import { ConnectedRouter } from 'react-router-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import App from './containers/App/App'
import UserAuth from './containers/partnerApp/containers/UserAuth'
import ResetPassword from './containers/partnerApp/containers/ResetPassword/resetPassword'
import ForgotPassword from './containers/partnerApp/containers/ForgotPassword/forgotPassword'
import Signin from './containers/Page/signin'
import SuspectActivity from './containers/partnerApp/containers/SuspectActivity/index'

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        isLoggedIn
          ? (
            <Component {...props} />
          )
          : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
      )}
    />
  )
}

const PublicRoutes = ({ isLoggedIn, history }) => {
  return (
    <div>
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            <Route
              exact
              path='/'
              component={Signin}
            />
            <Route
              exact
              path='/signin'
              component={Signin}
            />
            <Route
              exact
              path={'/forgot-password'}
              component={ForgotPassword}
            />
            <Route
              exact
              path={'/reset-password/:uuid'}
              component={ResetPassword}
            />
            <Route
              exact
              path={'/suspect-activity/:userkey'}
              component={SuspectActivity}
            />
            <Route
              exact
              path={'/auth'}
              component={UserAuth}
            />
            <RestrictedRoute
              path='/dashboard'
              component={App}
              isLoggedIn={isLoggedIn}
            />
            <Redirect to='/dashboard' />
          </Switch>
        </div>
      </ConnectedRouter>
    </div>
  )
}

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null

}))(PublicRoutes)
