import { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import Security from './security'
import popUpImg from './../../image/2fa_popup.png'
import { TwoFactorAuthStyle } from './TwoFactorAuth.style'

export class TwoFactorAuth extends Component {
  state = {
    popUpWillShow: true,
    showPopup: true,
    showSecurityPopup: false
  };

  componentDidMount () {
    let popStatus = localStorage.getItem('popStatus')
    if (popStatus) {
      this.setState({ popUpWillShow: false })
    }
  }

  handle2FAPopUp = () => {
    this.setState({ showPopup: false })
    localStorage.setItem('popStatus', 1)
  };

  handlePopup = () => {
    this.setState({ showPopup: false })
    this.setState({ showSecurityPopup: true })
    localStorage.setItem('popStatus', 1)
  };

  render () {
    const { enable2FAAuth, userDetails } = this.props

    if (enable2FAAuth || userDetails.success_login_count !== 1) {
      return null
    }
    return (
      <Fragment>
        {this.state.showPopup && this.state.popUpWillShow && (
          <Modal
            visible={this.state.showPopup}
            title='Two-Factor Authentification'
            footer={null}
            onCancel={this.handle2FAPopUp}
          >
            <TwoFactorAuthStyle>
              <div className='twoFactor__imgContainer'>
                <img src={popUpImg} alt='pop' className='twoFactorAuth__img' />
              </div>
              <h1 className='twoFactorAuth__h1'>Activate Now</h1>
              <p>
                By Enabling the two-factor Authentification your account will
                greatly decrease the chances of hackers gaining access to
                corporate devices or other sensitive informations
              </p>
              <div className='twoFactorAuth__lower'>
                <div className='twoFactorAuth__lowerLeft'>
                  <b
                    onClick={this.handle2FAPopUp}
                    className='twoFactorAuth__lowerLeftButton'
                  >
                    I'll enable this later
                  </b>
                  <small>We will ask this again periodically</small>
                </div>

                <button
                  onClick={this.handlePopup}
                  className='twoFactorAuth__btn'
                >
                  <b>ENABLE</b>
                </button>
              </div>
            </TwoFactorAuthStyle>
          </Modal>
        )}

        <Modal
          visible={this.state.showSecurityPopup}
          title='Security'
          footer={null}
          onCancel={() => this.setState({ showSecurityPopup: false })}
        >
          <Security
            handleClose={() => this.setState({ showSecurityPopup: false })}
          />
        </Modal>
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    default2FAOption: state.App.default2FAOption,
    partnerDetails: state.App.partnerDetails,
    enable2FAAuth: state.App.enable2FAAuth,
    topbarActiveModal: state.User.topbarActiveModal
  }),
  {}
)(TwoFactorAuth)
