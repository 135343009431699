import actions from './actions'


const initialState = {
feedbackDashboardData:[],
feedbackDashboardMessage:'',
feedbackTableData:[],
feedbackTableMessage:''
}

export default function feedBackReducer(state = initialState , action){
    switch(action.type){
        
        case actions.SUCCESS_FEEDBACK_DASHBOARD:
        case actions.ERROR_FEEDBACK_DASHBOARD:
            return {
                ...state,
                feedbackDashboardData:[action.data],
                feedbackDashboardMessage:action.feedbackDashboardMessage
            }
        case actions.SUCCESS_GET_FEEDBACK_TABLE:
        case actions.ERROR_GET_FEEDBACK_TABLE:
            return {
                ...state,
                feedbackTableData:[action.data],
                feedbackTableMessage: action.feedbackTableMessage
            }
        default:
            return state;    
    }
}