import moment from 'moment'

export const campaignColumns = [
  {
    title: 'No.',
    key: 'sno',
    width: 100,
    render: (text, object, index) => `${index + 1}.`
  },
  {
    title: 'Source',
    dataIndex: 'ga_source',
    sorter: (a, b) => a.ga_source.localeCompare(b.ga_source),
    key: 1,
    width: 150,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>
  },
  {
    title: 'Medium',
    dataIndex: 'ga_medium',
    key: 2,
    width: 200,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
    sorter: (a, b) => a.ga_medium.localeCompare(b.ga_medium)
  },
  {
    title: 'Campaign Name',
    dataIndex: 'ga_campaign',
    key: 3,
    width: 200,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
    sorter: (a, b) => a.ga_campaign.localeCompare(b.ga_campaign)
  },
  {
    title: 'Page Name',
    dataIndex: 'page_display_name',
    key: 4,
    width: 250,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
    sorter: (a, b) => a.page_display_name.localeCompare(b.page_display_name)
  },
  {
    title: 'Page views',
    dataIndex: 'ga_pageviews',
    key: 5,
    width: 150,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
    sorter: (a, b) => a.ga_pageviews - b.ga_pageviews,
    align: 'right'
  },
  {
    title: 'Bounce Rate',
    dataIndex: 'ga_bouncerate',
    key: 6,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
    sorter: (a, b) => a.ga_bouncerate - b.ga_bouncerate,
    width: 150,
    align: 'right'
  },
  {
    title: 'Transactions',
    dataIndex: 'transactions',
    key: 7,
    width: 150,
    render: text => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
    sorter: (a, b) => a.transactions - b.transactions,
    align: 'right'
  }
]

export const pageViewsColumns = [
  {
    title: 'No.',
    key: 'sno',
    width: 100,
    render: (text, object, index) => `${index + 1}.`
  },
  {
    title: 'Page Name',
    dataIndex: 'page_display_name',
    sorter: (a, b) => a.page_display_name.localeCompare(b.page_display_name),
    key: 1
  },
  {
    title: 'Page Views',
    dataIndex: 'ga_pageviews',
    sorter: (a, b) => a.ga_pageviews - b.ga_pageviews,
    key: 2,
    width: '30%',
    align: 'right'
  }
]

export const campaignData = [
  { source: 'Pasarpolis/inbox', campaign: 'CNY', page_views: 1000, bounce_rates: '80.63%', transactions: 1000 },
  { source: 'Pasarpolis/organic', campaign: 'CNY', page_views: 1000, bounce_rates: '8.03%', transactions: 1000 }
]

export const pageViewsData = [
  { page: 'Home Page', page_views: 1000 },
  { page: 'Registration Page', page_views: 6000 }
]

export const getMomentDateBeforeDays = (days) => {
  return moment().subtract(days, 'd')
}
