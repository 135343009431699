import styled from 'styled-components'

export const SuspectActivityStyle = styled.div`
  padding: 40px;
  font-size: 12px;
  .row { 
      display: flex; 
      justify-content: center;
    }
  .col {
    text-align: center;
    }
`