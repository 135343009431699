import styled from 'styled-components'

export const TopbarUserStyle = styled.div`
  .link {
    cursor: pointer;
    margin-left: 12px;
  }
  .logout {
    color: #FF0000;
  }
  .ant-divider-horizontal {
    margin: 12px 0px !important;
  }
`

export const TopbarPopupStyle = styled.div`
  .avatar {
    background-color: #00a2ae;
    vertical-align: middle;
  }
`

export const UserProfileStyle = styled.div`
  .heading {
    font-weight: 600;
  }
  .ant-row {
    margin-bottom: 8px;
  }
  .change-icon {
    width: 20px;
    margin-left: 4px;
    cursor: pointer;
  }
`

export const SecurityStyle = styled.div`
  .heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  /* .ant-radio-wrapper {
    display: flex;
  } */
  .security-option-container {
    border: 1px solid #eee;
    padding: 12px;
    margin-top: 14px;
    cursor: pointer;
  }
  .security-heading {
    font-weight: 600;
  }
  .sub-heading {
    margin-bottom: 8px;
  }
  .security-heading-1 {
    font-weight: 600;
    margin-left: 10px;
  }
  .sub-security-heading {
    color: #a9a9a9;
    width: 100%;
  }
  .update-button {
    margin-top: 20px;
  }
  .disabled {
    cursor: not-allowed;
  }
  .setup-option {
    color: #1890ff;
    font-weight: bold;
    margin-left: 4px;
    cursor: pointer;
  }
`
