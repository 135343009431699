const parseResponseError = (err, initialError) => {
  let errString = ''
  if (err !== undefined) {
    if (typeof err === 'string') {
      return err
    }
    if (typeof err === 'object' && Object.keys(err).length > 0) {
      for (let key in err) {
        if (err[key]) {
          errString += err[key]?.join(',')
        }
      }
      errString = errString.slice(0, -1)
    }
  }
  if (errString !== '') {
    return errString
  } else {
    return initialError
  }
}

export default parseResponseError
