const host = `${process.env.REACT_APP_API_ENDPOINT}/`
const getBenefits = payload => {
  return `${host}api/v1/claims/benefits/?package=${payload.package}&product=${
    payload.product
  }`
}

const getFormBenefits = payload => {
  return `${host}api/v1/claims/form/${payload}/`
}

const postClaim = () => {
  return `${host}api/v1/claims/create/`
}

const getClaimDetailsUrl = payload => {
  return `${host}api/v1/claims/details/?claim_id=${payload}`
}

const saveRequest = payload => {
  return `${host}api/v1/claims/save-attributes/?claim_id=${payload}`
}

const uploadClaimDocument = payload => {
  return `${host}api/v1/claims/upload/?claim_id=${payload}`
}

const uploadGenericClaimDocument = payload => {
  return `${host}api/v1/claims/generic-upload/?claim_id=${
    payload.claim_id
  }&form=${payload.form}`
}
const saveDraftAttributes = payload => {
  return `${host}api/v1/claims/save-draft/?claim_id=${payload.claim_id}&form=${payload.form}`
}

const saveGenericAttributesUrl = () => {
  return `${host}api/v1/claims/save-generic-attributes/`
}

const finalSubmitUrl = payload => {
  return `${host}api/v1/claims/final-submit/?claim_id=${payload.claim_id}`
}

const verifyBankAccount = () => {
  return `${host}api/v1/claims/verify-bank-account/`
}

const validateClaimUrl = () => {
  return `${host}api/v1/claims/validate-claim/`
}

const getFeedbackFormsUrl = () => {
  return `${host}api/v1/claims/feedback_forms/`
}
const getFeedbackEmailFormsUrl = () => {
  return `${host}api/v1/feedback/feedback_forms/`
}

const postFeedbackFormsUrl = () => {
  return `${host}api/v1/claims/give_feedback/`
}

const postFeedbackEmailFormsUrl = () => {
  return `${host}api/v1/feedback/give_feedback/`
}
const postSalvageFormUrl = () => {
  return `${host}api/v1/claims/submit-salvage-form/`
}
const createShippingClaim = () => {
  return `${host}api/v1/claims/create-shipping-claim/`
}


export default {
  getBenefits,
  getFormBenefits,
  postClaim,
  getClaimDetailsUrl,
  saveRequest,
  uploadClaimDocument,
  uploadGenericClaimDocument,
  saveGenericAttributesUrl,
  finalSubmitUrl,
  verifyBankAccount,
  validateClaimUrl,
  getFeedbackFormsUrl,
  postFeedbackFormsUrl,
  getFeedbackEmailFormsUrl,
  postFeedbackEmailFormsUrl,
  postSalvageFormUrl,
  createShippingClaim,
  saveDraftAttributes
}
