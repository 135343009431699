const actions = {
  SAVE_SEARCH_STRING: 'SAVE_SEARCH_STRING',

  GET_CUSTOMER_SEARCH_RESULT_REQUEST: 'GET_CUSTOMER_SEARCH_RESULT_REQUEST',
  GET_CUSTOMER_SEARCH_RESULT_SUCCESS: 'GET_CUSTOMER_SEARCH_RESULT_SUCCESS',
  GET_CUSTOMER_SEARCH_RESULT_FAILURE: 'GET_CUSTOMER_SEARCH_RESULT_FAILURE',
  GET_CUSTOMER_SEARCH_RESULT_DATA: 'GET_CUSTOMER_SEARCH_RESULT_DATA',

  GET_CUSTOMER_DETAILS_REQUEST: 'GET_CUSTOMER_DETAILS_REQUEST',
  GET_CUSTOMER_DETAILS_SUCCESS: 'GET_CUSTOMER_DETAILS_SUCCESS',
  GET_CUSTOMER_DETAILS_FAILURE: 'GET_CUSTOMER_DETAILS_FAILURE',
  GET_CUSTOMER_DETAILS_DATA: 'GET_CUSTOMER_DETAILS_DATA',

  GET_CUSTOMER_POLICY_DETAILS_REQUEST: 'GET_CUSTOMER_POLICY_DETAILS_REQUEST',
  GET_CUSTOMER_POLICY_DETAILS_SUCCESS: 'GET_CUSTOMER_POLICY_DETAILS_SUCCESS',
  GET_CUSTOMER_POLICY_DETAILS_FAILURE: 'GET_CUSTOMER_POLICY_DETAILS_FAILURE',
  GET_CUSTOMER_POLICY_DETAILS_DATA: 'GET_CUSTOMER_POLICY_DETAILS_DATA',

  GET_CUSTOMER_CLAIM_DETAILS_REQUEST: 'GET_CUSTOMER_CLAIM_DETAILS_REQUEST',
  GET_CUSTOMER_CLAIM_DETAILS_SUCCESS: 'GET_CUSTOMER_CLAIM_DETAILS_SUCCESS',
  GET_CUSTOMER_CLAIM_DETAILS_FAILURE: 'GET_CUSTOMER_CLAIM_DETAILS_FAILURE',
  GET_CUSTOMER_CLAIM_DETAILS_DATA: 'GET_CUSTOMER_CLAIM_DETAILS_DATA',

  saveSearchString: payload => ({
    type: actions.SAVE_SEARCH_STRING,
    payload
  }),
  getCustomerData: payload => ({
    type: actions.GET_CUSTOMER_SEARCH_RESULT_REQUEST,
    payload
  }),
  getCustomerDetails: payload => ({
    type: actions.GET_CUSTOMER_DETAILS_REQUEST,
    payload
  }),
  getCustomerPolicyDetails: payload => ({
    type: actions.GET_CUSTOMER_POLICY_DETAILS_REQUEST,
    payload
  }),
  getCustomerClaimDetails: payload => ({
    type: actions.GET_CUSTOMER_CLAIM_DETAILS_REQUEST,
    payload
  })
}

export default actions;
