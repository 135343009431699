import actions from "./actions";

const initialState = {
  chartData: [],
  totalPayment: 0,
  totalPolicies: 0,
  totalTransaction: 0,
  products: [],
  packages:[],
  dashboardDataMessage: "",

  claimDashBoardMessage: "",
  claimDashboardData: "",

  notificationDashboardMessage: "",
  notificationDashboardData: "",

  getclaimableDashboardData: "",
  getclaimableDashboardDataMessage: "",

  getclaimCompletionTimeData: "",
  getclaimCompletionTimeDataMessage: "",
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        chartData: action.chartData,
        products: action.products,
        totalPayment: action.totalPayment,
        totalPolicies: action.totalPolicies,
        totalTransaction: action.totalTransaction,
        dashboardDataMessage: action.message,
        packages: action.packages
      };
    case actions.GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        dashboardDataMessage: action.message,
      };
    case actions.GET_CLAIM_DASHBOARD_DATA_SUCCESS:
    case actions.GET_CLAIM_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        claimDashboardData: action.data,
        claimDashBoardMessage: action.message,
      };
    case actions.GET_NOTIFICATION_GRAPH_DATA_SUCCESS:
    case actions.GET_NOTIFICATION_GRAPH_DATA_FAILURE:
      return {
        ...state,
        notificationDashboardData: action.data,
        notificationDashboardMessage: action.message,
      };
    case actions.GET_CLAIMABLE_DASHBOARD_DATA_SUCCESS:
    case actions.GET_CLAIMABLE_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        getclaimableDashboardData: action.data,
        getclaimableDashboardDataMessage: action.message,
      };
    case actions.GET_CLAIM_COMPLETION_TIME_DATA_SUCCESS:
    case actions.GET_CLAIM_COMPLETION_TIME_DATA_FAILURE:
      return {
        ...state,
        getclaimCompletionTimeData: action.data,
        getclaimCompletionTimeDataMessage: action.message,
      };
    case actions.GET_CLIENT_PARTNER_SUCCESS:
    case actions.GET_CLIENT_PARTNER_FAILURE:
      return {
        ...state,
        clientPartners: action.data,
        allClientPartners: action.allClientPartners,
      };
    case actions.GET_PACKAGE_DATA_SUCCESS:
    case actions.GET_PACKAGE_DATA_FAILURE:
      return {
        ...state,
        packageLevelData: action.data,
      };
    case actions.DATA_PACKAGES:
      return {
        ...state,
        packages: action.packages,
      };
    case actions.GET_PACKAGES:
      return {
        ...state,
        packages: action.packages,
      };
    case actions.FAILED_GET_DATA_PACKAGES:
      return {
        ...state,
        failedMessageGetPackages: action.failedMessageGetPackages,
      };
    default:
      return state;
  }
}
