const actions = {
  GET_FILE_TEMPLATES_REQUEST: 'GET_FILE_TEMPLATES_REQUEST',
  GET_FILE_TEMPLATES_SUCCESS: 'GET_FILE_TEMPLATES_SUCCESS',
  GET_FILE_TEMPLATES_FAILURE: 'GET_FILE_TEMPLATES_FAILURE',
  GET_FILE_TEMPLATE_SAMPLE_REQUEST: 'GET_FILE_TEMPLATE_SAMPLE_REQUEST',
  GET_FILE_TEMPLATE_SAMPLE_SUCCESS: 'GET_FILE_TEMPLATE_SAMPLE_SUCCESS',
  GET_FILE_TEMPLATE_SAMPLE_FAILURE: 'GET_FILE_TEMPLATE_SAMPLE_FAILURE',
  GET_FILE_LIST_REQUEST: 'GET_FILE_LIST_REQUEST',
  GET_FILE_LIST_SUCCESS: 'GET_FILE_LIST_SUCCESS',
  GET_FILE_LIST_FAILURE: 'GET_FILE_LIST_FAILURE',
  GET_FILE_DETAILS_REQUEST: 'GET_FILE_DETAILS_REQUEST',
  GET_FILE_DETAILS_SUCCESS: 'GET_FILE_DETAILS_SUCCESS',
  GET_FILE_DETAILS_FAILURE: 'GET_FILE_DETAILS_FAILURE',
  GET_FILE_ROWS_REQUEST: 'GET_FILE_ROWS_REQUEST',
  GET_FILE_ROWS_SUCCESS: 'GET_FILE_ROWS_SUCCESS',
  GET_FILE_ROWS_FAILURE: 'GET_FILE_ROWS_FAILURE',
  GET_FILE_ROWS_EXCEL_REQUEST: 'GET_FILE_ROWS_EXCEL_REQUEST',
  GET_FILE_ROWS_EXCEL_SUCCESS: 'GET_FILE_ROWS_EXCEL_SUCCESS',
  GET_FILE_ROWS_EXCEL_FAILURE: 'GET_FILE_ROWS_EXCEL_FAILURE',
  POST_BULK_UPLOAD_FILE_REQUEST: 'POST_BULK_UPLOAD_FILE_REQUEST',
  POST_BULK_UPLOAD_FILE_SUCCESS: 'POST_BULK_UPLOAD_FILE_SUCCESS',
  POST_BULK_UPLOAD_FILE_FAILURE: 'POST_BULK_UPLOAD_FILE_FAILURE',
  RESET_UPLOAD_DATA_REDUX: 'RESET_UPLOAD_DATA_REDUX',

  CANCEL_UPLOAD_REQUEST: 'CANCEL_UPLOAD_REQUEST',
  CANCEL_UPLOAD_SUCCESS: 'CANCEL_UPLOAD_SUCCESS',
  CANCEL_UPLOAD_FAILURE: 'CANCEL_UPLOAD_FAILURE',

  DOWNLOAD_ORIGINAL_FILE_REQUEST: 'DOWNLOAD_ORIGINAL_FILE_REQUEST',
  DOWNLOAD_ORIGINAL_FILE_SUCCESS: 'DOWNLOAD_ORIGINAL_FILE_SUCCESS',
  DOWNLOAD_ORIGINAL_FILE_FAILURE: 'DOWNLOAD_ORIGINAL_FILE_FAILURE',

  getFileTemplates: (payload) => ({
    type: actions.GET_FILE_TEMPLATES_REQUEST,
    payload
  }),
  getFileTemplateSample: (payload) => ({
    type: actions.GET_FILE_TEMPLATE_SAMPLE_REQUEST,
    payload
  }),
  getFileList: (payload) => ({
    type: actions.GET_FILE_LIST_REQUEST,
    payload
  }),
  getFileDetails: (payload) => ({
    type: actions.GET_FILE_DETAILS_REQUEST,
    payload
  }),
  getFileRows: (payload) => ({
    type: actions.GET_FILE_ROWS_REQUEST,
    payload
  }),
  getFileRowsExcel: (payload) => ({
    type: actions.GET_FILE_ROWS_EXCEL_REQUEST,
    payload
  }),
  postBulkUploadFile: (payload) => ({
    type: actions.POST_BULK_UPLOAD_FILE_REQUEST,
    payload
  }),
  resetDataRedux: (payload) => ({
    type: actions.RESET_UPLOAD_DATA_REDUX,
    payload
  }),
  cancelUpload: (payload) => ({
    type: actions.CANCEL_UPLOAD_REQUEST,
    payload
  }),
  downloadOriginal: (payload) => ({
    type: actions.DOWNLOAD_ORIGINAL_FILE_REQUEST,
    payload
  })
}
export default actions
