import { all, takeEvery, call, fork, put } from 'redux-saga/effects'
import actions from './actions'
import authGet from '../../../../request/methods/authGet'
import NotificationsUrl from '../../../../config/urls/notification'

export function* getNotificationsList() {
  yield takeEvery(actions.GET_NOTIFICATIONS_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: NotificationsUrl.getNotificationsList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_NOTIFICATIONS_LIST_SUCCESS,
          data: res.data,
          getNotficationsListStatus: 'success'
        })
      } else {
        yield put({
          type: actions.GET_NOTIFICATIONS_LIST_FAILURE,
          data: [],
          getNotficationsListStatus: 'Gagal mengambil daftar notifikasi'
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_NOTIFICATIONS_LIST_FAILURE,
        data: [],
        getNotficationsListStatus: error.message
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getNotificationsList)
  ])
}
