import { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Row, Select, Input, notification } from 'antd'

import { AddPhoneNumberStyle } from './component.style'
import UserActions from '../../partnerApp/redux/user/actions'
import { get } from 'lodash'

const { sendOTPToUser } = UserActions

const FormItem = Form.Item
const { Option } = Select

class AddPhoneNumber extends Component {
  state = {
    visible: false
  }

  componentDidMount () {
    this.setState({ visible: this.props.visible })
  }

  componentWillReceiveProps (nextProps) {
    const { visible } = nextProps

    if (visible !== this.state.visible) {
      this.setState({ visible })
      this.props.form.resetFields()
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { userData } = this.props
        const { phone_number: phoneNumber, prefix } = values
        const phoneNumberWithPrefix = `${prefix}-${phoneNumber}`

        if (get(userData, 'phone') === phoneNumberWithPrefix) {
          return notification.error({ message: 'This number already exists with user. Please enter a different number to proceed.' })
        }
        this.props.sendOTPToUser({
          body: {
            otp_type: 'sms',
            phone_number: phoneNumberWithPrefix
          }
        })
      }
    })
  }

  render () {
    const { form, sendSMSOTPLoading } = this.props
    const { getFieldDecorator } = form
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '62'
    })(
      <Select className='select-option'>
        <Option value='62'>+62</Option>
        <Option value='66'>+66</Option>
        <Option value='84'>+84</Option>
        <Option value='60'>+60</Option>
        <Option value='65'>+65</Option>
        <Option value='63'>+63</Option>
        <Option value='886'>+886</Option>
        <Option value='86'>+852</Option>
        <Option value='61'>+61</Option>
      </Select>
    )

    return (
      <AddPhoneNumberStyle>
        <Form onSubmit={this.handleSubmit}>
          <FormItem label='Phone Number'>
            {
              getFieldDecorator('phone_number', {
                rules: [
                  {
                    required: true,
                    message: 'Phone number is not valid',
                    min: 7,
                    max: 17
                  }
                ]
              })(<Input
                addonBefore={prefixSelector}
              />)
            }
          </FormItem>
          <Row type='flex'>
            <Button
              type='primary'
              className='send-otp-button'
              htmlType='submit'
              loading={!!sendSMSOTPLoading}
            >
              Send OTP
            </Button>
          </Row>
        </Form>
      </AddPhoneNumberStyle>
    )
  }
}

const WrappedAddPhoneNumber = Form.create()(AddPhoneNumber)

export default connect(state => ({
  userData: state.Auth.profile,

  sendSMSOTPLoading: state.Loading.SEND_SMS_OTP
}), {
  sendOTPToUser
})(WrappedAddPhoneNumber)
