import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import middleware from '../../../../middleware/responseChecker'

import actions from './action'
import callApi from './../../../../helpers/callApi'

export function * getWalletList () {
  yield takeEvery(actions.GET_WALLET_LIST, function * ({ payload }) {
    let res = yield call(callApi.getWalletList, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_WALLET_LIST_SUCCESS,
        data: res.data,
        message: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_WALLET_LIST_FAILURE,
        data: {},
        message: 'error'
      })
    }
  }
  )
}
export function * getWalletTransaction () {
  yield takeEvery(actions.GET_WALLET_TRANSACTION, function * ({ payload }) {
    let res = yield call(callApi.getWalletTransaction, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_WALLET_TRANSACTION_SUCCESS,
        data: res.data,
        message: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_WALLET_TRANSACTION_FAILURE,
        data: {},
        message: 'error'
      })
    }
  }
  )
}

export default function * rootSaga () {
  yield all([
    fork(getWalletList),
    fork(getWalletTransaction)
  ])
}
