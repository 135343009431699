import { mapArrayElementsKeysToObjectKeys } from '../../../../../helpers/utility'
import { getMomentDateBeforeDays } from '../pages/Behaviour/container/utility'
import moment from 'moment'
import { PAGE_INSIGHT_ACTION } from './actions'

export const initialState = {
  startDate: getMomentDateBeforeDays(6),
  endDate: moment()
}

export const initialGlobalState = {
  productInsights: {
    startDate: moment(),
    endDate: moment()
  }
}

export default (state, action) => {
  const { payload } = action

  switch (action.type) {
    case PAGE_INSIGHT_ACTION.PRODUCT_LIST:
    case PAGE_INSIGHT_ACTION.CAMPAIGN_LIST:
    case PAGE_INSIGHT_ACTION.GA_DATA:
    case PAGE_INSIGHT_ACTION.PAGE_VIEWS:
    case PAGE_INSIGHT_ACTION.TRANSACTION:
    case PAGE_INSIGHT_ACTION.DEVICE_WISE_DATA:
    case PAGE_INSIGHT_ACTION.OS_WISE_DATA:
    case PAGE_INSIGHT_ACTION.BROWSER_WISE_DATA:
    case PAGE_INSIGHT_ACTION.CITIES_WISE_DATA:
    case PAGE_INSIGHT_ACTION.SET_END_DATE:
    case PAGE_INSIGHT_ACTION.SET_START_DATE:
      return { ...state, ...payload }
    case PAGE_INSIGHT_ACTION.RESET_STATE:
      return { ...state, ...mapArrayElementsKeysToObjectKeys(payload) }
    default:
      return state
  }
}
