import productInsightUrls from '../../../../../config/urls/productInsights'
import { getHeader } from '../../../../../helpers/utility'

export const PAGE_INSIGHT_ACTION = {
  PRODUCT_LIST_REQUEST: 'PRODUCT_LIST_REQUEST',
  PRODUCT_LIST: 'PRODUCT_LIST',
  CAMPAIGN_LIST_REQUEST: 'CAMPAIGN_LIST_REQUEST',
  CAMPAIGN_LIST: 'CAMPAIGN_LIST',
  RESET_STATE: 'RESET_STATE',
  GA_DATA_REQUEST: 'GA_DATA_REQUEST',
  GA_DATA: 'GA_DATA',
  PAGE_VIEWS_REQUEST: 'PAGE_VIEWS_REQUEST',
  PAGE_VIEWS: 'PAGE_VIEWS',
  TRANSACTION_REQUEST: 'TRANSACTION_REQUEST',
  TRANSACTION: 'TRANSACTION',
  DEVICE_WISE_DATA_REQUEST: 'DEVICE_WISE_DATA_REQUEST',
  DEVICE_WISE_DATA: 'DEVICE_WISE_DATA',
  OS_WISE_DATA_REQUEST: 'OS_WISE_DATA_REQUEST',
  OS_WISE_DATA: 'OS_WISE_DATA',
  BROWSER_WISE_DATA_REQUEST: 'BROWSER_WISE_DATA_REQUEST',
  BROWSER_WISE_DATA: 'BROWSER_WISE_DATA',
  CITIES_WISE_DATA_REQUEST: 'CITIES_WISE_DATA_REQUEST',
  CITIES_WISE_DATA: 'CITIES_WISE_DATA',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',

  setStartDate: date => ({
    type: PAGE_INSIGHT_ACTION.SET_START_DATE,
    payload: {
      startDate: date
    }
  }),
  setEndDate: date => ({
    type: PAGE_INSIGHT_ACTION.SET_END_DATE,
    payload: {
      endDate: date
    }
  }),
  getProductList: payload => ({
    type: PAGE_INSIGHT_ACTION.PRODUCT_LIST_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getProductList,
      headers: getHeader()
    }
  }),
  getCampaignList: payload => ({
    type: PAGE_INSIGHT_ACTION.CAMPAIGN_LIST_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getCampaignList,
      headers: getHeader()
    }
  }),
  getGaData: payload => ({
    type: PAGE_INSIGHT_ACTION.GA_DATA_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getCampaignData,
      headers: getHeader()
    }
  }),
  getPageViewsData: payload => ({
    type: PAGE_INSIGHT_ACTION.PAGE_VIEWS_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getPageViews,
      headers: getHeader()
    }
  }),
  getTransactionsData: payload => ({
    type: PAGE_INSIGHT_ACTION.TRANSACTION_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.pageViewsTransactions,
      headers: getHeader()
    }
  }),
  getDeviceWiseData: payload => ({
    type: PAGE_INSIGHT_ACTION.DEVICE_WISE_DATA_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getDeviceWiseData,
      headers: getHeader()
    }
  }),
  getOsWiseData: payload => ({
    type: PAGE_INSIGHT_ACTION.OS_WISE_DATA_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getOsWiseData,
      headers: getHeader()
    }
  }),
  getBrowserWiseData: payload => ({
    type: PAGE_INSIGHT_ACTION.BROWSER_WISE_DATA_REQUEST,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getBrowserWiseData,
      headers: getHeader()
    }
  }),
  getCitiesWiseData: payload => ({
    type: PAGE_INSIGHT_ACTION.CITIES_WISE_DATA,
    payload: {
      ...payload,
      method: 'get',
      url: productInsightUrls.getCitiesWiseData,
      headers: getHeader()
    }
  }),
  resetState: keys => ({
    type: PAGE_INSIGHT_ACTION.RESET_STATE,
    payload: keys
  })
}

// TODO: temp actions here, should be imported from common hooks
export const GLOBAL_ACTIONS = {
  GLOBAL_STATE_UPDATE: 'GLOBAL_STATE_UPDATE'
}

export const updateGlobalState = (payload) => ({
  type: GLOBAL_ACTIONS.GLOBAL_STATE_UPDATE,
  payload
})
