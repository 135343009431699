import actions from './actions'

const initialState = {
  PolicyList: [],
  PolicyListCount: {},
  PolicyDetail: {},
  PolicyDetailV2: {},
  failedGetPolicyDetail: {},
  message: '',
  policy: [],
  failedMessage: '',
  failedMessageGetProducts: '',
  getPolicyListMessage: '',
  sendPolicyReportStatus: '',
  masterList: {}
}

export default function policiesReducer (state = initialState, action) {
  switch (action.type) {
    case actions.RESET_REDUX_STATE_POLICY:
      return {
        ...state,
        getPolicyListMessage: '',
        message: '',
        failedMessage: '',
        failedMessageGetProducts: '',
        sendPolicyReportStatus: ''
      }
    case actions.GET_POLICY_LIST_SUCCESS:
    case actions.GET_POLICY_LIST_FAILURE:
      return {
        ...state,
        PolicyList: action.data,
        getPolicyListMessage: action.policyMessage
      }
    case actions.GET_POLICY_LIST_COUNT_SUCCESS:
    case actions.GET_POLICY_LIST_COUNT_FAILURE:
      return {
        ...state,
        PolicyListCount: action.data
      }
    case actions.FAILED_GET_POLICY_LIST:
      return { ...state, PolicyList: [], failedMessage: action.policyMessage }
    case actions.GET_POLICY_DETAIL_SUCCESS:
    case actions.GET_POLICY_DETAIL_FAILURE:
      return { ...state, PolicyDetail: action.data, message: action.message }
    case actions.GET_POLICY_DETAIL_SUCCESS_V2:
    case actions.GET_POLICY_DETAIL_FAILURE_V2:
      return { ...state, PolicyDetailV2: action?.data, messageV2: action.message }
    case actions.FAILED_GET_POLICY_DETAIL:
      return { ...state, PolicyDetailV2: {} }
    case actions.FAILED_INSURANCE_DATA:
      return {
        ...state,
        policy: action.data,
        failedMessage: action.policyMessage
      }
    case actions.DATA_PARTNERS:
      return {
        ...state,
        partners: action.partners
      }
    case actions.DATA_PRODUCTS:
      return {
        ...state,
        products: action.products
      }
    case actions.FAILED_GET_DATA_PRODUCTS:
      return {
        ...state,
        failedMessageGetProducts: action.failedMessageGetProducts
      }
    case actions.SUCCESS_SEND_REPORT_POLICY:
      return {
        ...state,
        sendPolicyReportStatus: action.sendPolicyReportStatus
      }
    case actions.FAILED_SEND_REPORT_POLICY:
      return {
        ...state,
        sendPolicyReportStatus: action.sendPolicyReportStatus
      }
    case actions.SUCCESS_SEND_REPORT_POLICY_RANGE:
      return {
        ...state,
        sendPolicyReportStatusRange: action.sendPolicyReportStatusRange
      }
    case actions.FAILED_SEND_REPORT_POLICY_RANGE:
      return {
        ...state,
        sendPolicyReportStatusRange: action.sendPolicyReportStatusRange
      }
    case actions.GET_MASTER_LIST_SUCCESS:
      return {
        ...state,
        masterList: action.data
      }
    default:
      return state
  }
}
