const actions = {
  GET_WALLET_LIST: 'GET_WALLET_LIST',
  GET_WALLET_LIST_SUCCESS: 'GET_WALLET_LIST_SUCCESS',
  GET_WALLET_LIST_FAILURE: 'GET_WALLET_LIST_FAILURE',

  GET_WALLET_TRANSACTION: 'GET_WALLET_TRANSACTION',
  GET_WALLET_TRANSACTION_SUCCESS: 'GET_WALLET_TRANSACTION_SUCCESS',
  GET_WALLET_TRANSACTION_FAILURE: 'GET_WALLET_TRANSACTION_FAILURE',


  getWalletList: () => ({
    type: actions.GET_WALLET_LIST
  }),
  getWalletListSuccess: (payload) => ({
    type: actions.GET_WALLET_LIST_SUCCESS,
    payload: payload
  }),
  getWalletListFailure: (payload) => ({
    type: actions.GET_WALLET_LIST_FAILURE,
    payload
  }),

  getWalletTransaction: () => ({
    type: actions.GET_WALLET_TRANSACTION
  }),
  getWalletTransactionSuccess: (payload) => ({
    type: actions.GET_WALLET_TRANSACTION_SUCCESS,
    payload: payload
  }),
  getWalletTransactionFailure: (payload) => ({
    type: actions.GET_WALLET_TRANSACTION_FAILURE,
    payload
  })
}

export default actions
