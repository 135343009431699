import { Component } from 'react'
import { Row, Col, Card, Button } from 'antd'

class Page404 extends Component {
    goToHome = () => {
      const { history } = this.props
      history.push('/dashboard')
    }
    render () {
      return (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card>
              <Row>
                <Col xs={24} sm={24} md={12} lg={24} xl={24} style={{ textAlign: 'center' }}>
                  <h2>Page not found !!!</h2>
                  <Button onClick={this.goToHome}>HomePage</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )
    }
}

export default Page404
