import actions from './actions';

const initialState ={
    userEngagementData:[],
    userEngagementDataMessage:'',

    userGroupData:[],
    userGroupDataMessage:'',

    timeZoneList:[],
    timeZoneListMessage:'',

    messageAddNewUser:'',
    messageeditUser:'',
    messageDeactivateUser:'',
    messageActivateUser:'',
    messageDeleteUser:''
}

export default function userEngagementReducer(state = initialState, action) {
    switch(action.type){
        case actions.RESET_REDUX_STATE:
            return {
                ...state,
                messageAddNewUser:'',
                messageeditUser:'',
                messageDeactivateUser:'',
                messageActivateUser:'',
                messageDeleteUser:''
            }
        case actions.GET_USERMANAGEMENT_LIST_SUCCESS:
        case actions.GET_USERMANAGEMENT_LIST_FAILURE:
            return {
                ...state,
                userEngagementData: [...action.data],
                userEngagementDataMessage: action.message
            }
        case actions.GET_USER_GROUP_LIST_SUCCESS:
        case actions.GET_USER_GROUP_LIST_FAILURE:
            return {
                ...state,
                userGroupData: [...action.data],
                userGroupDataMessage: action.message
            }
        case actions.GET_TIMEZONE_DATA_SUCCESS:
        case actions.GET_TIMEZONE_DATA_FAILURE:
            return {
                ...state,
                timeZoneList: [...action.data],
                timeZoneListMessage: action.message
            }
        case actions.POST_USER_GROUP_SUCCESS:
        case actions.POST_USER_GROUP_FAILED:
            return{
                ...state,
                messageAddNewUser: action.messageAddNewUser
            }
        case actions.EDIT_USER_GROUP_SUCCESS:
        case actions.EDIT_USER_GROUP_FAILED:
            return {
                ...state,
                messageeditUser: action.messageeditUser
            }
        case actions.DEACTIVATE_USER_SUCCESS:
        case actions.DEACTIVATE_USER_FAILURE:
            return {
                ...state,
                messageDeactivateUser:action.messageDeactivateUser
            }
        
        case actions.ACTIVATE_USER_SUCCESS:
        case actions.ACTIVATE_USER_FAILURE:
                return {
                    ...state,
                    messageActivateUser:action.messageActivateUser
                }
                
        case actions.DELETE_USER_GROUP_SUCCESS:
        case actions.DELETE_USER_GROUP_FAILED:
            return {
                ...state,
                messageDeleteUser:action.messageDeleteUser
            }
        default:
            return state;   
    }
}