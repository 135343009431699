import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import actions from './actions'
import callApi from '../../../../helpers/callApi'
import parseResponseError from '../../../../helpers/parseResponseError'
import { get } from 'lodash'
import userUrls from '../../../../config/urls/user'
import post from '../../../../request/methods/post'
import authPost from '../../../../request/methods/authPost'
import authGet from '../../../../request/methods/authGet'

import authActions from '../../../../redux/app/actions'

export function * getForgotPasswordRequest () {
  yield takeEvery(actions.FORGOT_PASSWORD_REQUEST, function * (payload) {
    let res = yield call(callApi.forgotPassword, payload)
    if (res.status === 200) {
      yield put({ type: actions.FORGOT_PASSWORD_SUCCESS, data: res })
    } else {
      yield put({ type: actions.FORGOT_PASSWORD_FAILURE, data: res })
    }
  })
}

export function * resetPasswordRequest () {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function * (payload) {
    let res = yield call(callApi.resetPassword, payload)
    if (res.status === 200) {
      yield put({ type: actions.RESET_PASSWORD_SUCCESS, data: res.data })
    } else {
      yield put({
        type: actions.RESET_PASSWORD_FAILURE,
        data: get(res.data, 'password') ? parseResponseError(res.data, 'Something went wrong') : get(res.data, 'message', 'Something went wrong. Please try again')
      })
    }
  })
}

export function * suspectActivityRequest () {
  yield takeEvery(actions.SUSPECT_ACTIVITY_REQUEST, function * ({ payload }) {
    yield call(post, { ...payload, url: userUrls.suspectActivity })
  })
}

export function * validateResetIdRequest () {
  yield takeEvery(actions.VALIDATE_RESET_ID_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(callApi.validateResetId, payload)
      if (res.status === 200) {
        yield put({ type: actions.VALIDATE_RESET_ID_SUCCESS, message: res.data })
      } else if (res.status === 400) {
        yield put({ type: actions.VALIDATE_RESET_ID_FAILURE, message: res.data })
      } else {
        yield put({ type: actions.VALIDATE_RESET_ID_FAILURE, message: 'Failed getting reset Id validity' })
      }
    } catch (e) {
      yield put({ type: actions.VALIDATE_RESET_ID_FAILURE, message: 'Failed getting reset Id validity' })
    }
  })
}

export function * sendSMSOTP () {
  yield takeEvery(actions.SEND_SMS_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.sendSMSOTP })
      if (res.status === 200) {
        yield put({
          type: actions.SEND_SMS_OTP_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'OTP sent!')
        })
      } else {
        yield put({
          type: actions.SEND_SMS_OTP_FAILURE,
          data: {},
          message: get(res.data, 'message', 'OTP sending failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.SEND_SMS_OTP_FAILURE,
        data: {},
        message: get(e.data, 'message', 'OTP sending failed!')
      })
    }
  })
}

export function * verifyAndAddPhone () {
  yield takeEvery(actions.VERIFY_AND_ADD_PHONE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.verifyOTPAndAddPhone })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_AND_ADD_PHONE_SUCCESS,
          message: get(res.data, 'message', 'Phone Number Updated!')
        })
        yield put({
          type: authActions.GET_CONFIG_REQUEST
        })
      } else {
        yield put({
          type: actions.VERIFY_AND_ADD_PHONE_FAILURE,
          message: get(res.data, 'message', 'Update Phone Number Failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_AND_ADD_PHONE_FAILURE,
        message: get(e.data, 'message', 'Update Phone Number Failed!')
      })
    }
  })
}

export function * resendUserOTP () {
  yield takeEvery(actions.RESEND_USER_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.resendUserOTP })
      if (res.status === 200) {
        yield put({
          type: actions.RESEND_USER_OTP_SUCCESS,
          message: get(res.data, 'message', 'OTP resent successfully!')
        })
      } else {
        yield put({
          type: actions.RESEND_USER_OTP_FAILURE,
          message: get(res.data, 'message', 'OTP resent failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.RESEND_USER_OTP_FAILURE,
        message: get(e.data, 'message', 'OTP resent failed!')
      })
    }
  })
}

export function * getTOTPSharedSecret () {
  yield takeEvery(actions.GET_TOTP_SHARED_SECRET_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { ...payload, url: userUrls.totpSharedSecret })
      if (res.status === 200) {
        yield put({
          type: actions.GET_TOTP_SHARED_SECRET_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success!')
        })
      } else {
        yield put({
          type: actions.GET_TOTP_SHARED_SECRET_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOTP_SHARED_SECRET_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error!')
      })
    }
  })
}

export function * verifyTOTP () {
  yield takeEvery(actions.VERIFY_TOTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.verifyTOTP })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_TOTP_SUCCESS,
          message: get(res.data, 'message', 'Device added successfully!')
        })
        yield put({
          type: authActions.GET_CONFIG_REQUEST
        })
      } else {
        yield put({
          type: actions.VERIFY_TOTP_FAILURE,
          message: get(res.data, 'error', 'OTP verification failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_TOTP_FAILURE,
        message: get(e.data, 'error', 'OTP verification failed!')
      })
    }
  })
}

export function * update2FAMethod () {
  yield takeEvery(actions.UPDATE_2FA_METHOD_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.update2FAMethod })
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_2FA_METHOD_SUCCESS,
          message: get(res.data, 'message', '2FA method updated successfully!')
        })
        yield put({
          type: authActions.GET_CONFIG_REQUEST
        })
      } else {
        yield put({
          type: actions.UPDATE_2FA_METHOD_FAILURE,
          message: get(res.data, 'error', '2FA method updation failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_2FA_METHOD_FAILURE,
        message: get(e.data, 'error', '2FA method updation failed!')
      })
    }
  })
}

export function * verifyUserOTP () {
  yield takeEvery(actions.VERIFY_USER_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.verifyUserOTP })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_USER_OTP_SUCCESS,
          message: get(res.data, 'message', 'OTP verified!')
        })
      } else {
        yield put({
          type: actions.VERIFY_USER_OTP_FAILURE,
          message: get(res.data, 'error', 'OTP verification failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_USER_OTP_FAILURE,
        message: get(e.data, 'error', 'OTP verification failed!')
      })
    }
  })
}

export function * disableTwoFactorAuth () {
  yield takeEvery(actions.DISABLE_TWO_FACTOR_AUTH_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.disableTwoFactorAuth })
      if (res.status === 200) {
        yield put({
          type: actions.DISABLE_TWO_FACTOR_AUTH_SUCCESS,
          message: get(res.data, 'message', '2FA auth successfully disabled')
        })
      } else {
        yield put({
          type: actions.DISABLE_TWO_FACTOR_AUTH_FAILURE,
          message: get(res.data, 'error', 'Disable 2FA failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.DISABLE_TWO_FACTOR_AUTH_FAILURE,
        message: get(e.data, 'error', 'Disable 2FA failed!')
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getForgotPasswordRequest),
    fork(resetPasswordRequest),
    fork(validateResetIdRequest),
    fork(suspectActivityRequest),
    fork(sendSMSOTP),
    fork(verifyAndAddPhone),
    fork(resendUserOTP),
    fork(getTOTPSharedSecret),
    fork(verifyTOTP),
    fork(update2FAMethod),
    fork(verifyUserOTP),
    fork(disableTwoFactorAuth)
  ])
}
