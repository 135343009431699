import { Button, Form, InputNumber, notification, Row } from 'antd'
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { UserAuthStyle } from './index.style'
import authActions from '../../../../redux/auth/actions'
import loadingActions from '../../../../redux/loading/actions'
import errorActions from '../../../../redux/error/actions'

const { verifyOtp, resendOtp } = authActions
const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions

const FormItem = Form.Item

class UserAuth extends Component {
  constructor (props) {
    super(props)
    this.state = {
      time: {},
      seconds: 30,
      disableResendOtp: true
    }
    this.timer = 0
  }

  componentDidMount () {
    const { loginData } = this.props
    if (!get(loginData, 'operation_id')) {
      this.props.history.push('/')
    }
    const timeLeftVar = this.secondsToTime(this.state.seconds)
    this.setState({ time: timeLeftVar })
    this.startTimer()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { verifyLoading, verifyError, resendLoading, resendError, resendOtpData } = nextProps
    if (resendLoading === false) {
      if (resendError) {
        notification.error({ message: get(resendOtpData, 'result', 'Something went wrong. Please ttry again.') })
      } else {
        notification.success({ message: get(resendOtpData, 'result', 'Success') })
        const timeLeftVar = this.secondsToTime(this.state.seconds)
        this.timer = 0
        this.setState({ seconds: 30, disableResendOtp: true, time: timeLeftVar }, () => this.startTimer())
      }
      this.props.resetLoadingRedux(['RESEND_OTP'])
      this.props.resetErrorRedux(['RESEND_OTP'])
    }

    if (verifyLoading === false) {
      if (verifyError) {
        notification.error({ message: 'OTP couldn\'t be verified. Please try again or click on Resend OTP' })
      } else {
        notification.success({ message: 'Logged in successfully!' })
        this.props.history.push('/')
      }
      this.props.resetLoadingRedux(['VERIFY_OTP'])
      this.props.resetErrorRedux(['VERIFY_OTP'])
    }
  }

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    })

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer)
      this.setState({ disableResendOtp: false })
    }
  }

  secondsToTime = (secs) => {
    const hours = Math.floor(secs / (60 * 60))

    const divisorForMinutes = secs % (60 * 60)
    const minutes = Math.floor(divisorForMinutes / 60)

    const divisorForSeconds = divisorForMinutes % 60
    const seconds = Math.ceil(divisorForSeconds)

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    }
    return obj
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { loginData } = this.props
        this.props.verifyOtp({
          body: {
            operation_id: get(loginData, 'operation_id'),
            otp: get(values, 'authentication_code')
          }
        })
      }
    })
  }

  handleResendOtp = () => {
    const { loginData = {} } = this.props
    const { otp_type: otpType } = loginData

    this.props.resendOtp({
      body: {
        operation_id: get(loginData, 'operation_id'),
        medium: otpType === 'EMAIL' ? 'email' : undefined
      }
    })
  }

  helpText = () => {
    const { loginData = {} } = this.props
    const { otp_type: otpType } = loginData

    switch (otpType) {
      case 'EMAIL':
        return <div>Please enter the <b>Authentication Code</b> received on your <b>Email</b> to verify your identity.</div>
      case 'SMS':
        return <div>Please enter the <b>Authentication Code</b> received on your <b>Phone</b> to verify your identity.</div>
      case 'TOTP':
        return <div>Please enter the <b>Authentication Code</b> from the <b>Two-Factor App</b> on your mobile deviice.</div>
      default:
        return ''
    }
  }

  render () {
    const { form, resendLoading, verifyLoading, loginData = {} } = this.props
    const { otp_type: otpType, user_info: userInfo } = loginData
    const { getFieldDecorator } = form
    const { time, disableResendOtp } = this.state
    const isOTPTypeTOTP = otpType === 'TOTP'

    return (
      <UserAuthStyle>
        <div className='inner-container'>
          <div className='right-container'>
            <div className='header'>
              PARTNERS PANEL
            </div>
            <div className='sub-header'>
              Two-Factor Authentication
            </div>
            <div className='instructions-container'>
              {this.helpText()}
              <div className='sub-text'>
                {
                  !isOTPTypeTOTP && `OTP will be received on ${userInfo}`
                }
              </div>
            </div>
            <Form onSubmit={this.handleSubmit}>
              <FormItem>
                {getFieldDecorator('authentication_code', {
                  rules: [
                    { required: true, message: 'Authentication Code can not be empty' }
                  ]
                })(
                  <InputNumber placeholder='Authentication Code' />
                )}
              </FormItem>
              <Row type='flex' justify={isOTPTypeTOTP ? 'end' : 'space-between'} align='middle'>
                {
                  !isOTPTypeTOTP &&
                    <Button
                      onClick={this.handleResendOtp}
                      loading={!!resendLoading}
                      disabled={disableResendOtp}
                    >
                      Resend OTP
                    </Button>
                }
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={!!resendLoading || !!verifyLoading}
                >
                  Verify Code
                </Button>
              </Row>
              {
                !isOTPTypeTOTP && time.s > 0 &&
                  <Row className='resend-time'>
                    Resend after <span className='second'>{time.s}</span> seconds
                  </Row>
              }
            </Form>
          </div>
        </div>
      </UserAuthStyle>
    )
  }
}

const WrappedUserAuth = Form.create()(UserAuth)

export default connect(state => ({
  loginData: state.Auth.loginData,

  verifyLoading: state.Loading.VERIFY_OTP,
  verifyError: state.Error.VERIFY_OTP,

  resendLoading: state.Loading.RESEND_OTP,
  resendError: state.Error.RESEND_OTP,
  resendOtpData: state.Auth.resendOtpData
}), {
  verifyOtp,
  resendOtp,
  resetLoadingRedux,
  resetErrorRedux
})(WrappedUserAuth)
