import actions from './actions';

const initialState = {
  customerSearchString: "",
  searchCustomerResult: [],
  customerClaimDetails: [],
  customerPolicyDetails: [],
  customerDetails: []
}

export default function CustomerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SAVE_SEARCH_STRING:
      return {
        ...state,
        customerSearchString: action.payload || initialState.customerSearchString
      }
    case actions.GET_CUSTOMER_SEARCH_RESULT_SUCCESS:
    case actions.GET_CUSTOMER_SEARCH_RESULT_FAILURE:
      return {
        ...state,
        searchCustomerResult: action.data || initialState.searchCustomerResult,
        searchCustomerMessage: action.message
      }
    case actions.GET_CUSTOMER_CLAIM_DETAILS_SUCCESS:
    case actions.GET_CUSTOMER_CLAIM_DETAILS_FAILURE:
      return {
        ...state,
        customerClaimDetails: action.data || initialState.customerClaimDetails
      }
    case actions.GET_CUSTOMER_POLICY_DETAILS_SUCCESS:
    case actions.GET_CUSTOMER_POLICY_DETAILS_FAILURE:
      return {
        ...state,
        customerPolicyDetails: action.data || initialState.customerPolicyDetails
      }
    case actions.GET_CUSTOMER_DETAILS_SUCCESS:
    case actions.GET_CUSTOMER_DETAILS_FAILURE:
      return {
        ...state,
        customerDetails: action.data || initialState.customerDetails
      }
    default:
      return state;
  }
}
