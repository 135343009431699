import { all, takeEvery, call, put, fork } from 'redux-saga/effects';
import actions from './actions'
import callApi from '../../../../helpers/callApi';
import middleware from '../../../../middleware/responseChecker'

export function* getSearchResult() {
  yield takeEvery(actions.GET_CUSTOMER_SEARCH_RESULT_REQUEST, function* ({ payload }) {
    let res = yield call(callApi.getSearchCustomer, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CUSTOMER_SEARCH_RESULT_SUCCESS,
        data: res.data,
        message: 'success'
      })
    }
    else {
      middleware(res)
      yield put({
        type: actions.GET_CUSTOMER_SEARCH_RESULT_FAILURE,
        data: undefined,
        message: 'error'
      })
    }
  })
}

export function* getCustomerDetails() {
  yield takeEvery(actions.GET_CUSTOMER_DETAILS_REQUEST, function* ({ payload }) {
    let res = yield call(callApi.getCustomerDetails, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CUSTOMER_DETAILS_SUCCESS,
        data: res.data,
        message: 'success'
      })
    }
    else {
      middleware(res)
      yield put({
        type: actions.GET_CUSTOMER_DETAILS_FAILURE,
        data: undefined,
        message: 'error'
      })
    }
  })
}

export function* getCustomerPolicies() {
  yield takeEvery(actions.GET_CUSTOMER_POLICY_DETAILS_REQUEST, function* ({ payload }) {
    let res = yield call(callApi.getCustomerPolicies, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CUSTOMER_POLICY_DETAILS_SUCCESS,
        data: res.data,
        message: 'success'
      })
    }
    else {
      middleware(res)
      yield put({
        type: actions.GET_CUSTOMER_POLICY_DETAILS_FAILURE,
        data: undefined,
        message: 'error'
      })
    }
  })
}

export function* getCustomerClaims() {
  yield takeEvery(actions.GET_CUSTOMER_CLAIM_DETAILS_REQUEST, function* ({ payload }) {
    let res = yield call(callApi.getCustomerClaims, payload)
    if (res.status === 200) {
      yield put({
        type: actions.GET_CUSTOMER_CLAIM_DETAILS_SUCCESS,
        data: res.data,
        message: 'success'
      })
    }
    else {
      middleware(res)
      yield put({
        type: actions.GET_CUSTOMER_CLAIM_DETAILS_FAILURE,
        data: undefined,
        message: 'error'
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getSearchResult),
    fork(getCustomerDetails),
    fork(getCustomerPolicies),
    fork(getCustomerClaims)
  ])
}