const actions = {
    GET_ACTIVE_CLAIMS: 'GET_ACTIVE_CLAIMS',
    ACTIVE_CLAIMS_DATA: 'ACTIVE_CLAIMS_DATA',
    GET_ALL_CLAIMS: 'GET_ALL_CLAIMS',
    ALL_CLAIMS_DATA: 'ALL_CLAIMS_DATA',
    CREATE_SHIPPING_CLAIM: 'CREATE_SHIPPING_CLAIM',
    CREATE_SHIPPING_CLAIM_STATUS: 'CREATE_SHIPPING_CLAIM_STATUS',
    UPLOAD_SHIPPING_CLAIM_PHOTOS: 'UPLOAD_SHIPPING_CLAIM_PHOTOS',
    CLEAR_UPLOAD_PHOTO_STATUS: 'CLEAR_UPLOAD_PHOTO_STATUS',
    UPLOAD_SHIPPING_CLAIM_PHOTOS_STATUS: 'UPLOAD_SHIPPING_CLAIM_PHOTOS_STATUS',
    SUBMIT_CLAIM: 'SUBMIT_CLAIM',
    SUBMIT_CLAIM_STATUS: 'SUBMIT_CLAIM_STATUS',
    RESET_REDUX_STATE_SHIPPING: 'RESET_REDUX_STATE_SHIPPING',
    UPLOAD_BULK_IMAGE: 'UPLOAD_BULK_IMAGE',
    UPLOAD_BULK_IMAGE_STATUS: 'UPLOAD_BULK_IMAGE_STATUS',
    ACTIVE_CLAIM_DETAIL: 'ACTIVE_CLAIM_DETAIL',
    ACTIVE_CLAIM_DETAIL_STATUS: 'ACTIVE_CLAIM_DETAIL_STATUS',
    API_INTEGRATE: 'API_INTEGRATE',
    API_INTEGRATE_STATUS: 'API_INTEGRATE_STATUS',
    TRACKING_INFORMATION: 'TRACKING_INFORMATION',
    TRACKING_INFORMATION_STATUS: 'TRACKING_INFORMATION_STATUS',
    getActiveClaims: payload => ({
      type: actions.GET_ACTIVE_CLAIMS,
      payload
    }),
    activeClaimsData: payload => ({
      type: actions.ACTIVE_CLAIMS_DATA,
      payload
    }),
    getAllClaims: payload => ({
      type: actions.GET_ALL_CLAIMS,
      payload
    }),
    allClaims: payload => ({
      type: actions.ALL_CLAIMS_DATA,
      payload
    }),
    createClaim: payload => ({
      type: actions.CREATE_SHIPPING_CLAIM,
      payload
    }),
    createClaimStatus: payload => ({
      type: actions.CREATE_SHIPPING_CLAIM_STATUS,
      payload
    }),
    uploadClaimPhotos: payload => ({
      type: actions.UPLOAD_SHIPPING_CLAIM_PHOTOS,
      payload
    }),
    uploadClaimPhotosStatus: payload => ({
      type: actions.UPLOAD_SHIPPING_CLAIM_PHOTOS_STATUS,
      payload
    }),
    clearUploadPhotoStatus: () => ({
      type: actions.CLEAR_UPLOAD_PHOTO_STATUS
    }),
    submitClaim: payload => ({
      type: actions.SUBMIT_CLAIM,
      payload
    }),
    submitClaimStatus: payload => ({
      type: actions.SUBMIT_CLAIM_STATUS,
      payload
    }),
    resetReduxShipping: () => ({
      type: actions.RESET_REDUX_STATE_SHIPPING
    }),
    uploadImage: payload => ({
      type: actions.UPLOAD_BULK_IMAGE,
      payload
    }),
    bulkUploadStatus: payload => ({
      type: actions.UPLOAD_BULK_IMAGE_STATUS,
      payload
    }),
    activeClaimDetail: payload => ({
      type: actions.ACTIVE_CLAIM_DETAIL,
      payload
    }),
    activeClaimDetailStatus: payload => ({
      type: actions.ACTIVE_CLAIM_DETAIL_STATUS,
      payload
    }),
    apiIntegrate: payload => ({
      type: actions.API_INTEGRATE,
      payload
    }),
    apiIntegrateStatus: payload => ({
      type: actions.API_INTEGRATE_STATUS,
      payload
    }),
    trackingInformation: payload => ({
      type: actions.TRACKING_INFORMATION,
      payload
    }),
    trackingInformationStatus: payload => ({
      type: actions.TRACKING_INFORMATION_STATUS,
      payload
    })
  }
  export default actions
  