import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { get } from 'lodash'
import actions from './actions'
import authGet from '../../../../request/methods/authGet'
import authPost from '../../../../request/methods/authPost'
import reportingUrls from '../../../../config/urls/reporting'

export function * getReportingList () {
  yield takeEvery(actions.GET_REPORTING_LIST_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: reportingUrls.getReportList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_REPORTING_LIST_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_REPORTING_LIST_FAILURE,
          data: [],
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_REPORTING_LIST_FAILURE,
        data: [],
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function * reportStatusChange () {
  yield takeEvery(actions.REPORT_STATUS_CHANGE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: reportingUrls.reportStatusChange })
      if (res.status === 200) {
        yield put({
          type: actions.REPORT_STATUS_CHANGE_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.REPORT_STATUS_CHANGE_FAILURE,
          data: [],
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.REPORT_STATUS_CHANGE_FAILURE,
        data: [],
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function * createReport () {
  yield takeEvery(actions.CREATE_REPORT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: reportingUrls.createReport })
      if (res.status === 201) {
        yield put({
          type: actions.CREATE_REPORT_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.CREATE_REPORT_FAILURE,
          data: [],
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.CREATE_REPORT_FAILURE,
        data: [],
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function * updateReport () {
  yield takeEvery(actions.UPDATE_REPORT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: reportingUrls.updateReport })
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_REPORT_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.UPDATE_REPORT_FAILURE,
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_REPORT_FAILURE,
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function * sendReport () {
  yield takeEvery(actions.SEND_REPORT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: reportingUrls.sendReport })
      if (res.status === 200) {
        yield put({
          type: actions.SEND_REPORT_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.SEND_REPORT_FAILURE,
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.SEND_REPORT_FAILURE,
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function * getReportRequest () {
  yield takeEvery(actions.GET_REQUESTS_REPORT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: reportingUrls.getReportRequest })
      if (res.status === 200) {
        yield put({
          type: actions.GET_REQUESTS_REPORT_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_REQUESTS_REPORT_FAILURE,
          data: [],
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_REQUESTS_REPORT_FAILURE,
        data: [],
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function * getSignedUrl () {
  yield takeEvery(actions.GET_SIGNED_URL_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: reportingUrls.getSignedUrl })
      if (res.status === 200) {
        yield put({
          type: actions.GET_SIGNED_URL_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_SIGNED_URL_FAILURE,
          data: {}
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_SIGNED_URL_FAILURE,
        data: {}
      })
    }
  })
}

export function * getReportView () {
  yield takeEvery(actions.GET_REPORT_VIEW_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: reportingUrls.getReportView })
      if (res.status === 200) {
        yield put({
          type: actions.GET_REPORT_VIEW_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_REPORT_VIEW_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_REPORT_VIEW_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getReportingList),
    fork(reportStatusChange),
    fork(createReport),
    fork(updateReport),
    fork(sendReport),
    fork(getReportRequest),
    fork(getSignedUrl),
    fork(getReportView)
  ])
}
