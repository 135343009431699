const actions = {
  RESET_NOTIFICATIONS_REDUX_STATE: 'RESET_NOTIFICATIONS_REDUX_STATE',
  GET_NOTIFICATIONS_LIST_REQUEST: 'GET_NOTIFICATIONS_LIST_REQUEST',
  GET_NOTIFICATIONS_LIST_SUCCESS: 'GET_NOTIFICATIONS_LIST_SUCCESS',
  GET_NOTIFICATIONS_LIST_FAILURE: 'GET_NOTIFICATIONS_LIST_FAILURE',
  resetNotificationCore: () => ({
    type: actions.RESET_NOTIFICATION_CORE
  }),
  getNotificationList: payload => ({
    type: actions.GET_NOTIFICATIONS_LIST_REQUEST,
    payload
  }),
  failedGetNotificationList: payload => ({
    type: actions.FAILED_GET_NOTIFICATIONS_LIST,
    payload
  }),
  successGetNotificationList: payload => ({
    type: actions.SUCCESS_GET_NOTIFICATIONS_LIST,
    payload
  })
}
export default actions
