const searchBuilder = finalFilter => {
  // let query = []
  // if (Object.keys(finalFilter).length <= 1) {
  //   for (let key in finalFilter) {
  //     query += `?${key}=${finalFilter[key]}`
  //   }
  // }
  // if (Object.keys(finalFilter).length > 1) {
  //   query = '?'
  //   for (let key in finalFilter) {
  //     query += `${key}=${finalFilter[key]}&`
  //   }
  //   query = query.slice(0, -1)
  // }
  // return query
  let queryArray = []
  Object.keys(finalFilter).forEach((key, index) => {
    const keyValue = finalFilter[key]
    let query = ''
    let identifier = queryArray.length ? '&' : '?'
    if (Array.isArray(keyValue)) {
      keyValue.forEach((value, index) => {
        query += `${query ? '&' : ''}${key}=${value}`
      })
    } else if (keyValue) {
      query += `${key}=${keyValue}`
    }
    if (query) {
      queryArray.push(`${identifier}${query}`)
    }
  })
  return queryArray.join('')
}

export default searchBuilder
