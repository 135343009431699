const actions = {
    RESET_REDUX_STATE:'RESET_REDUX_STATE',
    
    GET_USERMANAGEMENT_LIST:'GET_USERMANAGEMENT_LIST',
    GET_USERMANAGEMENT_LIST_REQUEST:'GET_USERMANAGEMENT_LIST_REQUEST',
    GET_USERMANAGEMENT_LIST_SUCCESS:'GET_USERMANAGEMENT_LIST_SUCCESS',
    GET_USERMANAGEMENT_LIST_FAILURE:'GET_USERMANAGEMENT_LIST_FAILURE',

    GET_USER_GROUP_LIST:'GET_USER_GROUP_LIST',
    GET_USER_GROUP_LIST_REQUEST:'GET_USER_GROUP_LIST_REQUEST',
    GET_USER_GROUP_LIST_SUCCESS:'GET_USER_GROUP_LIST_SUCCESS',
    GET_USER_GROUP_LIST_FAILURE:'GET_USER_GROUP_LIST_FAILURE',

    GET_TIMEZONE_DATA:'GET_TIMEZONE_DATA',
    GET_TIMEZONE_DATA_REQUEST:'GET_TIMEZONE_DATA_REQUEST',
    GET_TIMEZONE_DATA_SUCCESS:'GET_TIMEZONE_DATA_SUCCESS',
    GET_TIMEZONE_DATA_FAILURE:'GET_TIMEZONE_DATA_FAILURE',

    POST_USER_GROUP:' POST_USER_GROUP',
    POST_USER_GROUP_SUCCESS: 'POST_NEW_USER_SUCCESS',
    POST_USER_GROUP_FAILED: 'POST_USER_GROUP_FAILED',

    EDIT_USER_GROUP:'EDIT_USER_GROUP',
    EDIT_USER_GROUP_REQUEST: 'EDIT_USER_GROUP_REQUEST',
    EDIT_USER_GROUP_SUCCESS: 'EDIT_USER_GROUP_SUCCESS',
    EDIT_USER_GROUP_FAILED: 'EDIT_USER_GROUP_FAILED',

    DELETE_USER_GROUP:'DELETE_USER_GROUP',
    DELETE_USER_GROUP_REQUEST:'DELETE_USER_GROUP_REQUEST',
    DELETE_USER_GROUP_SUCCESS:'DELETE_USER_GROUP_SUCCESS',
    DELETE_USER_GROUP_FAILED:'DELETE_USER_GROUP_FAILED',

    DEACTIVATE_USER:'DEACTIVATE_USER',
    DEACTIVATE_USER_REQUEST:'DEACTIVATE_USER_REQUEST',
    DEACTIVATE_USER_SUCCESS:'DEACTIVATE_USER_SUCCESS',
    DEACTIVATE_USER_FAILED:'DEACTIVATE_USER_FAILED',

    ACTIVATE_USER:'ACTIVATE_USER',
    ACTIVATE_USER_REQUEST:'ACTIVATE_USER_REQUEST',
    ACTIVATE_USER_SUCCESS:'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_FAILED:'ACTIVATE_USER_FAILED',

    resetState: () => ({
        type: actions.RESET_REDUX_STATE
     }),

    getUserManagementList :(payload) =>({
        type: actions.GET_USERMANAGEMENT_LIST_REQUEST,
        payload
    }),
    updatedUserManagementList :(payload) =>({
        type:actions.GET_USERMANAGEMENT_LIST,
        payload
    }),

    getUserGroupList:(payload) =>({
        type:actions.GET_USER_GROUP_LIST_REQUEST,
        payload
    }),

    updatedUserGroupList:(payload) =>({
        type:actions.GET_USER_GROUP_LIST,
        payload
    }),

    getTimezoneList:(payload) =>({
        type:actions.GET_TIMEZONE_DATA_REQUEST,
        payload
    }),

    updatedTimezoneList:(payload) =>({
        type:actions.GET_TIMEZONE_DATA,
        payload
    }),

    addNewUser:(payload) =>({
        type:actions.POST_USER_GROUP,
        payload
    }),

    successAddUserGroup:(payload) =>({
        type:actions.POST_USER_GROUP_SUCCESS,
        payload
    }),

    failedAddUserGroup:(payload) =>({
        type:actions.POST_USER_GROUP_FAILED,
        payload
    }),

    editUser:(payload) =>({
        type:actions.EDIT_USER_GROUP_REQUEST,
        payload
    }),

    updatededitUser:(payload) =>({
        type:actions.EDIT_USER_GROUP,
        payload
    }),

    deleteUser:(payload) =>({
        type:actions.DELETE_USER_GROUP_REQUEST,
        payload
    }),

    updateddeleteUser:(payload) =>({
        type:actions.DELETE_USER_GROUP,
        payload
    }),

    deactivatePartnerUser:(payload) =>({
        type:actions.DEACTIVATE_USER_REQUEST,
        payload
    }),

    updatedDeactivatedPartnerUser:(payload) =>({
        type:actions.DEACTIVATE_USER,
        payload
    }),

    activatePartnerUser:(payload) =>({
        type:actions.ACTIVATE_USER_REQUEST,
        payload
    }),

    updatedActivatedPartnerUser:(payload) =>({
        type:actions.ACTIVATE_USER,
        payload
    })    
}
export default actions;