const actions = {
  GET_CLAIM_LISTS_REQUEST: 'GET_CLAIM_LISTS_REQUEST',
  GET_CLAIM_LISTS_SUCCESS: 'GET_CLAIM_LISTS_SUCCESS',
  GET_CLAIM_LISTS_FAILURE: 'GET_CLAIM_LISTS_FAILURE',

  CLAIM_LISTS: 'CLAIM_LISTS',
  GET_CLAIM_FILTERS_REQUEST: 'GET_CLAIM_FILTERS_REQUEST',
  GET_CLAIM_FILTERS_SUCCESS: 'GET_CLAIM_FILTERS_SUCCESS',
  GET_CLAIM_FILTERS_FAILURE: 'GET_CLAIM_FILTERS_FAILURE',

  RESET_REDUX_STATE_CLAIM: 'RESET_REDUX_STATE_CLAIM',
  GET_DETAILS_CLAIM: 'GET_DETAILS_CLAIM_REQUEST',
  DETAILS_CLAIM: 'GET_DETAILS_CLAIM_SUCCESS',
  FAILED_GET_DETAILS_CLAIM: 'GET_DETAILS_CLAIM_FAILURE',
  SUBMIT_EVENTS_CLAIM: 'SUBMIT_EVENTS_CLAIM',
  SUCCESS_SUBMIT_EVENT: 'SUCCESS_SUBMIT_EVENT',
  FAILED_SUBMIT_EVENT: 'FAILED_SUBMIT_EVENT',
  SAVE_ATTRIBUTES: 'SAVE_ATTRIBUTES',
  SUCCESS_SAVE_ATTRIBUTES: 'SUCCESS_SAVE_ATTRIBUTES',
  FAILED_SAVE_ATTRIBUTES: 'FAILED_SAVE_ATTRIBUTES',
  GET_BENEFITS: 'GET_BENEFITS',
  BENEFITS_DATA: 'BENEFITS_DATA',
  FAILED_GET_BENEFITS_DATA: 'FAILED_GET_BENEFITS_DATA',
  CREATE_CLAIM: 'CREATE_CLAIM',
  SUCCESS_CREATE_CLAIM: 'SUCCESS_CREATE_CLAIM',
  FAILED_CREATE_CLAIM: 'FAILED_CREATE_CLAIM',
  UPLOAD_CLAIM_DOC: 'UPLOAD_CLAIM_DOC',
  SUCCESS_SAVE_ATTRIBUTES_DOC: 'SUCCESS_SAVE_ATTRIBUTES_DOC',
  FAILED_SAVE_ATTRIBUTES_DOC: 'FAILED_SAVE_ATTRIBUTES_DOC',
  SAVE_GENERIC_ATTRIBUTES_DOC: 'SAVE_GENERIC_ATTRIBUTES_DOC',
  SAVE_GENERIC_ATTRIBUTES_REQUEST: 'SAVE_GENERIC_ATTRIBUTES_REQUEST',
  SAVE_GENERIC_ATTRIBUTES_SUCCESS: 'SAVE_GENERIC_ATTRIBUTES_SUCCESS',
  SAVE_GENERIC_ATTRIBUTES_FAILURE: 'SAVE_GENERIC_ATTRIBUTES_FAILURE',
  SAVE_GENERIC_ATTRIBUTES_STATUS: 'SAVE_GENERIC_ATTRIBUTES_STATUS',
  SAVE_DRAFT_ATTRIBUTES_STATUS: 'SAVE_DRAFT_ATTRIBUTES_STATUS',
  SAVE_DRAFT_ATTRIBUTES_REQUEST: 'SAVE_DRAFT_ATTRIBUTES_REQUEST',
  SAVE_DRAFT_ATTRIBUTES_SUCCESS: 'SAVE_DRAFT_ATTRIBUTES_SUCCESS',
  SAVE_DRAFT_ATTRIBUTES_FAILURE: 'SAVE_DRAFT_ATTRIBUTES_FAILURE',
  UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES: 'UPDATE_UPLOAD_GENERIC_CLAIM_DOC_LOADING_STATES',
  SEND_REPORT_CLAIMS: 'SEND_REPORT_CLAIMS',
  SUCCESS_SEND_REPORT_CLAIMS: 'SUCCESS_SEND_REPORT_CLAIMS',
  FAILED_SEND_REPORT_CLAIMS: 'FAILED_SEND_REPORT_CLAIMS',
  UPLOAD_CREATE_CLAIM_DOC_REQUEST: 'UPLOAD_CREATE_CLAIM_DOC_REQUEST',
  UPLOAD_CREATE_CLAIM_DOC_SUCCESS: 'UPLOAD_CREATE_CLAIM_DOC_SUCCESS',
  UPLOAD_CREATE_CLAIM_DOC_FAILURE: 'UPLOAD_CREATE_CLAIM_DOC_FAILURE',
  UPDATE_UPLOAD_CREATE_CLAIM_DOC_LOADING_STATES: 'UPDATE_UPLOAD_CREATE_CLAIM_DOC_LOADING_STATES',
  UPDATE_UPLOAD_CREATE_CLAIM_DOC_VALUES: 'UPDATE_UPLOAD_CREATE_CLAIM_DOC_VALUES',
  CREATE_CLAIM_WITH_DOC_REQUEST: 'CREATE_CLAIM_WITH_DOC_REQUEST',
  CREATE_CLAIM_WITH_DOC_SUCCESS: 'CREATE_CLAIM_WITH_DOC_SUCCESS',
  CREATE_CLAIM_WITH_DOC_FAILURE: 'CREATE_CLAIM_WITH_DOC_FAILURE',
  GET_BENEFITS_REQUEST: 'GET_BENEFITS_REQUEST',
  GET_BENEFITS_SUCCESS: 'GET_BENEFITS_SUCCESS',
  GET_BENEFITS_FAILURE: 'GET_BENEFITS_FAILURE',
  VALIDATE_CLAIM_REQUEST: 'VALIDATE_CLAIM_REQUEST',
  VALIDATE_CLAIM_SUCCESS: 'VALIDATE_CLAIM_SUCCESS',
  VALIDATE_CLAIM_FAILURE: 'VALIDATE_CLAIM_FAILURE',

  UPLOAD_GENERIC_CLAIM_DOC_REQUEST: 'UPLOAD_GENERIC_CLAIM_DOC_REQUEST',
  UPLOAD_GENERIC_CLAIM_DOC_SUCCESS: 'UPLOAD_GENERIC_CLAIM_DOC_SUCCESS',
  UPLOAD_GENERIC_CLAIM_DOC_FAILURE: 'UPLOAD_GENERIC_CLAIM_DOC_FAILURE',

  VERIFY_BANK_ACCOUNT: 'VERIFY_BANK_ACCOUNT',
  VERIFY_BANK_ACCOUNT_FORMS_REQUEST: 'VERIFY_BANK_ACCOUNT_FORMS_REQUEST',
  VERIFY_BANK_ACCOUNT_FORMS_SUCCESS: 'VERIFY_BANK_ACCOUNT_FORMS_SUCCESS',
  VERIFY_BANK_ACCOUNT_FORMS_FAILURE: 'VERIFY_BANK_ACCOUNT_FORMS_FAILURE',
  VERIFY_BANK_ACCOUNT_STATUS: 'VERIFY_BANK_ACCOUNT_STATUS',
  POST_SALVAGE_FORMS_STATUS: 'POST_SALVAGE_FORMS_STATUS',
  POST_SALVAGE_FORMS_REQUEST: 'POST_SALVAGE_FORMS_REQUEST',
  POST_SALVAGE_FORMS_SUCCESS: 'POST_SALVAGE_FORMS_SUCCESS',
  POST_SALVAGE_FORMS_FAILURE: 'POST_SALVAGE_FORMS_FAILURE',
  POST_SALVAGE_FORMS: 'POST_SALVAGE_FORMS',

  GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST: 'GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST',
  GENERATE_SIGNED_URL_AND_UPLOAD_SUCCESS: 'GENERATE_SIGNED_URL_AND_UPLOAD_SUCCESS',
  GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE: 'GENERATE_SIGNED_URL_AND_UPLOAD_FAILURE',

  UPLOAD_FILE_BY_SIGNED_URL_REQUEST: 'UPLOAD_FILE_BY_SIGNED_URL_REQUEST',
  UPLOAD_FILE_BY_SIGNED_URL_SUCCESS: 'UPLOAD_FILE_BY_SIGNED_URL_SUCCESS',
  UPLOAD_FILE_BY_SIGNED_URL_FAILURE: 'UPLOAD_FILE_BY_SIGNED_URL_FAILURE',
  GET_BANK_LIST_REQUEST: 'GET_BANK_LIST_REQUEST',
  GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
  GET_BANK_LIST_FAILURE: 'GET_BANK_LIST_FAILURE',

  GET_SERVICE_CENTER_LIST_ON_CLAIM_REQUEST: 'GET_SERVICE_CENTER_LIST_ON_CLAIM_REQUEST',
  GET_SERVICE_CENTER_LIST_ON_CLAIM_FAILURE: 'GET_SERVICE_CENTER_LIST_ON_CLAIM_FAILURE',
  GET_SERVICE_CENTER_LIST_ON_CLAIM_SUCCESS: 'GET_SERVICE_CENTER_LIST_ON_CLAIM_SUCCESS',

  GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_REQUEST: 'GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_REQUEST',
  GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_FAILURE: 'GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_FAILURE',
  GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_SUCCESS: 'GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_SUCCESS',

  GET_PROVINCE_LIST_FOR_SERVICE_CENTER_REQUEST: 'GET_PROVINCE_LIST_FOR_SERVICE_CENTER_REQUEST',
  GET_PROVINCE_LIST_FOR_SERVICE_CENTER_FAILURE: 'GET_PROVINCE_LIST_FOR_SERVICE_CENTER_FAILURE',
  GET_PROVINCE_LIST_FOR_SERVICE_CENTER_SUCCESS: 'GET_PROVINCE_LIST_FOR_SERVICE_CENTER_SUCCESS',

  GET_CITY_LIST_FOR_SERVICE_CENTER_REQUEST: 'GET_CITY_LIST_FOR_SERVICE_CENTER_REQUEST',
  GET_CITY_LIST_FOR_SERVICE_CENTER_FAILURE: 'GET_CITY_LIST_FOR_SERVICE_CENTER_FAILURE',
  GET_CITY_LIST_FOR_SERVICE_CENTER_SUCCESS: 'GET_CITY_LIST_FOR_SERVICE_CENTER_SUCCESS',

  GET_POSTAL_CODE_FOR_SERVICE_CENTER_REQUEST: 'GET_POSTAL_CODE_FOR_SERVICE_CENTER_REQUEST',
  GET_POSTAL_CODE_FOR_SERVICE_CENTER_FAILURE: 'GET_POSTAL_CODE_FOR_SERVICE_CENTER_FAILURE',
  GET_POSTAL_CODE_FOR_SERVICE_CENTER_SUCCESS: 'GET_POSTAL_CODE_FOR_SERVICE_CENTER_SUCCESS',

  GET_TOTAL_SERVICE_CENTER_LIST_REQUEST: 'GET_TOTAL_SERVICE_CENTER_LIST_REQUEST',
  GET_TOTAL_SERVICE_CENTER_LIST_FAILURE: 'GET_TOTAL_SERVICE_CENTER_LIST_FAILURE',
  GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS: 'GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS',

  getClaimLists: payload => ({
    type: actions.GET_CLAIM_LISTS_REQUEST,
    payload
  }),
  getClaimFilters: payload => ({
    type: actions.GET_CLAIM_FILTERS_REQUEST,
    payload
  }),
  claimLists: payload => ({
    type: actions.CLAIM_LISTS,
    payload
  }),
  resetReduxState: payload => ({
    type: actions.RESET_REDUX_STATE_CLAIM,
    payload
  }),
  getDetailsClaim: payload => ({
    type: actions.GET_DETAILS_CLAIM,
    payload
  }),
  detailsClaim: payload => ({
    type: actions.DETAILS_CLAIM,
    payload
  }),
  failedGetDetailsClaim: payload => ({
    type: actions.FAILED_GET_DETAILS_CLAIM,
    payload
  }),
  submitEvent: payload => ({
    type: actions.SUBMIT_EVENTS_CLAIM,
    payload
  }),
  successSubmitEvent: payload => ({
    type: actions.SUCCESS_SUBMIT_EVENT,
    payload
  }),
  failedSubmitEvent: payload => ({
    type: actions.FAILED_SUBMIT_EVENT,
    payload
  }),
  saveAttributes: payload => ({
    type: actions.SAVE_ATTRIBUTES,
    payload
  }),
  successSaveAttribute: payload => ({
    type: actions.SUCCESS_SAVE_ATTRIBUTES,
    payload
  }),
  failedSaveAttribute: payload => ({
    type: actions.FAILED_SAVE_ATTRIBUTES,
    payload
  }),
  getBenefits: payload => ({
    type: actions.GET_BENEFITS,
    payload
  }),
  benefitsData: payload => ({
    type: actions.BENEFITS_DATA,
    payload
  }),
  failedgetBenefitsData: payload => ({
    type: actions.FAILED_GET_BENEFITS_DATA,
    payload
  }),
  createClaim: payload => ({
    type: actions.CREATE_CLAIM,
    payload
  }),
  successCreateClaim: payload => ({
    type: actions.SUCCESS_CREATE_CLAIM,
    payload
  }),
  failedCreateClaim: payload => ({
    type: actions.FAILED_CREATE_CLAIM,
    payload
  }),
  uploadClaimDoc: payload => ({
    type: actions.UPLOAD_CLAIM_DOC,
    payload
  }),
  successUploadClaimDoc: payload => ({
    type: actions.SUCCESS_SAVE_ATTRIBUTES_DOC,
    payload
  }),
  failedUploadClaimDoc: payload => ({
    type: actions.FAILED_SAVE_ATTRIBUTES_DOC,
    payload
  }),
  sendReportClaims: payload => ({
    type: actions.SEND_REPORT_CLAIMS,
    payload
  }),
  succesSendClaimsReport: payload => ({
    type: actions.SUCCESS_SEND_REPORT_CLAIMS,
    payload
  }),
  failedSendClaimsReport: payload => ({
    type: actions.FAILED_SEND_REPORT_CLAIMS,
    payload
  }),
  uploadCreateClaimDoc: payload => ({
    type: actions.UPLOAD_CREATE_CLAIM_DOC_REQUEST,
    payload
  }),
  createClaimWithDocument: payload => ({
    type: actions.CREATE_CLAIM_WITH_DOC_REQUEST,
    payload
  }),
  getBenefitsList: payload => ({
    type: actions.GET_BENEFITS_REQUEST,
    payload
  }),
  validateClaim: payload => ({
    type: actions.VALIDATE_CLAIM_REQUEST,
    payload
  }),
  uploadGenericClaimDoc: payload => ({
    type: actions.UPLOAD_GENERIC_CLAIM_DOC_REQUEST,
    payload
  }),
  saveGenericAttributes: payload => ({
    type: actions.SAVE_GENERIC_ATTRIBUTES_REQUEST,
    payload
  }),
  saveDraftAttributes: payload => ({
    type: actions.SAVE_DRAFT_ATTRIBUTES_REQUEST,
    payload
  }),
  verifyBankAccountForms: payload => ({
    type: actions.VERIFY_BANK_ACCOUNT_FORMS_REQUEST,
    payload
  }),
  postSalvageForms: payload => ({
    type: actions.POST_SALVAGE_FORMS_REQUEST,
    payload
  }),
  getBankList: payload => ({
    type: actions.GET_BANK_LIST_REQUEST,
    payload
  }),
  generateSignedUrlAndUplaod: payload => ({
    type: actions.GENERATE_SIGNED_URL_AND_UPLOAD_REQUEST,
    payload
  }),
  uploadFileBySignedUrl: payload => ({
    type: actions.UPLOAD_FILE_BY_SIGNED_URL_REQUEST,
    payload
  }),
  getServiceCenterOnClaim: (payload) => ({
    type: actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_REQUEST,
    payload
  }),
  getServiceCenterBranchOnClaim: (payload) => ({
    type: actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_REQUEST,
    payload
  }),
  getProvinceListForServiceCenter: (payload) => ({
    type: actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_REQUEST,
    payload
  }),
  getCityListForServiceCenter: (payload) => ({
    type: actions.GET_CITY_LIST_FOR_SERVICE_CENTER_REQUEST,
    payload
  }),
  getPostalCodeForServiceCenter: (payload) => ({
    type: actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_REQUEST,
    payload
  }),
  getTotalServiceCenter: (payload) => ({
    type: actions.GET_TOTAL_SERVICE_CENTER_LIST_REQUEST,
    payload
  })
}
export default actions
