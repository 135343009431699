const actions ={
    GET_FEEDBACK_DASHBOARD:'GET_FEEDBACK_DASHBOARD',
    SUCCESS_FEEDBACK_DASHBOARD:'SUCCESS_FEEDBACK_DASHBOARD',
    ERROR_FEEDBACK_DASHBOARD:'ERROR_FEEDBACK_DASHBOARD',

    GET_FEEDBACK_TABLE:'GET_FEEDBACK_TABLE',
    SUCCESS_GET_FEEDBACK_TABLE:'SUCCESS_GET_FEEDBACK_TABLE',
    ERROR_GET_FEEDBACK_TABLE:'ERROR_GET_FEEDBACK_TABLE',

    getFeedbackDashboardData : (payload) =>({
        type: actions.GET_FEEDBACK_DASHBOARD,
        payload
    }),
    successDashboardData : (payload) =>({
        type: actions.SUCCESS_FEEDBACK_DASHBOARD,
        payload
    }),
    errorDashboardData : (payload) =>({
        type:actions.ERROR_FEEDBACK_DASHBOARD,
        payload
    }),
    getFeedbackTableData : (payload) =>({
        type:actions.GET_FEEDBACK_TABLE,
        payload
    }),
    sucessFeedbackTableData :(payload) =>({
        type:actions.SUCCESS_GET_FEEDBACK_TABLE,
        payload
    }),
    errorFeedbackTableData :(payload) =>({
        type:actions.ERROR_GET_FEEDBACK_TABLE,
        payload
    })

}
export default actions;