const BASE_URL = process.env.REACT_APP_API_ENDPOINT

export default {
  url: {
    login: `${BASE_URL}/api/v1/user/login-user/`,
    logout: `${BASE_URL}/api/v1/user/logout-user/`,
    getConfig: `${BASE_URL}/api/v1/user/config/`,
    getDashboardData: `${BASE_URL}/api/v1/policy/policy-dashboard/`,
    getPolicyList: `${BASE_URL}/api/v1/policy/policy-list/`,
    getPolicyDetail: `${BASE_URL}/api/v1/policy/policy-detail/`,
    resetPassword: `${BASE_URL}/api/v1/user/reset-password/`,
    forgotPassword: `${BASE_URL}/api/v1/user/forgot-password/`,
    getProducts: `${BASE_URL}/api/v1/partner/products/`,
    getPartners: `${BASE_URL}/api/v1/partner/lists/`,
    sendReportPolicy: `${BASE_URL}/api/v1/policy/email-policy/`,
    getClaimLists: `${BASE_URL}/api/v1/claims/lists/`,
    getDetailsClaim: `${BASE_URL}/api/v1/claims/details/`,
    getActiveClaimsUrl: `${BASE_URL}/api/v1/shipping/get-active-claims/`,
    getAllClaimsUrl: `${BASE_URL}/api/v1/shipping/get-all-claims/`,
    createClaimShippingUrl: `${BASE_URL}/api/v1/shipping/create-claims/`,
    uploadClaimPhotosUrl: `${BASE_URL}/api/v1/shipping/upload/`,
    submitClaimUrl: `${BASE_URL}/api/v1/shipping/submit-claim/`,
    uploadBulkImageUrl: `${BASE_URL}/api/v1/shipping/upload-bulk-image/`,
    activeClaimDetailsUrl: `${BASE_URL}/api/v1/shipping/get-active-detail-claim/`,
    apiIntegrateUrl: `${BASE_URL}/api/v1/shipping/api-integrate/`,
    trackingInformationUrl: `${BASE_URL}/api/v1/shipping/tracking-information/`,
    notificationsUrl: `${BASE_URL}/api/v1/notifications`,
    fileUrls: `${BASE_URL}/api/v1/file`,
    getClaimDashboardData: `${BASE_URL}/api/v1/claims/dashboard/`,
    getNotificationData: `${BASE_URL}/api/v1/notifications/dashboard/`,
    getclaimableDashboarData: `${BASE_URL}/api/v1/claimable/dashboard/`,
    getclaimCompletionTimeData: `${BASE_URL}/api/v1/claims/claim-completion-time/`,
    getClaimFilters: `${BASE_URL}/api/v1/claims/filters/`,
    sendReportClaims: `${BASE_URL}/api/v1/claims/email-list/`,
    getUserManagementList: `${BASE_URL}/api/v1/user-manager/user-list/`,
    getUserGroupList: `${BASE_URL}/api/v1/user-manager/group-list/`,
    getTimezoneList: `${BASE_URL}/api/v1/ops/list-timezones/`,
    addNewUser: `${BASE_URL}/api/v1/user-manager/add-user/`,
    updateUser: `${BASE_URL}/api/v1/user-manager/update-user/`,
    deleteUser: `${BASE_URL}/api/v1/user-manager/delete-user/`,
    deactivateUser: `${BASE_URL}/api/v1/user-manager/mark-inactive/`,
    activateUser: `${BASE_URL}/api/v1/user-manager/mark-active/`,
    validateResetId: `${BASE_URL}/api/v1/user/check-reset-password/`,
    feedBackDashboard: `${BASE_URL}/api/v1/feedback/dashboard/`,
    feedBackTable: `${BASE_URL}/api/v1/feedback/detail-table/`,
    uploadClaimDoc: `${BASE_URL}/api/v2/claims/upload/`,
    saveClaimFormAttribute: `${BASE_URL}/api/v2/claims/save-form-attribute/`,
    createClaimWithDocuments: `${BASE_URL}/api/v1/claims/create-with-documents/`,
    createClaim: `${BASE_URL}/api/v1/claims/create-claim/`,
    benefitsList: `${BASE_URL}/api/v1/claims/benefits/`,
    validateClaim: `${BASE_URL}/api/v1/claims/validate/`,
    searchCustomer: `${BASE_URL}/api/v1/customer/search/`,
    getCustomerDetails: `${BASE_URL}/api/v1/customer/details/`,
    getCustomerPolicies: `${BASE_URL}/api/v1/customer/customer-policies/`,
    getCustomerClaims: `${BASE_URL}/api/v1/customer/customer-claims/`,
    cancelFileUpload: (fileId) => `${BASE_URL}/api/v1/file/${fileId}/cancel/`,
    downloadOriginalFile: (fileId) => `${BASE_URL}/api/v1/file/${fileId}/download-original-file/`,
    getClientPartners: `${BASE_URL}/api/v1/partner/get-client-partner/`,
    getWalletList: `${BASE_URL}/api/v1/asowallet/summary/`,
    getWalletTransaction:`${BASE_URL}/api/v1/asowallet/transaction_list/`,
    sendReportTransactionHistoryRange:`${BASE_URL}/api/v1/asowallet/download/`,
    getPackage:`${BASE_URL}/api/v1/partner/packages/`,
  }
}
