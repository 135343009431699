import actions from './actions'

const initialState = {
  reportListMessage: '',
  reportList: [],
  createReportMessage: ''
}

export default function CustomerReducer (state = initialState, action) {
  switch (action.type) {
    case actions.GET_REPORTING_LIST_SUCCESS:
    case actions.GET_REPORTING_LIST_FAILURE:
      return {
        ...state,
        reportList: action.data,
        reportListMessage: action.message
      }
    case actions.REPORT_STATUS_CHANGE_SUCCESS:
    case actions.REPORT_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        reportStatusChangeMessage: action.message
      }
    case actions.CREATE_REPORT_SUCCESS:
    case actions.CREATE_REPORT_FAILURE:
      return {
        ...state,
        createReportMessage: action.message
      }
    case actions.UPDATE_REPORT_SUCCESS:
    case actions.UPDATE_REPORT_FAILURE:
      return {
        ...state,
        updateReportMessage: action.message
      }
    case actions.SEND_REPORT_SUCCESS:
    case actions.SEND_REPORT_FAILURE:
      return {
        ...state,
        sendReportMessage: action.message
      }
    case actions.GET_REQUESTS_REPORT_SUCCESS:
    case actions.GET_REQUESTS_REPORT_FAILURE:
      return {
        ...state,
        reportRequests: action.data
      }
    case actions.GET_SIGNED_URL_SUCCESS:
    case actions.GET_SIGNED_URL_FAILURE:
      return {
        ...state,
        reportFile: action.data
      }
    case actions.GET_REPORT_VIEW_SUCCESS:
    case actions.GET_REPORT_VIEW_FAILURE:
      return {
        ...state,
        viewReportData: action.data,
        viewReportDataMessage: action.message
      }
    default:
      return state
  }
}
