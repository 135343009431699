import { all, takeEvery, call, put, fork, takeLatest } from 'redux-saga/effects'
import { clone, get, find, findIndex } from 'lodash'
import moment from 'moment'

import actions from './actions'
import callApi from '../../../../helpers/callApi'
import middleware from '../../../../middleware/responseChecker'
import authGet from '../../../../request/methods/authGet'
import dashboardUrls from '../../../../config/urls/dashboard'

export function * getDashboardData () {
  yield takeEvery(actions.GET_DASHBOARD_DATA_REQUEST, function * (payload) {
    let res = yield call(callApi.getDashboardData, payload.payload)
    if (res.status === 200) {
      const data = res.data
      const {
        data: policyData,
        products,
        total_payment: totalPayment,
        total_policies: totalPolicies,
        total_transaction: totalTransaction
      } = data
      const chartData = []
      const dayCount = (policyData[products[0]] || []).length

      products.forEach(product => {
        for (let i = 0; i < dayCount; i++) {
          let obj = {}
          let formattedDate = moment(policyData[product][i]['date'], 'YYYY-MM-DD').format('DD MMM YYYY')
          if (!find(chartData, { date: formattedDate })) {
            obj.date = formattedDate
            obj[product] = policyData[product][i]['policy_created']
            chartData.push(obj)
          } else {
            obj = find(chartData, { date: formattedDate })
            const index = findIndex(chartData, { date: formattedDate })
            obj[product] = policyData[product][i]['policy_created']
            chartData[index] = obj
          }
        }
      })

      yield put({
        type: actions.GET_DASHBOARD_DATA_SUCCESS,
        products,
        chartData,
        totalPayment,
        totalPolicies,
        totalTransaction,
        message: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_DASHBOARD_DATA_FAILURE,
        message: 'Failure fetching dashboard data'
      })
    }
  })
}

export function * getClaimDashBoardData () {
  yield takeLatest(actions.GET_CLAIM_DASHBOARD_DATA_REQUEST, function * ({ payload }) {
    let res = yield call(callApi.getClaimDashboardData, payload)
    if (res.status === 200) {
      yield put({ type: actions.GET_CLAIM_DASHBOARD_DATA_SUCCESS, data: res.data, message: 'success' })
    } else {
      middleware(res)
      yield put({ type: actions.GET_CLAIM_DASHBOARD_DATA_FAILURE, data: undefined, message: 'error' })
    }
  })
}

export function * getNotificationData () {
  yield takeEvery(actions.GET_NOTIFICATION_GRAPH_DATA_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(callApi.getNotificationData, payload)
      yield put({ type: actions.GET_NOTIFICATION_GRAPH_DATA_SUCCESS, data: res.data, message: 'success' })
    } catch (e) {
      yield put({ type: actions.GET_NOTIFICATION_GRAPH_DATA_FAILURE, data: undefined, message: e })
    }
  })
}

export function * getclaimableDashboarData () {
  yield takeEvery(actions.GET_CLAIMABLE_DASHBOARD_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(callApi.getclaimableDashboarData, payload)
      yield put({ type: actions.GET_CLAIMABLE_DASHBOARD_DATA_SUCCESS, data: res.data, message: 'success' })
    } catch (e) {
      yield put({ type: actions.GET_CLAIMABLE_DASHBOARD_DATA_FAILURE, data: undefined, message: e })
    }
  })
}

export function * getclaimCompletionTimeData () {
  yield takeEvery(actions.GET_CLAIM_COMPLETION_TIME_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(callApi.getclaimCompletionTimeData, payload)
      yield put({ type: actions.GET_CLAIM_COMPLETION_TIME_DATA_SUCCESS, data: res.data, message: 'success' })
    } catch (e) {
      yield put({ type: actions.GET_CLAIM_COMPLETION_TIME_DATA_FAILURE, data: undefined, message: e })
    }
  })
}

export function * getClientPartners () {
  yield takeEvery(actions.GET_CLIENT_PARTNER_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(callApi.getClientPartners, payload)
      if (res.status === 200) {
        let data = clone(res.data)
        if (data.length) {
          data.splice(0, 1)
        }
        yield put({ type: actions.GET_CLIENT_PARTNER_SUCCESS, data: data, allClientPartners: res.data, message: 'success' })
      } else {
        yield put({ type: actions.GET_CLIENT_PARTNER_SUCCESS, data: [], allClientPartners: [], message: 'Error' })
      }
    } catch (e) {
      yield put({ type: actions.GET_CLIENT_PARTNER_FAILURE, data: undefined, allClientPartners: [], message: e })
    }
  })
}

export function * getPackageData () {
  yield takeEvery(actions.GET_PACKAGE_DATA_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: dashboardUrls.getPackageData })
      if (res.status === 200) {
        yield put({
          type: actions.GET_PACKAGE_DATA_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_PACKAGE_DATA_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PACKAGE_DATA_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}
export function * getPackageRequest () {
  yield takeEvery(actions.GET_PACKAGES, function * ({ payload }) {
    let res = yield call(callApi.getPackage, payload)
    if ( res.status === 200 ){
      yield put({ type: actions.DATA_PACKAGES, packages: res.data })
    } else {
      yield put({ type: actions.FAILED_GET_DATA_PACKAGES, failedMessageGetPackage: 'Ooops, something went terribly wrong!' })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getDashboardData),
    fork(getClaimDashBoardData),
    fork(getNotificationData),
    fork(getclaimableDashboarData),
    fork(getclaimCompletionTimeData),
    fork(getClientPartners),
    fork(getPackageData),
    fork(getPackageRequest)
  ])
}
