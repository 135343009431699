import actions from './actions'

const initialState = {
  getFileTemplateMessage: '',
  getFileTemplateSampleMessage: '',
  getFileRowsExcelMessage: '',
  postBulkUploadFileMessage: '',
  templateList: [],
  fileList: [],
  fileDetails: {},
  fileRows: [],
  totalRecords: 0,
  fileUrl: ''
}

export default function uploadReducer (state = initialState, action) {
  switch (action.type) {
    case actions.GET_FILE_TEMPLATES_SUCCESS:
      return {...state, templateList: action.data, getFileTemplateMessage: action.message}
    case actions.GET_FILE_TEMPLATES_FAILURE:
      return {...state, getFileTemplateMessage: action.message}
    case actions.GET_FILE_TEMPLATE_SAMPLE_SUCCESS:
    case actions.GET_FILE_TEMPLATE_SAMPLE_FAILURE:
      return {...state, getFileTemplateSampleMessage: action.message}
    case actions.GET_FILE_LIST_SUCCESS:
      return {...state, fileList: action.data, getFileListMessage: action.message}
    case actions.GET_FILE_LIST_FAILURE:
      return {...state, getFileListMessage: action.message}
    case actions.GET_FILE_DETAILS_SUCCESS:
      return {...state, fileDetails: action.data, getFileDetailsMessage: action.message}
    case actions.GET_FILE_DETAILS_FAILURE:
      return {...state, getFileDetailsMessage: action.message}
    case actions.GET_FILE_ROWS_SUCCESS:
    case actions.GET_FILE_ROWS_FAILURE:
      return {...state, fileRows: action.data, totalRecords: action.totalRecords, getFileRowsMessage: action.message}
    case actions.GET_FILE_ROWS_EXCEL_SUCCESS:
    case actions.GET_FILE_ROWS_EXCEL_FAILURE:
      return {...state, getFileRowsExcelMessage: action.message}
    case actions.POST_BULK_UPLOAD_FILE_SUCCESS:
      return {...state, postBulkUploadFileMessage: action.message}
    case actions.POST_BULK_UPLOAD_FILE_FAILURE:
      return {...state, postBulkUploadFileMessage: action.message}
    case actions.CANCEL_UPLOAD_SUCCESS:
    case actions.CANCEL_UPLOAD_FAILURE:
      return {...state, cancelStatus: action.message}
    case actions.DOWNLOAD_ORIGINAL_FILE_SUCCESS:
    case actions.DOWNLOAD_ORIGINAL_FILE_FAILURE:
      return {...state, fileUrl: action.data, fileUrlMessage: action.message}
    case actions.RESET_UPLOAD_DATA_REDUX:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initialState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
