import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import actions from './actions'
import callApi from '../../../../helpers/callApi'

export function * getFeedBackDashboard (){
    yield takeEvery('GET_FEEDBACK_DASHBOARD', function * (payload) {
        let res = yield call(callApi.feedBackDashboard ,payload)
        if(res.status === 200){
            yield put({ type: actions.SUCCESS_FEEDBACK_DASHBOARD , data :res.data ,feedbackDashboardMessage:'success'})
        } else{
            yield put({ type: actions.ERROR_FEEDBACK_DASHBOARD ,data :[] ,feedbackDashboardMessage:'error'})
        }
    })
}

export function * getFeedbackTableData (){
    yield takeEvery('GET_FEEDBACK_TABLE', function * (payload) {
        let res = yield call(callApi.feedBackTable ,payload)
        if(res.status === 200){
            yield put({ type: actions.SUCCESS_GET_FEEDBACK_TABLE , data :res.data ,feedbackTableMessage:'success'})
        } else{
            yield put({ type: actions.ERROR_GET_FEEDBACK_TABLE ,data :[] ,feedbackTableMessage:'error'})
        }
    })
}

export default function * rootSaga () {
    yield all ([
        fork(getFeedBackDashboard),
        fork(getFeedbackTableData)
    ])
}