import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import appSagas from './app/saga'
import errorSagas from './error/saga'
import loadingSagas from './loading/saga'
import dashboardSagas from '../containers/partnerApp/redux/dashboard/saga'
import policySagas from '../containers/partnerApp/redux/policies/saga'
import userSagas from '../containers/partnerApp/redux/user/saga'
import claimSagas from '../containers/partnerApp/redux/claims/saga'
import shippingSagas from '../containers/partnerApp/redux/shipping/saga'
import NotificationsSagas from '../containers/partnerApp/redux/notifications/saga'
import UploadSagas from '../containers/partnerApp/redux/upload/saga'
import UserManagementSagas from '../containers/partnerApp/redux/user-engagement/saga'
import FeedBackSagas from '../containers/partnerApp/redux/feedback/saga'
import CustomerSagas from '../containers/partnerApp/redux/customer/saga'
import ReportingSagas from '../containers/partnerApp/redux/reporting/saga'
import InsightSagas from '../containers/partnerApp/redux/insights/saga'
import walletSagas from '../containers/partnerApp/redux/Asowallet/saga'

export default function * rootSaga (getState) {
  yield all([
    authSagas(),
    appSagas(),
    errorSagas(),
    loadingSagas(),
    dashboardSagas(),
    policySagas(),
    userSagas(),
    claimSagas(),
    shippingSagas(),
    NotificationsSagas(),
    UploadSagas(),
    UserManagementSagas(),
    FeedBackSagas(),
    CustomerSagas(),
    ReportingSagas(),
    InsightSagas(),
    walletSagas()
  ])
}
