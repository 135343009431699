export default (url, params = {}) => {
  /*
    url takes string argument with format of api/v1/{paramKey},
    with bracketed key names being replacable values.

    passing in params argument with object of:
    {
      'paramKey': 'value'
    }
    will result in url being transformed into api/v1/value
  */
  let urlString = url
  Object.keys(params).forEach(key => urlString = urlString.replace(`{${key}}`, params[key]))
  return urlString
}
