import { Component } from 'react'
import { connect } from 'react-redux'
import { Layout, Spin, Icon, notification } from 'antd'
import { Debounce } from 'react-throttle'
import PropTypes from 'prop-types'
import WindowResizeListener from 'react-window-size-listener'
import { get } from 'lodash'

import { ThemeProvider } from 'styled-components'
import authAction from '../../redux/auth/actions'
import appActions from '../../redux/app/actions'
import loadingActions from '../../redux/loading/actions'
import errorActions from '../../redux/error/actions'
import Topbar from '../Topbar/Topbar'
import AppRouter from './AppRouter'
import themes from '../../settings/themes'
import { themeConfig } from '../../settings'
import AppHolder from './commonStyle'
import './global.css'
import TwoFactorAuth from './../Topbar/TwoFactorAuth'
import { GENERIC_IMAGES } from '../../constants/images'

const { Content, Footer } = Layout

const { logout } = authAction
const { toggleAll, getConfig } = appActions
const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions

const partnerLogo = (() => {
  if (window.location.hostname.includes('ppibroker')) return GENERIC_IMAGES.ppBrokerLogo
  return GENERIC_IMAGES.PASARPOLIS_LOGO
})()

export class App extends Component {
  state = {
    defaultUri: '',
    logo: partnerLogo,
    color: '',
    config: [],
    permissions: [],
    configLoading: true
  }

  uriDetection = uri => {
    this.setState({ defaultUri: uri })
  }

  componentDidMount () {
    this.props.resetLoadingRedux(['GET_CONFIG'])
    this.props.resetErrorRedux(['GET_CONFIG'])
  }

  componentWillMount = () => {
    this.props.getConfig()
  }

  componentWillReceiveProps (nextProps) {
    const {
      userDetails,
      userPermissions,
      partnerDetails,
      configLoading,
      configError,
      configResponseData
    } = nextProps
    const partnerConfig = partnerDetails.configs || []
    const colorConfig = (partnerConfig.find(x => (x.config_type || {}).name === 'COLOR_CODE') || {}).value || ''
    // const partnerLogo = (partnerConfig.find(x => (x.config_type || {}).name === 'PARTNER_LOGO') || {}).value || ''
    const headerTextColor = (partnerConfig.find(x => (x.config_type || {}).name === 'HEADER_TEXT_COLOR_CODE') || {}).value || ''
    this.setState({
      config: partnerConfig,
      color: colorConfig,
      // logo: partnerLogo,
      headerTextColor,
      permissions: userPermissions,
      userDetails,
      configLoading
    })

    if (configLoading === false && configError) {
      const resetRequestID = get(configResponseData, 'reset_request_id')
      const userEmail = get(configResponseData, 'user_email')
      const errorMessage = get(configResponseData, 'message')
      if (resetRequestID) {
        notification['error']({ message: errorMessage })
        this.props.logout()
        setTimeout(() => {
          this.props.history.push(`/reset-password/${resetRequestID}?email=${userEmail}`)
        }, 100)
      }
      this.props.resetLoadingRedux(['GET_CONFIG'])
      this.props.resetErrorRedux(['GET_CONFIG'])
    }
  }

  render () {
    const { url } = this.props.match

    const {
      height
    } = this.props
    const {
      logo,
      color,
      permissions,
      headerTextColor,
      userDetails,
      config,
      configLoading
    } = this.state
    const appHeight = window.innerHeight
    const footerLogo = (() => {
      if (window.location.hostname.includes('ppibroker')) return GENERIC_IMAGES.ppBrokerLogo
      return GENERIC_IMAGES.PASARPOLIS_LOGO
    })()
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder color={color} headerTextColor={headerTextColor}>
          <Layout style={{ height: appHeight }}>
            <Debounce time='1000' handler='onResize'>
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Spin
              spinning={configLoading}
              indicator={<Icon type='loading' spin />}
            >
              {
                !configLoading ? (
                  <div>
                    <TwoFactorAuth userDetails={userDetails} />
                    <Topbar
                      url={url}
                      logo={logo}
                      config={config}
                      permissions={permissions}
                      userDetails={userDetails}
                      uriDetection={this.uriDetection}
                      {...this.props}
                    />
                    <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                      <Layout
                        className='isoContentMainLayout'
                        style={{
                          height: height
                        }}
                      >
                        <Content
                          className='isomorphicContent'
                          style={{
                            padding: '70px 0 0',
                            flexShrink: '0',
                            background: '#f1f3f6',
                            position: 'relative'
                          }}
                        >
                          <AppRouter
                            permissions={permissions}
                            config={config}
                            url={url}
                            group={userDetails.groups}
                            {...this.props}

                          />
                        </Content>
                        <div>
                          <Footer
                            style={{
                              background: '#ffffff',
                              textAlign: 'center',
                              borderTop: '1px solid #ededed',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <p>Powered by &nbsp;</p>
                            <img width='100px' height='22px' src={footerLogo} alt='img' />
                          </Footer>
                        </div>
                      </Layout>
                    </Layout>
                  </div>
                ) : ''
              }
            </Spin>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    )
  }
}

App.propTypes = {
  userDetails: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
  partnerDetails: PropTypes.object.isRequired
}

App.defaultProps = {
  userDetails: {},
  userPermissions: [],
  partnerDetails: {},
  configLoading: true
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height,
    userDetails: state.App.userDetails,
    userPermissions: state.App.userPermissions,
    partnerDetails: state.App.partnerDetails,

    configError: state.Error.GET_CONFIG,
    configLoading: state.Loading.GET_CONFIG,
    configResponseData: state.App.configResponseData
  }),
  {
    logout,
    toggleAll,
    getConfig,
    resetLoadingRedux,
    resetErrorRedux
  }
)(App)
