export default {
  apiUrl: 'http://yoursite.com/api/'
}

const ppiBrokerConfig = {
  siteName: 'PP Broker',
  siteIcon: 'ion-flash',
  footerText: 'Powered by'
}
const PasarPolisConfig = {
  siteName: 'PasarPolis',
  siteIcon: 'ion-flash',
  footerText: 'Powered by'
}

const siteConfig = (() => {
  if (window.location.hostname.includes('ppibroker')) return ppiBrokerConfig
  return PasarPolisConfig
})()

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
}

export {
  siteConfig,
  themeConfig
}
