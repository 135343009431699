const actions = {
  GET_REPORTING_LIST_REQUEST: 'GET_REPORTING_LIST_REQUEST',
  GET_REPORTING_LIST_SUCCESS: 'GET_REPORTING_LIST_SUCCESS',
  GET_REPORTING_LIST_FAILURE: 'GET_REPORTING_LIST_FAILURE',

  REPORT_STATUS_CHANGE_REQUEST: 'REPORT_STATUS_CHANGE_REQUEST',
  REPORT_STATUS_CHANGE_SUCCESS: 'REPORT_STATUS_CHANGE_SUCCESS',
  REPORT_STATUS_CHANGE_FAILURE: 'REPORT_STATUS_CHANGE_FAILURE',

  CREATE_REPORT_REQUEST: 'CREATE_REPORT_REQUEST',
  CREATE_REPORT_SUCCESS: 'CREATE_REPORT_SUCCESS',
  CREATE_REPORT_FAILURE: 'CREATE_REPORT_FAILURE',

  UPDATE_REPORT_REQUEST: 'UPDATE_REPORT_REQUEST',
  UPDATE_REPORT_SUCCESS: 'UPDATE_REPORT_SUCCESS',
  UPDATE_REPORT_FAILURE: 'UPDATE_REPORT_FAILURE',

  SEND_REPORT_REQUEST: 'SEND_REPORT_REQUEST',
  SEND_REPORT_SUCCESS: 'SEND_REPORT_SUCCESS',
  SEND_REPORT_FAILURE: 'SEND_REPORT_FAILURE',

  GET_REQUESTS_REPORT_REQUEST: 'GET_REQUESTS_REPORT_REQUEST',
  GET_REQUESTS_REPORT_SUCCESS: 'GET_REQUESTS_REPORT_SUCCESS',
  GET_REQUESTS_REPORT_FAILURE: 'GET_REQUESTS_REPORT_FAILURE',

  GET_SIGNED_URL_REQUEST: 'GET_SIGNED_URL_REQUEST',
  GET_SIGNED_URL_SUCCESS: 'GET_SIGNED_URL_SUCCESS',
  GET_SIGNED_URL_FAILURE: 'GET_SIGNED_URL_FAILURE',

  GET_REPORT_VIEW_REQUEST: 'GET_REPORT_VIEW_REQUEST',
  GET_REPORT_VIEW_SUCCESS: 'GET_REPORT_VIEW_SUCCESS',
  GET_REPORT_VIEW_FAILURE: 'GET_REPORT_VIEW_FAILURE',

  getReportingList: payload => ({
    type: actions.GET_REPORTING_LIST_REQUEST,
    payload
  }),
  reportStatusChange: payload => ({
    type: actions.REPORT_STATUS_CHANGE_REQUEST,
    payload
  }),
  createReport: payload => ({
    type: actions.CREATE_REPORT_REQUEST,
    payload
  }),
  updateReport: payload => ({
    type: actions.UPDATE_REPORT_REQUEST,
    payload
  }),
  sendReport: payload => ({
    type: actions.SEND_REPORT_REQUEST,
    payload
  }),
  getReportRequests: payload => ({
    type: actions.GET_REQUESTS_REPORT_REQUEST,
    payload
  }),
  getFileSignedUrl: payload => ({
    type: actions.GET_SIGNED_URL_REQUEST,
    payload
  }),
  getReportView: payload => ({
    type: actions.GET_REPORT_VIEW_REQUEST,
    payload
  })
}

export default actions
