const actions = {
  POLICY_LIST: 'POLICY_LIST',

  GET_POLICY_LIST_REQUEST: 'GET_POLICY_LIST_REQUEST',
  GET_POLICY_LIST_SUCCESS: 'GET_POLICY_LIST_SUCCESS',
  GET_POLICY_LIST_FAILURE: 'GET_POLICY_LIST_FAILURE',

  GET_POLICY_LIST_COUNT_REQUEST: 'GET_POLICY_LIST_COUNT_REQUEST',
  GET_POLICY_LIST_COUNT_SUCCESS: 'GET_POLICY_LIST_COUNT_SUCCESS',
  GET_POLICY_LIST_COUNT_FAILURE: 'GET_POLICY_LIST_COUNT_FAILURE',

  FAILED_GET_POLICY_LIST: 'FAILED_GET_POLICIES',
  POLICY_DETAIL: 'POLICY_DETAIL',
  GET_POLICY_DETAIL_REQUEST: 'GET_POLICY_DETAIL_REQUEST',
  GET_POLICY_DETAIL_SUCCESS: 'GET_POLICY_DETAIL_SUCCESS',
  GET_POLICY_DETAIL_FAILURE: 'GET_POLICY_DETAIL_FAILURE',

  GET_POLICY_DETAIL_REQUEST_V2: 'GET_POLICY_DETAIL_REQUEST_V2',
  GET_POLICY_DETAIL_SUCCESS_V2: 'GET_POLICY_DETAIL_SUCCESS_V2',
  GET_POLICY_DETAIL_FAILURE_V2: 'GET_POLICY_DETAIL_FAILURE_V2',

  FAILED_GET_POLICY_DETAIL: 'FAILED_GET_POLICY_DETAIL',
  GET_PARTNERS: 'GET_PARTNERS',
  GET_PRODUCTS: 'GET_PRODUCTS',
  FAILED_GET_DATA_PRODUCTS: 'FAILED_GET_DATA_PRODUCTS',
  DATA_PRODUCTS: 'DATA_PRODUCTS',
  RESET_REDUX_STATE_POLICY: 'RESET_REDUX_STATE_POLICY',
  SEND_REPORT_POLICY: 'SEND_REPORT_POLICY',
  SUCCESS_SEND_REPORT_POLICY: 'SUCCESS_SEND_REPORT_POLICY',
  FAILED_SEND_REPORT_POLICY: 'FAILED_SEND_REPORT_POLICY',
  SEND_REPORT_POLICY_RANGE: 'SEND_REPORT_POLICY_RANGE',
  SUCCESS_SEND_REPORT_POLICY_RANGE: 'SUCCESS_SEND_REPORT_POLICY_RANGE',
  FAILED_SEND_REPORT_POLICY_RANGE: 'FAILED_SEND_REPORT_POLICY_RANGE',
  RESET_DOWNLOAD_STATUS: 'RESET_DOWNLOAD_STATUS',
  DATA_PARTNERS: 'DATA_PARTNERS',
  FAILED_GET_DATA_PARTNERS: 'FAILED_GET_DATA_PARTNERS',

  GET_MASTER_LIST_REQUEST: 'GET_MASTER_LIST_REQUEST',
  GET_MASTER_LIST_SUCCESS: 'GET_MASTER_LIST_SUCESS',
  GET_MASTER_LIST_FAILURE: 'GET_MASTER_LIST_FAILURE',

  policyList: () => ({
    type: actions.POLICY_LIST
  }),
  getPolicyList: (payload) => ({
    type: actions.GET_POLICY_LIST_REQUEST,
    payload
  }),
  getPolicyListCount: (payload) => ({
    type: actions.GET_POLICY_LIST_COUNT_REQUEST,
    payload
  }),
  failedGetPolicyList: () => ({
    type: actions.FAILED_GET_POLICY_LIST
  }),
  policyDetail: () => ({
    type: actions.POLICY_DETAIL
  }),
  getPolicyDetail: (payload) => ({
    type: actions.GET_POLICY_DETAIL_REQUEST,
    payload
  }),
  getPolicyDetailV2: (payload) => ({
    type: actions.GET_POLICY_DETAIL_REQUEST_V2,
    payload
  }),
  getProductsRequest: payload => ({
    type: actions.GET_PRODUCTS,
    payload
  }),
  failedGetProducts: payload => ({
    type: actions.FAILED_GET_DATA_PRODUCTS,
    payload
  }),
  dataProducts: payload => ({
    type: actions.DATA_PRODUCTS,
    payload
  }),
  resetReduxState: () => ({
    type: actions.RESET_REDUX_STATE_POLICY
  }),
  sendReport: payload => ({
    type: actions.SEND_REPORT_POLICY,
    payload
  }),
  successSendReportPolicy: payload => ({
    type: actions.SUCCESS_SEND_REPORT_POLICY,
    payload
  }),
  failedSendReportPolicy: payload => ({
    type: actions.FAILED_SEND_REPORT_POLICY,
    payload
  }),
  sendReportPolicyRange: payload => ({
    type: actions.SEND_REPORT_POLICY_RANGE,
    payload
  }),
  successSendReportPolicyRange: payload => ({
    type: actions.SUCCESS_SEND_REPORT_POLICY_RANGE,
    payload
  }),
  failedSendReportPolicyRange: payload => ({
    type: actions.FAILED_SEND_REPORT_POLICY_RANGE,
    payload
  }),
  resetDownloadStatus: () => ({
    type: actions.RESET_DOWNLOAD_STATUS
  }),
  dataPartners: payload => ({
    type: actions.DATA_PARTNERS,
    payload
  }),
  failedGetPartners: payload => ({
    type: actions.FAILED_GET_DATA_PARTNERS,
    payload
  }),
  getMasterList: payload => ({
    type: actions.GET_MASTER_LIST_REQUEST,
    payload
  })
}

export default actions
