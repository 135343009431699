import actions from './actions'
import { get } from 'lodash'

const initialState = {
  getClaimListsStatus: '',
  data: [],
  metafield: {},
  getDetailsClaimStatus: '',
  detailData: {},
  getStatusSubmitEvent: '',
  saveAttributesStatus: '',
  claimBenefitsData: [],
  getClaimBenefitsStatus: '',
  createClaimStatus: undefined,
  claimId: undefined,
  saveAttributesDocStatus: '',
  message: '',
  uploadCreateClaimDocLoading: {},
  uploadCreateClaimDocValues: {},
  verifyBankAccountFormStatus: '',
  verifyAccountData: undefined,
  createdClaimWithDoc: {},
  benefitsList: undefined,
  getBenefitsListStatus: '',
  validateClaimData: {},
  salvageForms: {},
  postSalvageFormStatus: '',
  validateClaimStatus: '',
  genericDataDocUpload: '',
  saveAttributesGenericDocStatus: '',
  uploadGenericClaimDocLoading: {},
  saveGenericAttributesStatus: '',
  selectedImage: '',
  saveDraftAttributesStatus: '',
  totalServiceCenterList: []
}

export default function partnerReducer (state = initialState, action) {
  switch (action.type) {
    case actions.RESET_REDUX_STATE_CLAIM:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initialState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state,
          ...initialState
        }
      }
    case actions.GET_CLAIM_LISTS_SUCCESS:
    case actions.GET_CLAIM_LISTS_FAILURE:
      return {
        ...state,
        getClaimListsStatus: action.getClaimListsStatus,
        data: action.data,
        metafield: action.meta_data
      }
    case actions.GET_CLAIM_FILTERS_SUCCESS:
    case actions.GET_CLAIM_FILTERS_FAILURE:
      return {
        ...state,
        getClaimFilterStatus: action.getClaimFilterStatus,
        filtersData: action.data
      }
    case actions.DETAILS_CLAIM:
      return {
        ...state,
        getDetailsClaimStatus: action.getDetailsClaimStatus,
        detailData: action.detailData
      }
    case actions.FAILED_GET_DETAILS_CLAIM:
      return {
        ...state,
        getDetailsClaimStatus: action.getDetailsClaimStatus,
        detailData: action.detailData
      }
    case actions.SUCCESS_SUBMIT_EVENT:
      return {
        ...state,
        getStatusSubmitEvent: action.getStatusSubmitEvent
      }
    case actions.FAILED_SUBMIT_EVENT:
      return {
        ...state,
        getStatusSubmitEvent: action.getStatusSubmitEvent
      }
    case actions.SUCCESS_SAVE_ATTRIBUTES:
      return {
        ...state,
        saveAttributesStatus: action.saveAttributesStatus
      }
    case actions.FAILED_SAVE_ATTRIBUTES:
      return {
        ...state,
        saveAttributesStatus: action.saveAttributesStatus,
        message: action.message
      }
    case actions.BENEFITS_DATA:
      return {
        ...state,
        claimBenefitsData: action.claimBenefitsData,
        getClaimBenefitsStatus: action.getClaimBenefitsStatus
      }
    case actions.FAILED_GET_BENEFITS_DATA:
      return {
        ...state,
        claimBenefitsData: action.claimBenefitsData,
        getClaimBenefitsStatus: action.getClaimBenefitsStatus
      }
    case actions.SUCCESS_CREATE_CLAIM:
      return {
        ...state,
        claimId: action.claimId,
        createClaimStatus: action.createClaimStatus
      }
    case actions.FAILED_CREATE_CLAIM:
      return {
        ...state,
        createClaimStatus: action.createClaimStatus
      }
    case actions.SUCCESS_SAVE_ATTRIBUTES_DOC:
      return {
        ...state,
        saveAttributesDocStatus: action.saveAttributesDocStatus
      }
    case actions.FAILED_SAVE_ATTRIBUTES_DOC:
      return {
        ...state,
        saveAttributesDocStatus: action.saveAttributesDocStatus
      }
    case actions.SUCCESS_SEND_REPORT_CLAIMS:
    case actions.FAILED_SEND_REPORT_CLAIMS:
      return {
        ...state,
        sendPolicyReportStatus: action.sendPolicyReportStatus
      }
    case actions.UPDATE_UPLOAD_CREATE_CLAIM_DOC_LOADING_STATES:
      return {
        ...state,
        uploadCreateClaimDocLoading: {
          ...state.uploadCreateClaimDocLoading,
          ...action.target
        }
      }
    case actions.UPDATE_UPLOAD_CREATE_CLAIM_DOC_VALUES:
      return {
        ...state,
        uploadCreateClaimDocValues: {
          ...state.uploadCreateClaimDocValues,
          ...action.target
        }
      }
    case actions.CREATE_CLAIM_WITH_DOC_SUCCESS:
    case actions.CREATE_CLAIM_WITH_DOC_FAILURE:
      return {
        ...state,
        createdClaimWithDoc: action.createdClaimWithDoc,
        createClaimWithDocStatus: action.createClaimWithDocStatus
      }
    case actions.GET_BENEFITS_SUCCESS:
    case actions.GET_BENEFITS_FAILURE:
      return {
        ...state,
        benefitsList: action.benefitsList,
        getBenefitsListStatus: action.getBenefitsListStatus
      }
    case actions.VALIDATE_CLAIM_SUCCESS:
    case actions.VALIDATE_CLAIM_FAILURE:
      return {
        ...state,
        validateClaimData: action.validateClaimData,
        validateClaimStatus: action.validateClaimStatus
      }
    case actions.UPLOAD_GENERIC_CLAIM_DOC_SUCCESS:
    case actions.UPLOAD_GENERIC_CLAIM_DOC_FAILURE:
      return {
        ...state,
        genericDataDocUpload: action.attribute,
        saveAttributesGenericDocStatus: action.saveAttributesGenericDocStatus
      }
    case actions.SAVE_GENERIC_ATTRIBUTES_SUCCESS:
    case actions.SAVE_GENERIC_ATTRIBUTES_FAILURE:
      return {
        ...state,
        saveGenericAttributesStatus: action.saveGenericAttributesStatus
      }
    case actions.SAVE_DRAFT_ATTRIBUTES_SUCCESS:
    case actions.SAVE_DRAFT_ATTRIBUTES_FAILURE:
      return {
        ...state,
        saveDraftAttributesStatus: action.saveDraftAttributesStatus
      }
    case actions.VERIFY_BANK_ACCOUNT_FORMS_SUCCESS:
    case actions.VERIFY_BANK_ACCOUNT_FORMS_FAILURE:
      return {
        ...state,
        verifyBankAccountFormStatus: action.status,
        verifyAccountData: action.data
      }
    case actions.POST_SALVAGE_FORMS_SUCCESS:
    case actions.POST_SALVAGE_FORMS_FAILURE:
      return {
        ...state,
        postSalvageFormStatus: action.postSalvageFormStatus
      }
    case actions.GET_BANK_LIST_SUCCESS:
    case actions.GET_BANK_LIST_FAILURE:
      return {
        ...state,
        claimBankList: action.data
      }
    case actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_SUCCESS:
    case actions.GET_SERVICE_CENTER_LIST_ON_CLAIM_FAILURE:
      return {
        ...state,
        serviceCenterOnClaim: get(action, 'data', []).map(({ display_name: displayName, value }) => ({ value, displayName: decodeURI(displayName) })),
        serviceCenterOnClaimMessage: action.message
      }
    case actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_SUCCESS:
    case actions.GET_SERVICE_CENTER_BRANCH_LIST_ON_CLAIM_FAILURE:
      return {
        ...state,
        serviceCenterBranchListOnClaim: get(action, 'data', []).map(({ name, address, branch_code: value }) => ({ value, displayName: `${name} - ${address}` }))
      }

    case actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_SUCCESS:
    case actions.GET_PROVINCE_LIST_FOR_SERVICE_CENTER_FAILURE:
      return {
        ...state,
        provinceListOfServiceCenter: get(action, 'data', []).map(({ display_name: displayName, value }) => ({ value, displayName }))
      }

    case actions.GET_CITY_LIST_FOR_SERVICE_CENTER_SUCCESS:
    case actions.GET_CITY_LIST_FOR_SERVICE_CENTER_FAILURE:
      return {
        ...state,
        cityListOfServiceCenter: get(action, 'data', []).map(({ display_name: displayName, value }) => ({ value, displayName }))
      }

    case actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_SUCCESS:
    case actions.GET_POSTAL_CODE_FOR_SERVICE_CENTER_FAILURE:
      return {
        ...state,
        postalCodeListOnServiceCenter: get(action, 'data', []).map(({ display_name: displayName, value }) => ({ value, displayName }))
      }
    case actions.GET_TOTAL_SERVICE_CENTER_LIST_FAILURE:
    case actions.GET_TOTAL_SERVICE_CENTER_LIST_SUCCESS:
      return {
        ...state,
        totalServiceCenterList: get(action, 'data', [])
      }
    default:
      return state
  }
}
