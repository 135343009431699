import React, { createElement, Component, lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc'
import Page404 from '../partnerApp/containers/404'
import PropTypes from 'prop-types'

// define requiredPermission and requiredConfig

const routes = [
  {
    path: '',
    // component: asyncComponent(() => import("../dashboard"))
    component: lazy(() => import('../partnerApp/containers/Dashboard')),
    requiredPermission: 'view_dashboard'
  },
  {
    path: 'feedback',
    component: lazy(() => import('../partnerApp/containers/FeedBack/feedbackDashboard'))
  },
  {
    path: 'user-management',
    component: lazy(() => import('../partnerApp/containers/UserEngagement/userEnagagement')),
    requiredPermission: 'can_view_user'
  },
  {
    path: 'reporting',
    component: lazy(() => import('../partnerApp/containers/Reporting')),
    requiredPermission: 'view_reporting',
    requiredConfig: 'DISPLAY_REPORTING'
  },
  {
    path: 'reporting-requests',
    component: lazy(() => import('../partnerApp/containers/Reporting/reportRequest')),
    requiredPermission: 'view_reporting',
    requiredConfig: 'DISPLAY_REPORTING'
  },
  {
    path: 'view-report/:id',
    component: lazy(() => import('../partnerApp/containers/Reporting/components/displayReport')),
    requiredPermission: 'view_reporting',
    requiredConfig: 'DISPLAY_REPORTING'
  },
  // TODO: put right permission for audience and behaviour path
  {
    path: 'audience',
    component: lazy(() => import('../partnerApp/containers/ProductInsights/pages/Audience')),
    requiredPermission: 'view_product_insights_audience',
    requiredConfig: 'DISPLAY_PRODUCT_INSIGHTS'
  },
  {
    path: 'behaviour',
    component: lazy(() => import('../partnerApp/containers/ProductInsights/pages/Behaviour')),
    requiredPermission: 'view_product_insights_behaviour',
    requiredConfig: 'DISPLAY_PRODUCT_INSIGHTS'
  },
  {
    path: 'insights',
    component: lazy(() => import('../partnerApp/containers/Insights')),
    // requiredPermission: 'view_reporting',
    requiredConfig: 'DISPLAY_INSIGHTS'
  },
  {
    path: 'policy-list',
    component: lazy(() => import('../partnerApp/containers/PolicyList/policyList'))
  },
  {
    path: 'policy-list/:id',
    component: lazy(() => import('../partnerApp/containers/PolicyList/policyDetails'))
  },
  {
    path: 'claim-list',
    component: lazy(() => import('../partnerApp/containers/Claim')),
    requiredConfig: 'DISPLAY_CLAIM_LIST'
  },
  {
    path: 'claim-list/:id',
    component: lazy(() => import('../partnerApp/containers/Claim/detailClaim')),
    requiredConfig: 'DISPLAY_CLAIM_LIST'
  },
  {
    path: 'shipping/dash',
    component: lazy(() => import('../partnerApp/containers/Shipping/Dashboard')),
    exact: true
  },
  {
    path: 'shipping/grid',
    component: asyncComponent(() => import('../partnerApp/containers/Shipping/Grid'))
  },
  {
    path: 'shipping/claim/create',
    component: lazy(() => import('../partnerApp/containers/Shipping/Claim'))
  },
  {
    path: 'shipping/claim/create/upload',
    component: lazy(() => import('../partnerApp/containers/Shipping/Claim/mainUpload'))
  },
  {
    path: 'shipping/claim/create/success/:claimNo',
    component: lazy(() => import('../partnerApp/containers/Shipping/Claim/success')),
    exact: true
  },
  {
    path: 'shipping/claim/active/:claimId',
    component: lazy(() => import('../partnerApp/containers/Shipping/Claim/activeClaimDetail')),
    exact: true
  },
  {
    path: 'shipping/claim/all/:claimId',
    component: lazy(() => import('../partnerApp/containers/Shipping/Claim/allClaimDetail')),
    exact: true
  },
  {
    path: 'shipping/integrations',
    component: lazy(() => import('../partnerApp/containers/Shipping/Claim/apiIntegrations'))
  },
  {
    path: 'upload/',
    component: lazy(() => import('../partnerApp/containers/Upload')),
    requiredConfig: 'DISPLAY_BULK_UPLOAD'
  },
  {
    path: 'upload-list/',
    component: lazy(() => import('../partnerApp/containers/Upload/uploadList')),
    requiredConfig: 'DISPLAY_BULK_UPLOAD'
  },
  {
    path: 'upload-list/:id',
    component: lazy(() => import('../partnerApp/containers/Upload/uploadDetails')),
    requiredConfig: 'DISPLAY_BULK_UPLOAD'
  },
  {
    path: 'customer',
    component: lazy(() => import('../partnerApp/containers/Customer')),
    requiredPermission: 'view_customer360_search',
    requiredConfig: 'DISPLAY_CUSTOMER360_SEARCH'
  },
  {
    path: 'customer/search',
    component: lazy(() => import('../partnerApp/containers/Customer/SearchDisplay')),
    requiredPermission: 'view_customer360_search',
    requiredConfig: 'DISPLAY_CUSTOMER360_SEARCH'
  },
  {
    path: 'customer/:customerId',
    component: lazy(() => import('../partnerApp/containers/Customer/CustomerDetails')),
    requiredPermission: 'view_customer360_search',
    requiredConfig: 'DISPLAY_CUSTOMER360_SEARCH'
  },
  {
    path: 'wallet',
    component: lazy(() => import('../partnerApp/containers/Asowallet/pages/Wallet')),
    requiredPermission: 'view_asowallet_list',
    requiredConfig: 'DISPLAY_ASO_WALLET'
  },
  {
    path: 'wallet/:id',
    component: lazy(() => import('../partnerApp/containers/Asowallet/pages/WalletDetail')),
    requiredPermission: 'view_asowallet_list',
    requiredConfig: 'DISPLAY_ASO_WALLET'
  },
  {
    path: 'developers',
    component: lazy(() => import('../partnerApp/containers/Developers/pages/ApiKey')),
    requiredPermission: 'view_developers_panel'
  }
]

class AppRouter extends Component {
  // componentWillMount(){
  //     const {group} =this.props
  //     var newRoutes =[]
  //     if(group.length && group[0].name === 'OPERATIONS'){
  //       newRoutes = routes.filter(x => x.path ==='policy-list')
  //   }
  // }
  configLookUp = (config, value) => {
    return Array.isArray(config) && config.findIndex(x => (x.config_type || {}).name === value && x.value === '1') > -1
  }

  permissionLookUp = (permissions, value) => {
    return Array.isArray(permissions) && permissions.findIndex(x => (x.codename === value)) > -1
  }

  getRedirectionPath = () => {
    if (this.permissionLookUp('view_dashboard')) {
      return '/dashboard'
    }
    return '/dashboard/policy-list'
  }

  render () {
    const { url, style, config, permissions } = this.props
    return (
      <div style={style}>
        <Suspense fallback={<div />}>
          <Switch>
            {
              routes.map(singleRoute => {
                const {
                  component,
                  exact,
                  requiredConfig,
                  requiredPermission
                } = singleRoute
                return (
                  <Route exact={exact !== false} key={singleRoute.path} path={`${url}/${singleRoute.path}`} render={(props) => {
                    // if(group.length && group.find(({name}) => name === 'OPERATIONS')){
                    //   return React.createElement(component, {...props})
                    // }
                    // else {
                    let hasRequiredConfig = requiredConfig
                      ? this.configLookUp(config, requiredConfig)
                      : true
                    let hasRequiredPermissions = requiredPermission
                      ? this.permissionLookUp(permissions, requiredPermission)
                      : true
                    if (hasRequiredConfig && hasRequiredPermissions) {
                      return createElement(component, {
                        ...props
                      })
                    } else {
                      return <Redirect to={this.getRedirectionPath()} />
                    }
                    //  }
                  }}
                  // {...otherProps}
                  />
                )
              })
            }
            <Route component={Page404} />
          </Switch>
        </Suspense>
      </div>
    )
  }
}

AppRouter.propTypes = {
  permissions: PropTypes.array.isRequired,
  config: PropTypes.array.isRequired,
  group: PropTypes.array.isRequired
}

AppRouter.defaultProps = {
  permissions: [],
  config: [],
  group: []
}

export default AppRouter
