import { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'antd'
import userAction from '../../redux/user/actions'
import { SuspectActivityStyle } from './index.style'

const { suspectActivity } = userAction

class SuspectActivity extends Component {
  componentDidMount () {
    this.props.suspectActivity({ body: { userkey: this.props.match.params.userkey } })
  }

  goToHome = () => {
    const { history } = this.props
    history.push('/dashboard')
  }

  render () {
    return (
      <SuspectActivityStyle>
        <Row className='row'>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card>
              <Row>
                <Col xs={24} sm={24} md={12} lg={24} xl={24} className='col'>
                  <h2>Your request has been registered with us !!!</h2>
                  <Button onClick={this.goToHome}>HomePage</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </SuspectActivityStyle>
    )
  }
}

export default connect(
  null,
  {
    suspectActivity
  }
)(SuspectActivity)
