import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import actions from './actions'
import callApi from '../../../../helpers/callApi'

export function * getActiveClaimsRequest () {
  yield takeEvery('GET_ACTIVE_CLAIMS', function * (payload) {
    let res = yield call(callApi.getActiveClaims, payload)
    if (res.status === 200) {
      yield put({type: actions.ACTIVE_CLAIMS_DATA, data: res.data.data, getActiveClaimsStatus: 'success'})
    } else {
      yield put({type: actions.ACTIVE_CLAIMS_DATA, data: [], getActiveClaimsStatus: res.data.response})
    }
  })
}

export function * getAllClaimsRequest () {
  yield takeEvery('GET_ALL_CLAIMS', function * (payload) {
    let res = yield call(callApi.getAllClaims, payload)
    if (res.status === 200) {
      yield put({type: actions.ALL_CLAIMS_DATA, data: res.data.data, getAllClaimsStatus: 'success'})
    } else {
      yield put({type: actions.ALL_CLAIMS_DATA, data: [], getAllClaimsStatus: res.data.response})
    }
  })
}

export function * createClaimRequest () {
  yield takeEvery('CREATE_SHIPPING_CLAIM', function * (payload) {
    let res = yield call(callApi.createClaimShipping, payload)
    if (res.status === 201) {
      yield put({type: actions.CREATE_SHIPPING_CLAIM_STATUS, data: res.data, createClaimShippingStatus: 'success'})
    } else {
      yield put({type: actions.CREATE_SHIPPING_CLAIM_STATUS, data: [], createClaimShippingStatus: res.data.detail || res.data.error})
    }
  })
}

export function * uploadClaimPhotosRequest () {
  yield takeEvery('UPLOAD_SHIPPING_CLAIM_PHOTOS', function * (payload) {
    let res = yield call(callApi.uploadClaimPhotos, payload)
    if (res.status === 200) {
      yield put({type: actions.UPLOAD_SHIPPING_CLAIM_PHOTOS_STATUS, data: res.data, uploadClaimPhotosStatus: 'success'})
    } else {
      yield put({type: actions.UPLOAD_SHIPPING_CLAIM_PHOTOS_STATUS, data: '', uploadClaimPhotosStatus: res.data.response})
    }
  })
}

export function * submitClaimRequest () {
  yield takeEvery('SUBMIT_CLAIM', function * (payload) {
    let res = yield call(callApi.submitClaim, payload)
    if (res.status === 200) {
      yield put({type: actions.SUBMIT_CLAIM_STATUS, data: res.data, submitClaimStatus: 'success'})
    } else {
      yield put({type: actions.SUBMIT_CLAIM_STATUS, data: '', submitClaimStatus: res.data.response})
    }
  })
}

export function * uploadBulkImageRequest () {
  yield takeEvery('UPLOAD_BULK_IMAGE', function * (payload) {
    let res = yield call(callApi.uploadBulkImage, payload)
    if (res.status === 200) {
      yield put({type: actions.UPLOAD_BULK_IMAGE_STATUS, data: res.data.response, uploadBulkImageStatus: 'success'})
    } else {
      yield put({type: actions.UPLOAD_BULK_IMAGE_STATUS, data: '', uploadBulkImageStatus: res.data.response})
    }
  })
}

export function * activeClaimDetailRequest () {
  yield takeEvery('ACTIVE_CLAIM_DETAIL', function * (payload) {
    let res = yield call(callApi.activeClaimDetails, payload)
    if (res.status === 200) {
      yield put({type: actions.ACTIVE_CLAIM_DETAIL_STATUS, data: res.data, activeClaimDetailStatus: 'success'})
    } else {
      yield put({type: actions.ACTIVE_CLAIM_DETAIL_STATUS, data: [], activeClaimDetailStatus: res.data.response})
    }
  })
}

export function * apiIntegrateRequest () {
  yield takeEvery('API_INTEGRATE', function * (payload) {
    let res = yield call(callApi.apiIntegrate, payload)
    if (res.status === 200) {
      yield put({type: actions.API_INTEGRATE_STATUS, data: res.data, apiIntegrateStatus: 'success'})
    } else {
      yield put({type: actions.API_INTEGRATE_STATUS, data: [], apiIntegrateStatus: res.data.response})
    }
  })
}

export function * trackingInformationRequest () {
  yield takeEvery('TRACKING_INFORMATION', function * (payload) {
    let claimId = payload.payload.claimId
    let res = yield call(callApi.trackingInformation, payload)
    if (res.status === 200) {
      yield put({type: actions.ACTIVE_CLAIM_DETAIL, payload: {claimId}})
      yield put({type: actions.TRACKING_INFORMATION_STATUS, trackingInformationStatus: 'success'})
    } else {
      yield put({type: actions.TRACKING_INFORMATION_STATUS, trackingInformationStatus: 'failed'})
    }
  })
}


export default function * rootSaga () {
  yield all([
    fork(getActiveClaimsRequest),
    fork(getAllClaimsRequest),
    fork(createClaimRequest),
    fork(uploadClaimPhotosRequest),
    fork(submitClaimRequest),
    fork(uploadBulkImageRequest),
    fork(activeClaimDetailRequest),
    fork(trackingInformationRequest)
  ])
}
