import styled from 'styled-components'

export const TwoFactorAuthStyle = styled.div`
  h1 {
    font-weight: 700;
    font-size: 25px;
  }

  .twoFactor__imgContainer {
    width: 100%;
    display: grid;
    place-items: center;
  }

  .twoFactorAuth__img {
    height: 155px;
    width: 200px;
  }

  .twoFactorAuth__lower {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  }

  .twoFactorAuth__lowerLeft {
    display: flex;
    flex-direction: column;
  }

  .twoFactorAuth__lowerLeftButton {
    color: rgb(32 115 220);
    padding-bottom: 2px;
    cursor: pointer;
  }

  .twoFactorAuth__btn {
    background-color: #f1d135;
    border-radius: 20px;
    cursor: pointer;
    width: 115px;
    height: 45px;
    border: none;
  }

  b {
    font-weight: 900;
  }

  @media only screen and (max-width: 250px) {
    .twoFactorAuth__img {
      width: 85%;
    }
  }
`
