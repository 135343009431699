import { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import Popover from '../../components/uielements/popover'
import authAction from '../../redux/auth/actions'
import appAction from '../../redux/app/actions'
import TopbarDropdownWrapper, { UserProfile } from './topbarDropdown.style'
import { TopbarUserStyle } from './topbarUser.style'
import { Avatar, Divider, Modal, Icon } from 'antd'
import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'
import Profile from './userProfile'
import Security from './security'
import userLogo from '../../image/ellipse-1161.png'
import UserActions from '../partnerApp/redux/user/actions'

const cookies = new Cookies()
const { logout } = authAction
const { closeAll } = appAction
const { updateTopbarActiveModal } = UserActions

class TopbarUser extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      user: {},
      showProfile: false,
      showSecurity: false
    }
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible })
  }
  clearIt () {
    cookies.remove('isDemo')
  }

  handleProfileClick = () => {
    this.setState({ showProfile: true, visible: false })
    this.props.updateTopbarActiveModal('profile')
  }

  handleSecurityClick = () => {
    this.setState({ showSecurity: true, visible: false })
    this.props.updateTopbarActiveModal('security')
  }

  renderPopOver = () => {
    return (
      <TopbarUserStyle>
        <TopbarDropdownWrapper className='isoUserDropdown'>
          <div className='link' onClick={this.handleProfileClick}>
            Profile
          </div>
          <div className='link' onClick={this.handleSecurityClick}>
            Security
          </div>
          <Divider />
          <span
            className='isoDropdownLink anchor'
            onClick={() => {
              this.clearIt()
              this.props.logout()
              this.props.closeAll()
              localStorage.removeItem('popStatus')
            }}
          >
            Logout
          </span>
        </TopbarDropdownWrapper>
      </TopbarUserStyle>
    )
  }

  render () {
    const {
      userDetails,
      responsiveDesign,
      colorCode
    } = this.props
    const { visible, showProfile, showSecurity } = this.state
    const username = userDetails.name
    const useremail = userDetails.email

    return (
      <Fragment>
        {
          responsiveDesign
            ? <UserProfile>
              <img src={userLogo} style={{ width: 60, height: 60 }} alt='' />
              <span style={{ color: colorCode, float: 'right', marginTop: 10 }}>{username ? username.toUpperCase() : ''}</span>

              <span style={{ color: colorCode, float: 'right', wordBreak: 'break-word' }}>
                <Popover
                  content={this.renderPopOver()}
                  trigger='click'
                  visible={visible}
                  onVisibleChange={this.handleVisibleChange}
                  arrowPointAtCenter
                  placement='bottomRight'
                >
                  {!!useremail && useremail}  <Icon type='down' style={{ fontSize: 10, paddingLeft: 5 }} />
                </Popover>
              </span>
            </UserProfile>
            : <div className='UsernameSection'>
              <p className='username'>Hi, <b>{username ? (username.charAt(0).toUpperCase() + username.slice(1)) : 'U'}</b></p>
              <Modal
                visible={showProfile}
                title='User Profile'
                footer={null}
                onCancel={() => this.setState({ showProfile: false })}
              >
                <Profile
                  handleClose={() => this.setState({ showProfile: false })}
                />
              </Modal>
              <Modal
                visible={showSecurity}
                title='Security'
                footer={null}
                onCancel={() => this.setState({ showSecurity: false })}
              >
                <Security
                  handleClose={() => this.setState({ showSecurity: false })}
                />
              </Modal>
              <Popover
                content={this.renderPopOver()}
                trigger='click'
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter
                placement='bottomRight'
              >
                <div className='isoImgWrapper'>

                  <Avatar style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle' }} size='large'>
                    {username ? username[0].toUpperCase() : 'U'}
                  </Avatar>
                  <span className='userActivity online' />
                </div>

              </Popover>
            </div>
        }
      </Fragment>
    )
  }
}

TopbarUser.propTypes = {
  userDetails: PropTypes.object.isRequired
}

TopbarUser.defaultProps = {
  userDetails: {}
}

export default connect(null, { logout, closeAll, updateTopbarActiveModal })(TopbarUser)
