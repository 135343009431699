import { createRef, Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, notification, Button, Input, Row } from 'antd'
import Cookies from 'universal-cookie'
import { get } from 'lodash'

import authAction from '../../redux/auth/actions'
import loadingAction from '../../redux/loading/actions'
import errorAction from '../../redux/error/actions'
import SignInStyleWrapper from './signin.style'
import { regExp } from '../../helpers/utility'

const REACT_APP_CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY

const cookies = new Cookies()

const FormItem = Form.Item

const { login } = authAction
const { resetLoadingRedux } = loadingAction
const { resetErrorRedux } = errorAction

class SignIn extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirectToReferrer: false,
      loading: false
    }
    this.captcha = createRef()
  }

  loadRecaptcha = () => {
    if (window?.grecaptcha?.render) {
      const wrapper = document.createElement('div')
      this._widgetId = window.grecaptcha.render(wrapper, {
        sitekey: REACT_APP_CAPTCHA_SITE_KEY,
        callback: this.onSubmit
      })
      this.captcha.appendChild(wrapper)
    }
  }

  componentDidMount () {
    if (window?.grecaptcha?.render) {
      this.loadRecaptcha()
    } else {
      let attempts = 3
      while (attempts) {
        setTimeout(() => {
          this._widgetId === undefined && this.loadRecaptcha()
        }, 1000 * (4 - attempts))
        attempts--
      }
    }
    if (this.props.isLoggedIn === true) {
      this.setState({ redirectToReferrer: true })
    }
    this.props.resetLoadingRedux(['LOGIN'])
    this.props.resetErrorRedux(['LOGIN'])
  }

  componentDidUpdate () {
    const { loginLoading, loginError } = this.props
    if (!loginLoading && loginError) {
        window?.grecaptcha && window.grecaptcha.reset(this._widgetId)
    }
  }

  componentWillReceiveProps (nextProps) {
    const { loginLoading, loginError, loginResponseData, loginMessage } = nextProps

    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn === true
    ) {
      this.setState({ redirectToReferrer: true })
    }

    if (loginLoading === false) {
      if (loginError === false) {
        notification['success']({ message: loginMessage })
        this.props.history.push(`/auth`)
      } else {
        notification['error']({ message: loginMessage })
        const resetRequestID = get(loginResponseData, 'reset_request_id')
        const userEmail = get(loginResponseData, 'user_email')
        if (resetRequestID) {
          this.props.history.push(`/reset-password/${resetRequestID}?email=${userEmail}`)
        }
        this.props.form.resetFields(['captcha_response'])
      }
      this.props.resetLoadingRedux(['LOGIN'])
      this.props.resetErrorRedux(['LOGIN'])
    }
  }

  onSubmit = (token) => {
    this.props.form.setFieldsValue({ 'captcha_response': token })
  }

  handleRecaptchaRef = (elem) => {
    this.captcha = elem
  }

  onHandleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleLogin = e => {
    e.preventDefault()
    this.props.form.validateFields((error, values) => {
      if (!error) {
        let url = new URL(window.location.href)
        let isDemo = url.searchParams.get('demo')
        if (isDemo === 'true') {
          cookies.set('isDemo', true, { path: '/' })
        }
        this.props.login({
          body: values
        })
      }
    })
  }

  render () {
    const { history, form, loginLoading } = this.props
    const { redirectToReferrer } = this.state
    const { getFieldDecorator } = form

    const pathname = get(history, 'location.state.from.pathname')
    const from = { pathname: pathname || '/dashboard' }
    if (redirectToReferrer) {
      return <Redirect to={from} />
    }

    return (
      <SignInStyleWrapper className='isoSignInPage'>
        <div className='isoLoginContentWrapper'>
          <div className='isoLoginContent'>
            <div className='isoLogoWrapper'>
              <Link to='/dashboard'>
                Partners Panel
              </Link>
            </div>

            <Form onSubmit={this.handleLogin}>
              <FormItem>
                {getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      pattern: regExp.email,
                      message: 'Enter a valid email address'
                    }
                  ]
                })(
                  <Input
                    placeholder='Email ID'
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Password can not be empty' }
                  ]
                })(
                  <Input
                    type='password'
                    placeholder='Password'
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('captcha_response', {
                  rules: [
                    { required: true, message: 'Captcha can not be empty' }
                  ]
                })(
                  <div
                    className='g-recaptcha'
                    id='recaptcha'
                    ref={this.handleRecaptchaRef}
                    data-sitekey={REACT_APP_CAPTCHA_SITE_KEY}
                  />
                )}
              </FormItem>
              <Row type='flex' justify='space-between' align='middle'>
                <Link to='/forgot-password' className='isoForgotPass'>
                  Forgot Password
                </Link>
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={!!loginLoading}
                >
                  Sign In
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      </SignInStyleWrapper>
    )
  }
}

const WrappedForm = Form.create({})(SignIn)

export default connect(
  state => ({
    isLoggedIn: state.Auth.idToken !== null,

    loginLoading: state.Loading.LOGIN,
    loginError: state.Error.LOGIN,
    loginResponseData: state.Auth.loginResponseData,
    loginMessage: state.Auth.message,
    account_status: state.Auth.account_status,
    retry: state.Auth.retry
  }),
  {
    login,
    resetLoadingRedux,
    resetErrorRedux
  }
)(WrappedForm)
