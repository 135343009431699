import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import _ from 'lodash'
import actions from './actions'
import authGet from '../../../../request/methods/authGet'
import insightUrls from '../../../../config/urls/insights'

export function * getInsightsData () {
  yield takeEvery(actions.GET_INSIGHTS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: insightUrls.getInsights })
      if (res.status === 200) {
        yield put({
          type: actions.GET_INSIGHTS_SUCCESS,
          data: res.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_INSIGHTS_FAILURE,
          data: {},
          message: _.get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_INSIGHTS_FAILURE,
        data: {},
        message: _.get(e.data, 'error', 'Error')
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getInsightsData)
  ])
}
