import actions from './action.js'
const initialState = {
  asoWalletData: {},
  asoLoading: true,
  asoMessage: ''
}

export default function AsoWalletReducer (state = initialState, action) {
  switch (action.type) {
    case actions.GET_WALLET_LIST:
      return {
        ...state,
        asoLoading: true
      }

    case actions.GET_WALLET_LIST_SUCCESS:
      return {
        ...state,
        asoLoading: false,
        asoWalletData: action.data,
        asoMessage: action.message
      }

    case actions.GET_WALLET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        asoMessage: action.message
      }
    case actions.GET_WALLET_TRANSACTION:
        return {
          ...state,
          asoTransactionLoading: true
        }
  
    case actions.GET_WALLET_TRANSACTION_SUCCESS:
        return {
          ...state,
          asoTransactionLoading: false,
          asoWalleTransactionData: action.data,
          asoTransactionMessage: action.message
        }
  
    case actions.GET_WALLET_TRANSACTION_FAILURE:
        return {
          ...state,
          Transactionloading: false,
          asoTransactionMessage: action.message
        }
    default:
      return state
  }
}
