import styled from 'styled-components'
import bgImage from '../../../../image/sign.jpg'

export const UserAuthStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${bgImage}) no-repeat center center;
  background-size: cover;
  .inner-container {
    background-color: rgba(0,0,0,0.6);
    height: inherit;
    width: inherit;
  }
  .right-container {
    background-color: #fff;
    position: absolute;
    height: -webkit-fill-available;
    width: 500px;
    right: 0;
    padding: 70px 50px;
    min-height: 100vh;
    z-index: 10;
  }
  .header {
    color: #788195;
    font-size: 24px;
    text-align: center;
    margin-bottom: 50px;
    line-height: 1;
  }
  .sub-header {
    color: #788195;
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: 600;
    border-bottom: 2px solid #eee;
    padding-bottom: 6px;
  }
  .instructions-container {
    margin-bottom: 12px;
  }
  .ant-input-number {
    width: 100%;
  }
  .resend-time {
    margin-top: 9px;
    font-size: 12px;
  }
  .second {
    color: #4482ff;
    font-weight: bold;
  }
  .sub-text {
    font-size: 13px;
    color: #a9a9a9;
    margin-bottom: 11px;
  }
  @media only screen and (max-width: 600px) {
    .right-container {
      width: 100%;
      padding: 70px 20px;
    }
  }
`
