import { all, takeEvery, call, put, fork } from 'redux-saga/effects';
import actions from './actions'
import callApi from '../../../../helpers/callApi';
import middleware from '../../../../middleware/responseChecker'
import parseResponseError from '../../../../helpers/parseResponseError'

export function * getUserManagementList(){
    yield takeEvery(actions.GET_USERMANAGEMENT_LIST_REQUEST, function * ({payload}){
        let res = yield call(callApi.getUserManagementList, payload)
        if(res.status === 200){
            yield put({type: actions.GET_USERMANAGEMENT_LIST_SUCCESS, data: res.data, message:'success'})
        }
        else{
            middleware(res) 
            yield put({type: actions.GET_USERMANAGEMENT_LIST_FAILURE, data: undefined,  message:'error'})
        }
    })
}

export function * getUserGroupList(){
    yield takeEvery(actions.GET_USER_GROUP_LIST_REQUEST, function * (){
        let res = yield call(callApi.getUserGroupList)
        if(res.status === 200){
            yield put({type: actions.GET_USER_GROUP_LIST_SUCCESS, data: res.data, message:'success'})
        }
        else{
            middleware(res) 
            yield put({type: actions.GET_USER_GROUP_LIST_FAILURE, data: undefined,  message:'error'})
        }
    })
}

export function * getTimezoneList(){
    yield takeEvery(actions.GET_TIMEZONE_DATA_REQUEST, function * (){
        let res = yield call(callApi.getTimezoneList)
        if(res.status === 200){
            yield put({type:actions.GET_TIMEZONE_DATA_SUCCESS, data: res.data, message:'success'})
        }
        else{
            middleware(res) 
            yield put({type:actions.GET_TIMEZONE_DATA_FAILURE, data: undefined, message:'error'})
        }
    })
}

export function  * addNewUser(){
    yield takeEvery(actions.POST_USER_GROUP, function * (payload){
        let res = yield call(callApi.postNewUser,payload)
        if(res.status === 201||res.status === 200){
            yield put({ type: actions.POST_USER_GROUP_SUCCESS, messageAddNewUser: 'success' })
            yield put({ type: actions.GET_USERMANAGEMENT_LIST_REQUEST, payload})
        }
        else{
            middleware(res) 
            const err = res.data.error
            yield put({
                type: actions.POST_USER_GROUP_FAILED,
                messageAddNewUser: parseResponseError(err, 'User fail to added')
            })
        }
    })
}

export function * editUser(){
    yield takeEvery(actions.EDIT_USER_GROUP_REQUEST, function * (payload){
        let res = yield call(callApi.editUser,payload)
        if(res.status === 201 || res.status === 200 || res.status === 202){
            yield put({ type: actions.EDIT_USER_GROUP_SUCCESS, messageeditUser: 'success' })
            yield put({ type: actions.GET_USERMANAGEMENT_LIST_REQUEST, payload})
        }
        else{
            middleware(res) 
            const err = res.data.error
            yield put({
                type: actions.EDIT_USER_GROUP_FAILED,
                messageeditUser: parseResponseError(err, 'User fail to edit')
            })
        }
    })
}

export function * deleteUser(){
    yield takeEvery(actions.DELETE_USER_GROUP_REQUEST, function * (payload){
        let res = yield call(callApi.deleteUser,payload)
        if(res.status === 201 || res.status === 200){
            yield put({ type: actions.DELETE_USER_GROUP_SUCCESS, messageDeleteUser: 'success' })
            yield put({ type: actions.GET_USERMANAGEMENT_LIST_REQUEST, payload})
        }
        else{
            middleware(res) 
            const err = res.data.error
            yield put({
                type: actions.DELETE_USER_GROUP_FAILED,
                messageDeleteUser: parseResponseError(err, 'User fail to edit')
            })
        }
    })
}
export function * deactivatePartnerUser(){
    yield takeEvery(actions.DEACTIVATE_USER_REQUEST, function * (payload){
        let res = yield call(callApi.deactivateUser,payload)
        if(res.status === 201 || res.status === 200){
            yield put({ type: actions.GET_USERMANAGEMENT_LIST_REQUEST, payload})
            yield put({ type: actions.DEACTIVATE_USER_SUCCESS, messageDeactivateUser: 'success' })
        }
        else{
            middleware(res) 
            const err = res.data.error
            yield put({
                type: actions.DEACTIVATE_USER_FAILED,
                messageDeactivateUser: parseResponseError(err, 'User fail to edit')
            })
        }
    })
}
export function * activatePartnerUser(){
    yield takeEvery(actions.ACTIVATE_USER_REQUEST, function * (payload){
        let res = yield call(callApi.activateUser,payload)
        if(res.status === 201 || res.status === 200){
            yield put({ type: actions.GET_USERMANAGEMENT_LIST_REQUEST, payload})
            yield put({ type: actions.ACTIVATE_USER_SUCCESS, messageActivateUser: 'success' })
        }
        else{
            middleware(res) 
            const err = res.data.error
            yield put({
                type: actions.ACTIVATE_USER_FAILED,
                messageActivateUser: parseResponseError(err, 'User fail to edit')
            })
        }
    })
}

export default function * rootSaga () {
    yield all([
        fork(getUserManagementList),
        fork(getUserGroupList),
        fork(getTimezoneList),
        fork(addNewUser),
        fork(editUser),
        fork(deactivatePartnerUser),
        fork(deleteUser),
        fork(activatePartnerUser)
    ])
}