import { Component } from 'react'
import {
  Form,
  Row,
  Input,
  Button,
  notification
} from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ResetPasswordStyleWrapper from './resetPassword.style'
import userAction from '../../redux/user/actions'
import loadingAction from '../../../../redux/loading/actions'
import errorAction from '../../../../redux/error/actions'
import { queryStringParser } from '../../../../helpers/utility'

const FormItem = Form.Item

const { resetPassword, validateResetId } = userAction
const { resetLoadingRedux } = loadingAction
const { resetErrorRedux } = errorAction

class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      verifyPassword: '',
      mismatch: false,
      loading: false
    }
  }

  componentDidMount () {
    this.props.validateResetId({ body: { uuid: this.props.match.params.uuid } })
    this.props.resetLoadingRedux(['RESET_PASSWORD'])
    this.props.resetErrorRedux(['RESET_PASSWORD'])
  }

  componentWillReceiveProps (nextProps) {
    const { resetPasswordLoading, resetPasswordError, resetPasswordStatus } = nextProps
    if (resetPasswordLoading === false) {
      notification[resetPasswordError ? 'error' : 'success']({ message: resetPasswordStatus })
      if (resetPasswordError === false) {
        this.signin()
      }
      this.props.resetLoadingRedux(['RESET_PASSWORD'])
      this.props.resetErrorRedux(['RESET_PASSWORD'])
    }
  }

  onHandleChange = (val) => {
    this.setState({ [val.target.name]: val.target.value, mismatch: false })
  }

  onHandleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { newPassword, confirmPassword } = values
        const { uuid } = this.props.match.params
        const queryParams = queryStringParser(this.props.location.search)
        const { email } = queryParams
        if (newPassword !== confirmPassword) {
          notification['error']({ message: 'Passwords do not match' })
          return
        }
        this.props.resetPassword({ new_password: newPassword, confirm_password: confirmPassword, uuid, email })
      }
    })
  }

  signin = () => {
    const { history } = this.props
    history.push('/signin')
  }

  forgotPassword = () => {
    const { history } = this.props
    history.push('/forgot-password')
  }

  render () {
    const {
      resetPasswordLoading,
      form
    } = this.props
    const { getFieldDecorator } = form

    return (
      <ResetPasswordStyleWrapper className='isoResetPassPage'>
        <div className='isoFormContentWrapper'>
          <div className='isoFormContent'>
            <div className='isoLogoWrapper'>
              <Link to='/'>
                RESET PASSWORD
              </Link>
            </div>
            <div className='isoFormHeadText'>
              <h3>
                Reset Password
              </h3>
              <p>
                Enter new password and confirm it.
              </p>
            </div>
            <Form onSubmit={this.onHandleSubmit}>
              <FormItem>
                {getFieldDecorator('newPassword', {
                  rules: [
                    { required: true, message: 'Password can not be empty' },
                    { min: 8, message: 'Password should be minimum 8 characters.' },
                    { pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, message: 'Password does not match minimum validation required.' }
                  ]
                })(
                  <Input
                    type='password'
                    placeholder='New Password'
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('confirmPassword', {
                  rules: [
                    { required: true, message: 'Confirm Password can not be empty' }
                  ]
                })(
                  <Input
                    type='password'
                    placeholder='Confirm Password'
                  />
                )}
              </FormItem>
              <Row type='flex' justify='end'>
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={!!resetPasswordLoading}
                >
                  RESET PASSWORD
                </Button>
              </Row>
            </Form>
            <div>
              <div className='password-heading'>Password Rules:</div>
              <ul>
                <li>Password should be alphanumeric, containing at least an upper case character, a lower case character and a special symbol.</li>
                <li>Password should not contain any sub-strings from the email/username of the user.</li>
                <li>Last and new password should not be same.</li>
              </ul>
            </div>
          </div>
        </div>
      </ResetPasswordStyleWrapper>
    )
  }
}

const WrappedForm = Form.create({})(ResetPassword)

export default connect(
  state => ({
    resetPasswordStatus: state.User.resetPasswordStatus,
    resetPasswordLoading: state.Loading.RESET_PASSWORD,
    resetPasswordError: state.Error.RESET_PASSWORD,

    validateResetIdMessage: state.User.validateMessage,
    validateResetIdLoading: state.Loading.VALIDATE_RESET_ID,
    validateResetIdError: state.Error.VALIDATE_RESET_ID
  }),
  {
    resetPassword,
    validateResetId,
    resetLoadingRedux,
    resetErrorRedux
  }
)(WrappedForm)
