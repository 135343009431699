import { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Dropdown, Icon, Drawer } from 'antd'
import appActions from '../../redux/app/actions'
import TopbarUser from './topbarUser'
import CountrySelect from './country'
import TopbarWrapper, { DrawerTopDiv, DrawerStyle } from './topbar.style'
import themes from '../../settings/themes'
import { themeConfig } from '../../settings'
import { NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'
import DashboardLogo from '../../image/blogging.png'
import PolicyListLogo from '../../image/vector-smart-object.png'
import ClaimListLogo from '../../image/vector-smart-object-2.png'
import UserManagementLogo from '../../image/user.png'
// import CustomerLogo from '../../image/customer.png'
import PermissionChecker from '../../components/PermissionChecker'
import ConfigChecker from '../../components/ConfigChecker'

const cookies = new Cookies()
const { Header } = Layout
const { toggleCollapsed } = appActions
const customizedTheme = themes[themeConfig.theme]

class Topbar extends Component {
  state = {
    isDemo: cookies.get('isDemo') !== undefined,
    showDecision: false,
    width: '',
    visible: false,
    uploadShow: false,
    shippingClaims: false,
    active: 0,
    claimsShow: false,
    reportingShow: false,
    developersShow: false
  };

  componentWillMount = () => {
    this.updateDimensions()
  };
  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  };
  componentDidMount () {
    window.addEventListener('resize', this.updateDimensions)
  }
  openNav = () => {
    this.setState({ visible: true, uploadShow: false, shippingClaims: false })
  };
  closeNav = () => {
    this.setState({ visible: false })
  };
  goToShippingDashboard = () => {
    const { history } = this.props
    history.push('/dashboard/shipping/dash')
  };
  goToShippingClaim = () => {
    const { history } = this.props
    history.push('/dashboard/shipping/grid')
  };
  showUpload = () => {
    this.setState({
      uploadShow: !this.state.uploadShow
    })
  };

  showClaims = () => {
    this.setState({
      claimsShow: !this.state.claimsShow
    })
  };
  showDevelopers = () => {
    this.setState({
      developersShow: !this.state.developersShow
    })
  };
  showReporting = () => {
    this.setState({
      reportingShow: !this.state.reportingShow
    })
  };

  toggleTab = (stateName) => {
    this.setState({ [stateName]: !this.state[stateName] })
  };

  showShippingClaims = () => {
    this.setState({
      shippingClaims: !this.state.shippingClaims
    })
  };
  configLookUp = (config, value) => {
    return (
      Array.isArray(config) &&
      config.findIndex(
        (x) => (x.config_type || {}).name === value && x.value === '1'
      ) > -1
    )
  };
  permissionLookUp = (permissions, value) => {
    return Array.isArray(permissions) && permissions.indexOf(value) > -1
  };

  hexToRgbA = (hex) => {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = '0x' + c.join('')
      return (
        'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.4)'
      )
    }
  };
  toggle = (position) => {
    if (this.state.active === position) {
      this.setState({ active: null })
    } else {
      this.setState({ active: position })
    }
  };
  myColor = (colorCode, position) => {
    const covertedCode = this.hexToRgbA(colorCode)
    if (this.state.active === position) {
      return covertedCode
    }
    return ''
  };

  movePage = (path) => {
    const { history } = this.props
    history.push(path)
    this.closeNav()
  };
  render () {
    const {
      uploadShow,
      shippingClaims,
      claimsShow,
      reportingShow,
      showProductInsights,
      developersShow
    } = this.state
    const { config, userDetails, clientPartners = [] } = this.props
    const collapsed = this.props.collapsed && !this.props.openDrawer
    const colorCode =
      (config.find((x) => (x.config_type || {}).name === 'COLOR_CODE') || {})
        .value || ''
    const textColorCode =
      (
        config.find(
          (x) => (x.config_type || {}).name === 'HEADER_TEXT_COLOR_CODE'
        ) || {}
      ).value || ''
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    }

    const menu = (
      <PermissionChecker requiredPermission='view_uploaddata'>
        <Menu>
          <Menu.Item>
            <NavLink
              to='/dashboard/upload'
              className='link'
              activeClassName='link-active'
            >
              Upload Data
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink
              to='/dashboard/upload-list'
              className='link'
              activeClassName='link-active'
            >
              View Upload Data
            </NavLink>
          </Menu.Item>
        </Menu>
      </PermissionChecker>
    )
    const menu1 = (
      <Menu>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_shippingdashboard'>
            <NavLink
              to='/dashboard/shipping/dash'
              className='link'
              activeClassName='link-active'
            >
              Shipping dashboard
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_shippingclaaimlist'>
            <NavLink
              to='/dashboard/shipping/grid'
              className='link'
              activeClassName='link-active'
            >
              Claim List
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
      </Menu>
    )
    const menu2 = (
      <Menu>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_claimlist'>
            <NavLink
              to='/dashboard/claim-list'
              className='link'
              activeClassName='link-active'
            >
              Claims List
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_customerfeedback'>
            <NavLink
              to='/dashboard/feedback'
              className='link'
              activeClassName='link-active'
            >
              Customer Feedback
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
      </Menu>
    )
    const menu3 = (
      <Menu>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_claimlist'>
            <NavLink
              to='/dashboard/reporting'
              className='link'
              activeClassName='link-active'
            >
              Report Schedules
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_customerfeedback'>
            <NavLink
              to='/dashboard/reporting-requests'
              className='link'
              activeClassName='link-active'
            >
              Report Requests
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
      </Menu>
    )
    const menu4 = (
      <Menu>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_product_insights_audience'>
            <NavLink
              to='/dashboard/audience'
              className='link'
              activeClassName='link-active'
            >
              Audience
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_product_insights_behaviour'>
            <NavLink
              to='/dashboard/behaviour'
              className='link'
              activeClassName='link-active'
            >
              Behaviour
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
      </Menu>
    )
    const developersDropdownMenu = (
      <Menu>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_developers_panel'>
            <NavLink
              to='/dashboard/developers'
              className='link'
              activeClassName='link-active'
            >
              API Key
            </NavLink>
          </PermissionChecker>
        </Menu.Item>
        <Menu.Item>
          <PermissionChecker requiredPermission='view_developers_panel'>
            <a
              href='http://developers.pasarpolis.io'
              target='_blank'
              className='link'
            >
              API Documentation
            </a>
          </PermissionChecker>
        </Menu.Item>
      </Menu>
    )

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          {this.state.width > 845 ? (
            <Fragment>
              <div className='isoLeft'>
                <a href='/dashboard'>
                  <img className='p5mH45' src={this.props.logo} alt='img' />
                </a>
                <PermissionChecker requiredPermission='view_dashboard'>
                  <NavLink
                    to='/dashboard'
                    exact
                    className='link'
                    activeClassName='link-active'
                  >
                    Dashboard
                  </NavLink>
                </PermissionChecker>
                {this.configLookUp(config, 'DISPLAY_POLICY_LIST') ? (
                  <PermissionChecker requiredPermission='view_policylist'>
                    <NavLink
                      to='/dashboard/policy-list'
                      className='link'
                      activeClassName='link-active'
                    >
                      Policy List
                    </NavLink>
                  </PermissionChecker>
                ) : null}
                {
                  // this.permissionLookUp(permissions, 'something') &&
                  this.configLookUp(config, 'DISPLAY_CLAIM_LIST') ? (
                    <PermissionChecker requiredPermission='view_claimlist'>
                      <Dropdown overlay={menu2} trigger={['click']}>
                        <span className='link anchor'>Claims</span>
                      </Dropdown>
                    </PermissionChecker>
                  ) : (
                    ''
                  )
                }
                {
                  // this.permissionLookUp(permissions, 'something') &&
                  this.configLookUp(config, 'DISPLAY_BULK_UPLOAD') ? (
                    <PermissionChecker requiredPermission='view_uploaddata'>
                      <Dropdown overlay={menu} trigger={['click']}>
                        <span className='link anchor'>Upload</span>
                      </Dropdown>
                    </PermissionChecker>
                  ) : (
                    ''
                  )
                }
                {this.configLookUp(config, 'DISPLAY_SHIPPING_CLAIMS') ? (
                  <PermissionChecker requiredPermission='view_shippingclaaimlist'>
                    <Dropdown overlay={menu1} trigger={['click']}>
                      <span className='link'>Shipping Claims</span>
                    </Dropdown>
                  </PermissionChecker>
                ) : (
                  ''
                )}
                <PermissionChecker requiredPermission='view_usermanagement'>
                  <NavLink
                    to='/dashboard/user-management'
                    className='link'
                    activeClassName='link-active'
                  >
                    User Management
                  </NavLink>
                </PermissionChecker>
                <ConfigChecker
                  requiredConfig='DISPLAY_REPORTING'
                  requiredValue='1'
                >
                  <PermissionChecker requiredPermission='view_reporting'>
                    <Dropdown overlay={menu3} trigger={['click']}>
                      <span className='link anchor'>Reporting</span>
                    </Dropdown>
                  </PermissionChecker>
                </ConfigChecker>
                {/* TODO: put right permission for product insights menu */}
                <ConfigChecker
                  requiredConfig='DISPLAY_ASO_WALLET'
                  requiredValue='1'
                >
                  <PermissionChecker requiredPermission='view_asowallet_list'>
                    <NavLink
                      to='/dashboard/wallet'
                      className='link'
                      activeClassName='link-active'
                    >
                      Wallet
                    </NavLink>
                  </PermissionChecker>
                </ConfigChecker>

                <PermissionChecker requiredPermission='view_developers_panel'>
                  <Dropdown
                    overlay={developersDropdownMenu}
                    trigger={['click']}
                  >
                    <span className='link anchor'>Developers</span>
                  </Dropdown>
                </PermissionChecker>

                <ConfigChecker
                  requiredConfig='DISPLAY_PRODUCT_INSIGHTS'
                  requiredValue='1'
                >
                  <PermissionChecker requiredPermission='view_product_insights_audience'>
                    <Dropdown overlay={menu4} trigger={['click']}>
                      <span className='link anchor'>Product Insights</span>
                    </Dropdown>
                  </PermissionChecker>
                </ConfigChecker>
                <ConfigChecker
                  requiredConfig='DISPLAY_INSIGHTS'
                  requiredValue='1'
                >
                  {clientPartners.includes('gosend') && (
                    <NavLink
                      to='/dashboard/insights'
                      className='link'
                      activeClassName='link-active'
                    >
                      Insights
                    </NavLink>
                  )}
                </ConfigChecker>

                {/* Hiding this feature as it is not fully functioning */}

                {/* {this.configLookUp(config, 'DISPLAY_CUSTOMER360_SEARCH') ? (
                  <PermissionChecker requiredPermission='view_customer360_search'>
                    <NavLink
                      to='/dashboard/customer'
                      className='link'
                      activeClassName='link-active'
                    >
                      Customer
                    </NavLink>
                  </PermissionChecker>
                ) : (
                  ''
                )} */}
              </div>
              <ul
                className='isoRight'
                style={{
                  marginBottom: '0px'
                }}
              >
                <li>
                  <CountrySelect />
                </li>
                <li
                  onClick={() => this.setState({ selectedItem: 'user' })}
                  className='isoUser'
                >
                  <TopbarUser
                    userDetails={userDetails}
                    responsiveDesign={false}
                  />
                </li>
              </ul>
            </Fragment>
          ) : (
            <Fragment>
              <div className='fL'>
                <a href='/dashboard'>
                  <img
                    width='150px'
                    height='auto'
                    src={this.props.logo}
                    className='topbar-collapsed'
                    alt='img'
                  />
                </a>
              </div>
              <div
                style={{
                  fontSize: 30,
                  cursor: 'pointer'
                }}
                onClick={this.openNav}
              >
                <div
                  style={{
                    marginTop: '40%'
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      height: 4,
                      width: 40,
                      borderRadius: 3.5,
                      backgroundColor: '#5c5c5c',
                      marginLeft: 20,
                      marginTop: 3
                    }}
                  />
                  <span
                    style={{
                      display: 'block',
                      height: 4,
                      width: 31,
                      borderRadius: 3.5,
                      backgroundColor: '#5c5c5c',
                      marginLeft: 29,
                      marginTop: 5
                    }}
                  />
                  <span
                    style={{
                      display: 'block',
                      height: 4,
                      width: 22,
                      borderRadius: 3.5,
                      backgroundColor: '#5c5c5c',
                      marginLeft: 37,
                      marginTop: 5
                    }}
                  />
                </div>
              </div>

              <Drawer
                placement='right'
                closable={false}
                onClose={this.closeNav}
                maskClosable='maskClosable'
                className='sidebar-drawer'
                visible={this.state.visible}
                bodyStyle={{
                  padding: 0
                }}
              >
                <DrawerTopDiv
                  color={colorCode}
                  onClick={() => this.setState({ selectedItem: 'user' })}
                >
                  <TopbarUser
                    userDetails={userDetails}
                    responsiveDesign
                    colorCode={textColorCode}
                  />
                </DrawerTopDiv>
                <DrawerStyle
                  style={{
                    width: '100%',
                    marginTop: 20
                  }}
                >
                  <div className='text-right'>
                    <CountrySelect />
                  </div>

                  <div
                    className='mouse-pointer main-div'
                    style={{
                      background: this.myColor(colorCode, 0)
                    }}
                    onClick={() => {
                      this.toggle(0)
                      this.movePage('/dashboard')
                    }}
                  >
                    <NavLink
                      to='/dashboard'
                      exact
                      className='mt-20 fs-12'
                      activeClassName='link-active'
                      onClick={this.closeNav}
                    >
                      <span className='mR25-dashboard'>
                        Dashboard&nbsp;&nbsp;&nbsp;&nbsp;
                        <img
                          className='h16w16'
                          src={DashboardLogo}
                          alt='DashboardLogo'
                        />
                      </span>
                    </NavLink>
                  </div>
                  {this.configLookUp(config, 'DISPLAY_POLICY_LIST') ? (
                    <PermissionChecker requiredPermission='view_policylist'>
                      <div
                        className='mouse-pointer text-right w-100'
                        style={{
                          height: 35,
                          background: this.myColor(colorCode, 1),
                          paddingTop: 5
                        }}
                        onClick={() => {
                          this.toggle(1)
                          this.movePage('/dashboard/policy-list')
                        }}
                      >
                        <NavLink
                          to='/dashboard/policy-list'
                          style={{
                            color: 'black',
                            fontSize: 12
                          }}
                          activeClassName='link-active'
                          onClick={this.closeNav}
                        >
                          <span
                            style={{
                              marginRight: 25
                            }}
                          >
                            Policy List&nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              className='h16w16'
                              src={PolicyListLogo}
                              alt='PolicyListLogo'
                            />
                          </span>
                        </NavLink>
                      </div>
                    </PermissionChecker>
                  ) : null}

                  {
                    // this.permissionLookUp(permissions, 'something') &&
                    this.configLookUp(config, 'DISPLAY_CLAIM_LIST') ? (
                      <PermissionChecker requiredPermission='view_claimlist'>
                        <div
                          className='w-100 text-right mouse-pointer pt-5'
                          style={{
                            height: claimsShow ? 100 : 35,
                            background: this.myColor(colorCode, 2)
                          }}
                          onClick={() => {
                            this.toggle(2)
                          }}
                        >
                          <span
                            onClick={() => {
                              this.showClaims()
                            }}
                            className='span-style'
                            activeClassName='link-active'
                          >
                            Claims
                            <img
                              src={ClaimListLogo}
                              className='icon-img'
                              alt='ClaimListLogo'
                            />
                          </span>
                          {claimsShow ? (
                            <div className='claim-show'>
                              <PermissionChecker requiredPermission='view_claimlist'>
                                <NavLink
                                  to='/dashboard/claim-list'
                                  className='nav-link'
                                  activeClassName='link-active'
                                  onClick={() => { this.closeNav(); this.showClaims() }}
                                >
                                  Claim List
                                </NavLink>
                              </PermissionChecker>

                              <PermissionChecker requiredPermission='view_customerfeedback'>
                                <NavLink
                                  to='/dashboard/feedback'
                                  className='nav-link'
                                  activeClassName='link-active'
                                  onClick={() => { this.closeNav(); this.showClaims() }}
                                >
                                  Customer Feedback
                                </NavLink>
                              </PermissionChecker>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </PermissionChecker>
                    ) : (
                      ''
                    )
                  }

                  {
                    // this.permissionLookUp(permissions, 'something') &&
                    this.configLookUp(config, 'DISPLAY_BULK_UPLOAD') ? (
                      <PermissionChecker requiredPermission='upload_data'>
                        <div
                          className='mouse-pointer main-div'
                          style={{
                            height: uploadShow ? 50 : 35,
                            background: this.myColor(colorCode, 3)
                          }}
                          onClick={() => {
                            this.toggle(3)
                            this.showUpload()
                          }}
                        >
                          <span
                            onClick={() => {
                              this.showUpload()
                            }}
                            className='cBFs12'
                            activeClassName='link-active'
                          >
                            Upload&nbsp;&nbsp;&nbsp;&nbsp;
                            <Icon type='down' className='icon-down' />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          {uploadShow ? (
                            <div className='pR-25'>
                              <PermissionChecker requiredPermission='upload_data'>
                                <NavLink
                                  className='nav-link-sub mL20'
                                  to='/dashboard/upload'
                                  activeClassName='link-active'
                                  onClick={this.closeNav}
                                >
                                  Upload Data
                                </NavLink>
                              </PermissionChecker>

                              <PermissionChecker requiredPermission='view_uploaddata'>
                                <NavLink
                                  className='nav-link'
                                  to='/dashboard/upload-list'
                                  activeClassName='link-active'
                                  onClick={this.closeNav}
                                >
                                  View Upload Data
                                </NavLink>
                              </PermissionChecker>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </PermissionChecker>
                    ) : (
                      ''
                    )
                  }
                  {this.configLookUp(config, 'DISPLAY_SHIPPING_CLAIMS') ? (
                    <PermissionChecker requiredPermission='view_shippingdashboard'>
                      <div
                        className='mouse-pointer w100tRpT5'
                        style={{
                          height: shippingClaims ? 50 : 35,
                          background: this.myColor(colorCode, 4)
                        }}
                        onClick={() => {
                          this.toggle(4)
                          this.showShippingClaims()
                        }}
                      >
                        <span
                          className='mouse-pointer cBF12'
                          onClick={() => {
                            this.showShippingClaims()
                          }}
                          activeClassName='link-active'
                        >
                          Shipping Claims&nbsp;&nbsp;&nbsp;&nbsp;
                          <Icon type='down' className='icon-down' />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        {shippingClaims ? (
                          <div className='pR-25'>
                            <PermissionChecker requiredPermission='view_shippingdashboard'>
                              <NavLink
                                className='nav-link-sub '
                                to='/dashboard/shipping/dash'
                                activeClassName='link-active'
                                onClick={this.closeNav}
                              >
                                Shipping dashboard
                              </NavLink>
                            </PermissionChecker>

                            <PermissionChecker requiredPermission='view_shippingclaaimlist'>
                              <NavLink
                                to='/dashboard/shipping/grid'
                                className='nav-link-sub mL20'
                                activeClassName='link-active'
                                onClick={this.closeNav}
                              >
                                View Shipping ClaimList
                              </NavLink>
                            </PermissionChecker>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </PermissionChecker>
                  ) : (
                    ''
                  )}

                  <PermissionChecker requiredPermission='view_usermanagement'>
                    <div
                      className='mouse-pointer w100tRpT5 h35'
                      style={{
                        background: this.myColor(colorCode, 5)
                      }}
                      onClick={() => {
                        this.toggle(5)
                        this.movePage('/dashboard/user-management')
                      }}
                    >
                      <NavLink
                        to='/dashboard/user-management'
                        className='nav-link-sub'
                        activeClassName='link-active'
                        onClick={this.closeNav}
                      >
                        <span className='mR25'>
                          User Management&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            className='h16w16'
                            src={UserManagementLogo}
                            alt='UserManagementLogo'
                          />
                        </span>
                      </NavLink>
                    </div>
                  </PermissionChecker>

                  {/* Hiding this feature as it is not fully functioning */}

                  {/* {this.configLookUp(config, 'DISPLAY_CUSTOMER360_SEARCH') ? (
                    <PermissionChecker requiredPermission='view_customer360_search'>
                      <div
                        className='main-div'
                        style={{
                          background: this.myColor(colorCode, 5)
                        }}
                        onClick={() => {
                          this.toggle(5)
                          this.movePage('/dashboard/customer')
                        }}
                      >
                        <NavLink
                          className='nav-link-sub'
                          to='/dashboard/customer'
                          activeClassName='link-active'
                          onClick={this.closeNav}
                        >
                          <span className='h16w16'>
                            Customer&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={CustomerLogo} alt='CustomerLogo' />
                          </span>
                        </NavLink>
                      </div>
                    </PermissionChecker>
                  ) : (
                    ''
                  )} */}
                  <ConfigChecker
                    requiredConfig='DISPLAY_ASO_WALLET'
                    requiredValue='1'
                  >
                    <PermissionChecker requiredPermission='view_asowallet_list'>
                      <div
                        className='main-div'
                        style={{
                          background: this.myColor(colorCode, 6)
                        }}
                        onClick={() => {
                          this.toggle(6)
                          this.movePage('/dashboard/wallet')
                        }}
                      >
                        <NavLink
                          to='/dashboard/wallet'
                          className='nav-link-sub '
                          activeStyle={{
                            color: 'black'
                          }}
                          onClick={this.closeNav}
                        >
                          <span className='mR25'>
                            Wallet&nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              className='h16w16'
                              src={PolicyListLogo}
                              alt='PolicyListLogo'
                            />
                          </span>
                        </NavLink>
                      </div>
                    </PermissionChecker>
                  </ConfigChecker>

                  <PermissionChecker requiredPermission='view_developers_panel'>
                    <div
                      className='main-div'
                      style={{
                        height: developersShow ? 100 : 35,
                        background: this.myColor(colorCode, 7)
                      }}
                      onClick={() => {
                        this.toggle(7)
                      }}
                    >
                      <span
                        className='mR25'
                        onClick={() => {
                          this.showDevelopers()
                        }}
                        activeClassName='link-active'
                      >
                        Developers&nbsp;&nbsp;&nbsp;&nbsp;
                        <Icon type='down' className='icon-down' />
                      </span>
                      {developersShow ? (
                        <div className='developer-show'>
                          <PermissionChecker requiredPermission='view_developers_panel'>
                            <NavLink
                              to='/dashboard/developers'
                              className='nav-link'
                              activeClassName='link-active'
                              onClick={() => { this.closeNav(); this.showDevelopers() }}
                            >
                              API Key
                            </NavLink>
                          </PermissionChecker>
                          <PermissionChecker requiredPermission='view_developers_panel'>
                            <a
                              href='http://developers.pasarpolis.io'
                              target='_blank'
                              className='nav-link'
                              onClick={this.closeNav}
                            >
                              API Documentation
                            </a>
                          </PermissionChecker>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </PermissionChecker>

                  <ConfigChecker
                    requiredConfig='DISPLAY_REPORTING'
                    requiredValue='1'
                  >
                    <PermissionChecker requiredPermission='view_reporting'>
                      <div
                        className='main-div'
                        style={{
                          height: reportingShow ? 100 : 35,
                          background: this.myColor(colorCode, 8)
                        }}
                        onClick={() => {
                          this.toggle(8)
                        }}
                      >
                        <span
                          className='mR25'
                          onClick={() => {
                            this.showReporting()
                          }}
                          activeClassName='link-active'
                        >
                          Reporting
                          <img
                            src={ClaimListLogo}
                            className='icon-img'
                            alt='ClaimListLogo'
                          />
                        </span>
                        {reportingShow && (
                          <div
                            style={{
                              paddingTop: 10,
                              paddingRight: 25,
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <PermissionChecker requiredPermission='view_claimlist'>
                              <NavLink
                                to='/dashboard/claim-list'
                                className='navlink'
                                activeClassName='active-navlink'
                                onClick={this.closeNav}
                              >
                                Schedules
                              </NavLink>
                            </PermissionChecker>

                            <PermissionChecker requiredPermission='view_customerfeedback'>
                              <NavLink
                                to='/dashboard/feedback'
                                className='navlink'
                                activeClassName='active-navlink'
                                onClick={this.closeNav}
                              >
                                Request
                              </NavLink>
                            </PermissionChecker>
                          </div>
                        )}
                      </div>
                    </PermissionChecker>
                  </ConfigChecker>
                  <ConfigChecker
                    requiredConfig='DISPLAY_PRODUCT_INSIGHTS'
                    requiredValue='1'
                  >
                    <PermissionChecker requiredPermission='view_product_insights_audience'>
                      <div
                        className='mouse-pointer w100tRpT5'
                        style={{
                          height: showProductInsights ? 100 : 35,
                          background: this.myColor(colorCode, 7)
                        }}
                        onClick={() => {
                          this.toggle(7)
                        }}
                      >
                        <span
                          onClick={() => this.toggleTab('showProductInsights')}
                          className='submenu-tab'
                        >
                          Product Insights
                          <img
                            src={ClaimListLogo}
                            className='icon-img'
                            alt='ClaimListLogo'
                          />
                        </span>
                        {showProductInsights && (
                          <div className='submenu-container'>
                            <PermissionChecker requiredPermission='view_product_insights_audience'>
                              <NavLink
                                to='/dashboard/audience'
                                className='navlink'
                                activeClassName='active-navlink'
                                onClick={this.closeNav}
                              >
                                Audience
                              </NavLink>
                            </PermissionChecker>

                            <PermissionChecker requiredPermission='view_product_insights_behaviour'>
                              <NavLink
                                to='/dashboard/behaviour'
                                className='navlink'
                                activeClassName='active-navlink'
                                onClick={this.closeNav}
                              >
                                Behaviour
                              </NavLink>
                            </PermissionChecker>
                          </div>
                        )}
                      </div>
                    </PermissionChecker>
                  </ConfigChecker>

                  <ConfigChecker
                    requiredConfig='DISPLAY_INSIGHTS'
                    requiredValue='1'
                  >
                    <div
                      className='mobile-tab'
                      onClick={() => {
                        this.toggle(8)
                        this.movePage('/dashboard/reporting')
                      }}
                    >
                      <NavLink
                        to='/dashboard/insights'
                        className='navlink-style '
                        activeClassName='active-style'
                        onClick={this.closeNav}
                      >
                        <span className='label-style'>
                          Insights
                          <img
                            src={PolicyListLogo}
                            className='icon-img'
                            alt='ClaimListLogo'
                          />
                        </span>
                      </NavLink>
                    </div>
                  </ConfigChecker>
                </DrawerStyle>
              </Drawer>
            </Fragment>
          )}
        </Header>
      </TopbarWrapper>
    )
  }
}

Topbar.propTypes = {
  config: PropTypes.array.isRequired,
  userDetails: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired
}

Topbar.defaultProps = {
  config: [],
  userDetails: {},
  permissions: []
}

export default connect(
  (state) => ({
    App: state.App,
    clientPartners: state.Dashboard.allClientPartners
  }),
  { toggleCollapsed }
)(Topbar)
